import React, { useEffect, useRef, useState } from "react";
import { COMMON_WEB_PATH } from "../constant";
import { useDispatch, useSelector } from "react-redux";
import { toggleBGM } from "../redux/action/action";
import useDeviceType from "../hook/useDeviceType";

const AudioPlayer = () => {
  const audioRef = useRef(null);

  const isBGMEnabled = useSelector((state) => state.isBGMEnabled);
  const { enabled, song, musicVolume = 0.2 } = isBGMEnabled || {};

  const dispatch = useDispatch();

  const deviceType = useDeviceType();

  const [userInteracted, setUserInteracted] = useState(false);

  const handlePlayback = async (determine) => {
    if (audioRef.current && userInteracted) {
      audioRef.current.muted = false;
      if (determine) {
        audioRef.loop = true;
        audioRef.volume = musicVolume || 0.2;
        try {
          await audioRef.current.play();
        } catch (error) {
          console.error("Error in playback:", error);
        }
      } else {
        audioRef.current.pause();
      }
    }
  };

  useEffect(() => {
    audioRef.current = new Audio(
      `${COMMON_WEB_PATH}/FlyDragon/${song || "back2"}.mp3?abc`
    );
    audioRef.current.muted = true;
    audioRef.current.loop = true;
    audioRef.current.volume = musicVolume || 0.2;

    const handleFirstInteraction = () => {
      setUserInteracted(true);
    };

    document.addEventListener("click", handleFirstInteraction, { once: true });
    document.addEventListener("keydown", handleFirstInteraction, {
      once: true,
    });

    return () => {
      document.removeEventListener("click", handleFirstInteraction);
      document.removeEventListener("keydown", handleFirstInteraction);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, [song, musicVolume]);

  useEffect(() => {
    if (deviceType == "PC") {
      handlePlayback(enabled);
    }
  }, [enabled, userInteracted, song, deviceType]);

  return null;
};

export default AudioPlayer;
