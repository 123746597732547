import React, { useState, cache, useEffect } from "react";
import CommonConfirmModal from "../../../components/Modal/commonConfirmModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeCache,
  closeClearCash,
} from "../../../redux/action/member/memberAction";
import { notice } from "../../../redux/action/action";
import { Flex, Image, Text, useToast } from "@chakra-ui/react";
import { waitTime } from "../../../utils/waitTime";
import { COMMON_WEB_PATH } from "../../../constant";
import { clearCashRegister } from "../../../api/postApi";

const ClearCashPop = ({ openModal, setModalOpen }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const dispatch = useDispatch();
  const clearCashPop = useSelector((state) => state.isClearCashPop);

  const toast = useToast();

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleClearCash = async () => {
    try {
      setButtonLoading(true); // 設置按鈕 loading 狀態
      await clearCashRegister(); // 呼叫棄分 API
      dispatch({ type: "GET_BALANCE" });
      // 成功提示
      toast({
        title: i18n("foldSuccess"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      dispatch(closeClearCash()); // 關閉彈窗
    } catch (error) {
      // 失敗提示
      toast({
        title: i18n("foldFailed"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setButtonLoading(false); // 關閉按鈕 loading
    }
  };
  return (
    <CommonConfirmModal
      modalProps={{
        title: i18n("fold"),
        okButtonText: i18n("confirm"),
        buttonLoading: buttonLoading,
        content: (
          <>
            <Flex
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              gap="15px"
            >
              {/* <Image
                w="150px"
                src={`${COMMON_WEB_PATH}/v4-layout/clear_cache.png`}
              /> */}
              <Text>{i18n("confirm-clearCash")}</Text>
            </Flex>
          </>
        ),
        cancelButtonText: i18n("cancel"),
        footerStyle: {
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        },
        buttonStyle: {
          width: "100%",
          mr: "0",
          borderRadius: "10px",
          height: "40px",
        },
        confirmButtonStyle: {
          width: "100%",
          bgGradient: "linear(to-b, #4D77FB, #2755FE)",
          color: "white",
          _hover: {
            bgGradient: "linear(to-b, #2755FE, #4D77FB)",
            opacity: 0.9,
          },
        },
      }}
      onConfirm={() => {
        handleClearCash();
      }}
      modalOpen={clearCashPop}
      size="xs"
      onClose={() => {
        dispatch(closeClearCash());
      }}
    />
  );
};

export default ClearCashPop;
