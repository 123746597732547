import React, { Fragment, useMemo } from "react";
import Seo from "./seo";
import { Box, Flex, Text } from "@chakra-ui/react";
import SubListSelector from "../../components/v4Layout/SubListSelector/SubListSelector";
import PlatformSelector from "../../components/v4Layout/PlatformSelector/PlatformSelector";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import GameCatalogV2 from "../../components/GameCatalog_v2";
import GameSelector from "../../components/v4Layout/GameSelector/GameSelector";
import { useSelector } from "react-redux";
import { routeMapping } from "../../components/v4Layout/GameTypeSelector/utils/routeMapping";
import MobileSearchV4 from "../Search/mobile-search_v4";
import CarouselV4 from "../../components/Carousel_v4";
import GameTypeSelectorV4 from "../../components/v4Layout/GameTypeSelector/GameTypeSelector_v4";
import SubListSelectorV4 from "../../components/v4Layout/SubListSelector/SubListSelector_v4";
import PlatformSelectorV4 from "../../components/v4Layout/PlatformSelector/PlatformSelector_v4";
import useCheckLogin from "../../hook/useCheckLogin";

const SlotV4 = ({ gameMainTypeFromProps }) => {
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { gameType, topGameList, newGameList, gameListByTypeAndSubType } =
    isHandleAllGame;
  const { isMachineUser } = useCheckLogin();

  return (
    <Fragment>
      <Seo />
      <Box
        as="section"
        px="common-y"
        pb="home-space"
        display={{ base: "block", xl: "none" }}
      >
        <GameTypeSelectorV4 />
      </Box>
      {/* {!isMachineUser && ( //機台使用者不顯示
        <Box as="section" px="common-y" display={{ base: "block", md: "none" }}>
          <GameTypeSelectorV4 />
        </Box>
      )}
      {!isMachineUser && ( //機台使用者不顯示
        <Box as="section" pt="home-space" px="common-y">
          <SubListSelectorV4
            forceShowAll
            gameMainTypeFromProps={gameMainTypeFromProps}
          />
        </Box>
      )} */}
      {/* <Box as="section" pt="home-space" px="common-y">
        <CarouselV4 />
      </Box> */}
      {/* <Box as="section" pt="home-space" px="common-y">
        <MarqueeListV2 />
      </Box> */}
      {/* {!isMachineUser && ( //機台使用者不顯示
        <Box
          mt={{
            md: "0px",
            // base: "10px",
          }}
          pt="home-space"
        >
          <MobileSearchV4 listHidden />
        </Box>
      )} */}

      <Box flex={1}>
        <Box
          //pb="home-space"
          pb={{
            base: "0px",
            sm: "home-space",
          }}
          px="common-y"
          mt={
            isMachineUser
              ? {
                  lg: "25px",
                  base: "15px",
                }
              : "0"
          }
        >
          <PlatformSelectorV4 gameMainTypeFromProps={gameMainTypeFromProps} />
        </Box>
        <Box
          py="home-space"
          px="common-y"
          mb={{
            base: "60px",
            sm: "0px",
          }}
        >
          <GameSelector gameMainTypeFromProps={gameMainTypeFromProps} />
        </Box>
      </Box>
    </Fragment>
  );
};

export default SlotV4;
