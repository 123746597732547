import React, { useEffect, useMemo, useState } from "react";
import InputCopy from "../../../components/CopyComponent/InputCopy";
import { appName } from "../../../config";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReferWrap from "./ReferWrap";
import { HiCash, HiChevronRight } from "react-icons/hi";
import { formatNumber } from "../../../utils/formatNumber";
import { REPLACE_AIR } from "../../../replace_str_constant";
import { useNavigate } from "react-router";
import LoadingIcon from "../../../components/Loading/LoadingIcon";
import ReferTitle from "./ReferTitle";
import ReferColumn from "./ReferColumn";
import RuleDetail from "./RuleDetail";
import { getCommissionList, getShortLink } from "../../../api/getApi";
import { withdrawCommission } from "../../../api/postApi";
import ComissionListPage from "./ComissionListPage";
import { openComissionListPop } from "../../../redux/action/member/memberAction";
import { notice } from "../../../redux/action/action";
import CommonConfirmModal from "../../../components/Modal/commonConfirmModal";
import QRCode, { QRCodeCanvas } from "qrcode.react";
import ReferQrcode from "./referQrcode";
import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import CopyIcon from "../../../components/CopyComponent/CopyIcon";

const TabYourReferCode = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`referral.${key}`, { ...props });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const textColor = useColorModeValue("dark.100", "light.100");
  const qrBg = useColorModeValue("light.100", "dark.100");
  const commissionBg = useColorModeValue("light.200", "dark.200");
  const commissionColumnBg = useColorModeValue("light.100", "dark.100");

  const [isLoading, setIsLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const memberData = useSelector((state) => state.isMemberInfo);
  const comissionListPop = useSelector((state) => state.isComissionListPop);

  const [cagentCommissionList, setCagentCommissionList] = useState([]); //api回傳的代理傭金列表
  const [cagentCommissionInfo, setCagentCommissionInfo] = useState({
    selfChildCount: 0, //直屬會員
    allChildCount: 0, //搜尋底下會員
    lastWeekChildCount: 0, //上周新增
    thisWeekChildCount: 0, //本週新增
    lastMonthChildCount: 0, //上個月新增
    thisMonthChildCount: 0, //本個月新增
    totalWashSelfToday: 0, //日結自己統計
    totalWashChildToday: 0, //日結子統計
  }); //api回傳的代理傭金資訊
  const [commissionPoint, setCommissionPoint] = useState(0); //api回傳的代理傭金
  const [withdrawStatus, setWithdrawStatus] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [shortLink, setShortLink] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getCommissionList() // 這是獲取傭金資訊的api
      .then((data) => {
        const { cagentInfo, cagentList, point, withdraw } = data;

        setCommissionPoint(point);
        setCagentCommissionList(cagentList);
        setCagentCommissionInfo(cagentInfo);
        setWithdrawStatus(withdraw);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [trigger]);

  useEffect(() => {
    getShortLink()
      .then((data) => {
        setShortLink(data?.short_url);
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  const handleGetCommission = () => {
    setWithdrawLoading(true);
    withdrawCommission() //領傭金api
      .then((data) => {
        dispatch({ type: "GET_BALANCE" });
        dispatch(notice({ title: "Success", type: "success" }));
        setOpenConfirmModal(false);
      })
      .catch((err) => {})
      .finally(() => {
        setWithdrawLoading(false);
        setTrigger(!trigger);
      });
  };

  const FRIENDS_LIST = [
    {
      label: "Total Register Friends",
      value: cagentCommissionInfo?.selfChildCount,
      // span: "col-span-3",
    },
    {
      label: "Direct Register Friends",
      value: cagentCommissionInfo?.allChildCount,
      // span: "col-span-2",
    },
  ];

  const TOTAL_TURNOVER = [
    {
      label: i18n("Your Turnover"),
      symbol: true,
      value: cagentCommissionInfo?.totalWashSelfToday,
      //span: "col-span-2",
    },
    // {
    //   label: i18n("Direct Turnover"),
    //   symbol: true,
    //   value: referDetail?.unlimit_total_thisweek?.selfbet,
    // },
    {
      label: i18n("Agent Turnover"),
      symbol: true,
      value: cagentCommissionInfo?.totalWashChildToday,
    },
  ];

  const REWARD_LIST = [
    {
      label: "ThisWeek Friends",
      value: cagentCommissionInfo?.thisWeekChildCount,
    },
    {
      label: "LastWeek Friends",
      value: cagentCommissionInfo?.lastWeekChildCount,
    },
    {
      label: "ThisMonth Friends",
      value: cagentCommissionInfo?.thisMonthChildCount,
    },
    {
      label: "LastMonth Friends",
      value: cagentCommissionInfo?.lastMonthChildCount,
    },
  ];

  return (
    <Box as="section" display={`flex`} flexDirection={`column`} gap="10px">
      <Box as="section" display="flex" flexDirection={`column`} gap="10px">
        <Box as="section">
          <Flex alignItems={`center`} justifyContent={`space-between`} mb="5px">
            <Text as="p" color={textColor} fontSize={`lg`} fontWeight={`bold`}>
              {i18n("Now Withdraw Comission")}
            </Text>
            <Text
              as="p"
              color={`special.1`}
              fontSize={`xl`}
              fontWeight={`bold`}
            >
              {commissionPoint}
            </Text>
          </Flex>

          <Flex alignItems={`center`} justifyContent={`space-between`} mb="5px">
            <Text className="mb-0 text-gold-text">{i18n("notice.tip1")}</Text>
            <Button
              leftIcon={<HiCash />}
              isLoading={withdrawLoading}
              isDisabled={withdrawLoading || withdrawStatus == 0}
              onClick={() => {
                setOpenConfirmModal(true);
              }}
              size="sm"
              variant="brandPrimary"
            >
              {/* {withdrawStatus == 0 ? i18n("withdraw") : i18n("withdrawing")} */}
              {i18n("withdraw")}
            </Button>
          </Flex>
        </Box>
        <Divider />
        <Text as="p" fontWeight={`medium`} fontSize="lg" color={textColor}>
          {i18n("Referrals")}
        </Text>
        <Box
          as="section"
          fontSize="sm"
          bg={commissionColumnBg}
          color={textColor}
          p="10px"
          borderRadius={`common`}
          display="flex"
          flexDirection={`column`}
          gap="5px"
        >
          <Flex gap="5px" alignItems={`center`}>
            <Text as="span" fontSize="sm" color={textColor}>
              {i18n("Your")}
              {i18n("link")}:
            </Text>
            <Text fontSize={`sm`} fontWeight={`bold`} color={textColor}>
              {shortLink}
            </Text>
            <CopyIcon copyLink={shortLink} />
          </Flex>

          <Flex gap="5px" alignItems={`center`}>
            <Text as="span" fontSize="sm" color={textColor}>
              {i18n("Your")}
              {i18n("code")}:
            </Text>
            <Text fontSize={`sm`} fontWeight={`bold`} color={textColor}>
              {memberData?.memId}
            </Text>
            <CopyIcon copyLink={`${memberData?.memId}`} />
          </Flex>
        </Box>

        <Text as="span" color={textColor} textAlign={`center`}>
          {i18n("Your")}
          QRCode
        </Text>
        <Divider className="!my-[5px]" />
        <Box p="7px" bg={qrBg} mx="auto" borderRadius="common">
          <a className="cursor-pointer" href={shortLink}>
            <ReferQrcode value={shortLink} />
          </a>
        </Box>
      </Box>
      <Divider />

      <ReferWrap>
        <Box
          as="section"
          borderRadius={`common`}
          overflow={`hidden`}
          display="flex"
          flexDirection={`column`}
        >
          {FRIENDS_LIST.map((item, index) => {
            return (
              <Box
                p="8px"
                borderRadius={`common`}
                display="flex"
                alignItems={`center`}
                justifyContent={`space-between`}
                bg={index % 2 === 0 ? commissionColumnBg : commissionBg}
                key={index}
              >
                <Text color="special.1" fontWeight={`semibold`} mb="0px">
                  {i18n(item.label)}
                </Text>
                <Text fontWeight={`bold`}>
                  <LoadingIcon commonLoading={isLoading}>
                    {item.value ?? 0}
                  </LoadingIcon>
                </Text>
              </Box>
            );
          })}
        </Box>

        <Box
          as="section"
          borderRadius={`common`}
          overflow={`hidden`}
          display="flex"
          flexDirection={`column`}
        >
          {TOTAL_TURNOVER.map((item, index) => {
            return (
              <Box
                p="8px"
                borderRadius={`common`}
                display="flex"
                alignItems={`center`}
                justifyContent={`space-between`}
                bg={index % 2 === 0 ? commissionColumnBg : commissionBg}
                key={index}
              >
                <Text color="special.1" fontWeight={`semibold`} mb="0px">
                  {item.label}
                </Text>
                <Text fontWeight={`bold`}>
                  <LoadingIcon commonLoading={isLoading}>
                    {item.value}
                  </LoadingIcon>
                </Text>
              </Box>
            );
          })}
        </Box>
        <Box
          as="section"
          borderRadius={`common`}
          overflow={`hidden`}
          display="flex"
          flexDirection={`column`}
        >
          {REWARD_LIST.map((item, index) => {
            return (
              <Box
                p="8px"
                borderRadius={`common`}
                display="flex"
                alignItems={`center`}
                justifyContent={`space-between`}
                bg={index % 2 === 0 ? commissionColumnBg : commissionBg}
                key={index}
              >
                <Text color="special.1" fontWeight={`semibold`} mb="0px">
                  {" "}
                  {i18n(item.label)}
                </Text>
                <Text fontWeight={`bold`}>
                  <LoadingIcon commonLoading={isLoading}>
                    {item.value || 0}
                  </LoadingIcon>
                </Text>
              </Box>
            );
          })}
        </Box>

        <Box
          display="flex"
          flexDirection={`column`}
          py="8px"
          px="5px"
          fontSize="sm"
          fontWeight={`semibold`}
          textAlign={`start`}
          justifyContent={`space-between`}
          borderRadius={`lg`}
          bg={commissionBg}
          my="10px"
        >
          <Text as="p" className="text-gold-text">
            *{i18n("notice.tip2")}
          </Text>
        </Box>
        <Flex gap="10px" my="10px" alignItems={`center`}>
          <Button
            variant={`brandPrimary`}
            w="100%"
            onClick={() => {
              dispatch(openComissionListPop());
            }}
          >
            {i18n("comissionList")}
          </Button>
        </Flex>
      </ReferWrap>

      <Flex direction={`column`} gap="10px">
        <Text color="textColor" fontWeight={`medium`} fontSize="lg">
          {" "}
          {i18n("rule")}
        </Text>

        {/*規則表格*/}
        <Box
          as="section"
          fontSize="sm"
          bg={commissionColumnBg}
          color={textColor}
          p="16px"
          borderRadius={`common`}
          display="flex"
          flexDirection={`column`}
          gap="5px"
        >
          <Text as="p" fontWeight={`bold`} className="text-gold-text">
            1. {i18n("warning.tip1")}
          </Text>
          <Text as="p" fontWeight={`bold`} color="special.1">
            2. {i18n("warning.tip2")}
          </Text>
          <Text as="p" fontWeight={`bold`} color="special.1">
            3. {i18n("warning.tip3")}
          </Text>
          <Text as="p" fontWeight={`bold`} color="special.1">
            4. {i18n("warning.tip4")}
          </Text>
          <Text as="p" fontWeight={`bold`} color="special.1">
            5. {i18n("warning.tip5", { casino: appName })}
          </Text>
        </Box>
        <RuleDetail />
      </Flex>
      {comissionListPop && (
        <ComissionListPage cagentCommissionList={cagentCommissionList} />
      )}
      {openConfirmModal && (
        <CommonConfirmModal
          modalOpen={openConfirmModal}
          setModalOpen={setOpenConfirmModal}
          onConfirm={() => {
            handleGetCommission();
          }}
          size="mini"
          modalProps={{
            title: i18n("withdrawCommissionTitle"),
            content: i18n("withdrawCommissionContent"),
            cancelButtonText: i18n("cancelButtonText"),
            okButtonText: i18n("okButtonText"),
          }}
        />
      )}
    </Box>
  );
};

export default TabYourReferCode;
