import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { IoCloseOutline } from "react-icons/io5";
import { RiMore2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import UseGetGameBoxBackPath from "../../../hook/useGetGameBoxBackPath";
import SupportCurrency from "./supportCurrency";
import { Box } from "@chakra-ui/layout";
import { closeChat } from "../../../redux/action/member/memberAction";
import UseGetGameBoxBackPathV2 from "../../../hook/useGetGameBoxBackPath_v2";
import { WEB_LAYOUT } from "../../../constant";
import useDeviceType from "../../../hook/useDeviceType";

const ToolTab = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`gamebox.${key}`);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const getPrevPath = UseGetGameBoxBackPath();
  const getPrevPath_v2 = UseGetGameBoxBackPathV2();

  const isMobile = useDeviceType() === "Mobile";

  const gameConfig = useSelector((state) => state.isGameConfig);

  const { to } = useParams();
  const { SubPlatformEnum } = gameConfig;

  const [dragStartTime, setDragStartTime] = useState(0);
  const [sureClose, setSureClose] = useState(false);

  const handleStart = () => {
    setDragStartTime(Date.now());
  };

  const handleStop = () => {
    if (Date.now() - dragStartTime < 200) {
      // dispatch(openAccessTool());
      if (sureClose) {
        if (WEB_LAYOUT == "v2" || WEB_LAYOUT == "v3" || WEB_LAYOUT == "v4") {
          navigate(
            `${getPrevPath_v2}/category/${SubPlatformEnum || to}/AllGames`
          );
        } else {
          navigate(
            `/${getPrevPath}/category/${SubPlatformEnum || to}/AllGames`
          );
        }
      }
      dispatch(closeChat());
      setSureClose(true);
    }
  };

  useEffect(() => {
    if (sureClose) {
      setTimeout(() => {
        setSureClose(false);
      }, 3000);
    }
  }, [sureClose]);

  return (
    <Draggable onStart={handleStart} onStop={handleStop}>
      <Box
        position={"absolute"}
        bg={"#eef0f166"}
        left={"-4px"}
        top={"20px"}
        minW={"40px"}
        h={"44px"}
        rounded={"5px"}
        display={{
          md: "none",
          base: "flex",
        }}
        gap={"10px"}
        alignItems={"center"}
        justifyContent={"center"}
        className={`${isMobile ? "!flex" : ""}`}
      >
        {" "}
        {/* <RiMore2Fill className="text-2xl" /> */}
        {/* {toolbarHint && (
            <span className="mr-[10px]"> {i18n("Quick access tool")}</span>
          )} */}
        {sureClose ? (
          <IoCloseOutline className="text-2xl ml-[10px]" />
        ) : (
          <RiMore2Fill className="text-2xl" />
        )}
        {sureClose && (
          <span className="mr-[10px]"> {i18n("Are your sure close game")}</span>
        )}
      </Box>
    </Draggable>
  );
};

export default ToolTab;
