import { useEffect, useMemo, useRef, useState } from "react";
import SwiperGameList from "./SwiperGameList/SwiperGameList";
import { useLocation, useNavigate } from "react-router";
import React from "react";
import useDeviceType from "../hook/useDeviceType";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useCasinoType from "../hook/useCasinoType";
import ObserveComponents from "../utils/observeComponents";
import LoadingLine from "./Loading/LoadingLine";
import LoadingImage from "./Loading/LoadingImage";
import { useSelector } from "react-redux";
import ObserveInView from "../utils/observeInView";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import {
  Box,
  Flex,
  Heading,
  Text,
  chakra,
  shouldForwardProp,
  useColorModeValue,
} from "@chakra-ui/react";
import { isValidMotionProp, motion } from "framer-motion";
import { gameListFromBottom } from "../animateConstant";

const OBSERVE_TIME = 1000;

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const GameCatalog = ({
  mainTitle,
  gameType,
  itemClass, //遊戲item class
  swiperProps,
  swiperClassName,
  closeComponent, //關閉組件
  wrapperClassName, //外層class
  hiddenHeader, //隱藏header
  directToPlatform, //直接導向平台
  dontTranslateMainTitle, //不翻譯mainTitle
  listType = "game", //game,promotion,sponsor
  list = [], // 列表
  observeFalse,
  hiddenButtonNavigator, //隱藏All ... 按鈕
  forceShow, //強制顯示維護的遊戲
  customGameList, //自定義遊戲列表
  fullImageLayout, // 全圖版型
}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const splideRef = useRef();

  const titleColor = useColorModeValue("dark.100", "light.100");
  const clickBg = useColorModeValue("light.300", "dark.100");
  const clickColor = useColorModeValue("dark.100", "light.200");
  const headingStyle = useColorModeValue(
    {
      // Light mode
      background:
        "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,1) 75%, rgba(0,0,0,1) 100%)",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      color: "transparent",
      textShadow: "0px 0px 0px rgba(0, 0, 0, 0.3)",
    },
    {
      // Dark mode
      background:
        "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(247,249,79,1) 0%, rgba(254,238,54,1) 50%, rgba(245,174,12,1) 75%, rgba(236,208,59,1) 100%)",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      color: "transparent",
      textShadow: "0px 0px 3px rgba(255, 215, 0, 0.3)",
    }
  );

  const casinoType = useCasinoType();

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { game_subtype } = isHandleAllGame;

  const location = useLocation();

  const isGameBox = useMemo(() => {
    return location.pathname.includes("gamebox");
  }, [location.pathname]);

  const findCasinoType = useMemo(() => {
    if (isGameBox) return "casino";
    const subTypeConfig = game_subtype?.find(
      (item) => item.game_subtype === mainTitle
    );
    const belongType =
      mainTitle === "MyFavorite" ||
      mainTitle === "News" ||
      mainTitle === "Top" ||
      mainTitle === "ClickedGames"
        ? casinoType
        : subTypeConfig?.belong_type === "Slots"
        ? "casino"
        : subTypeConfig?.belong_type?.toLowerCase() || "casino";

    return belongType;
  }, [mainTitle]);

  const isMobile = useDeviceType() === "Mobile";

  const handleSlideRight = () => {
    if (splideRef.current) {
      const splide = splideRef.current.splide;
      const currentIndex = splide.index;
      const newIndex = currentIndex + 3;

      splide.go(newIndex);
    }
  };
  const handleSlideLeft = () => {
    if (splideRef.current) {
      const splide = splideRef.current.splide;
      const currentIndex = splide.index;
      const newIndex = currentIndex - 3;

      splide.go(newIndex);
    }
  };

  const listSize = useMemo(() => {
    return isMobile ? list?.slice(0, 15) : list?.slice(0, 30);
  }, [isMobile, list]);

  return (
    <ObserveInView
      props={{
        itemClass: itemClass,
        swiperProps: swiperProps,
        swiperClassName: swiperClassName,
      }}
      observeFalse={observeFalse}
    >
      <Box
        position={"relative"}
        borderRadius={"30px"}
        px={"10px"}
        py={"5px"}
        my={"5px"}
        className={`${closeComponent} ${wrapperClassName}`}
      >
        <Box position={`relative`} zIndex={`10`}>
          {hiddenHeader || (
            <Flex justifyContent={`space-between`} mb="10px">
              <Flex alignItems={`center`}>
                <Heading
                  as="h3"
                  color={titleColor}
                  fontSize={{
                    base: "lg",
                    sm: "2xl",
                  }}
                  mb="0px"
                  fontWeight={`medium`}
                  sx={headingStyle}
                >
                  {mainTitle && !dontTranslateMainTitle
                    ? i18n(mainTitle)
                    : mainTitle}
                </Heading>
              </Flex>
              <Box h="40px">
                <Flex alignItems={`center`} gap="5px">
                  {listType === "game" && (
                    <>
                      {mainTitle && !hiddenButtonNavigator ? (
                        <Link
                          to={
                            directToPlatform
                              ? `/${findCasinoType}/category/${directToPlatform}/AllGames`
                              : `${
                                  findCasinoType ? `/${findCasinoType}/` : ""
                                }category/all/${mainTitle}`
                          }
                        >
                          <Flex
                            bg={"brand.500"}
                            color={"white"}
                            cursor={`pointer`}
                            py="8px"
                            px="20px"
                            height="40px"
                            fontWeight={`medium`}
                            fontSize={`sm`}
                            alignItems={`center`}
                            borderRadius={`common`}
                          >
                            <LoadingLine className={``}>
                              {i18n("all")}{" "}
                              {/* {mainTitle && !dontTranslateMainTitle
                                ? i18n(mainTitle)
                                : mainTitle} */}
                            </LoadingLine>
                          </Flex>
                        </Link>
                      ) : null}
                      <Box
                        display={{ md: "flex", base: "none" }}
                        alignItems={`center`}
                        justifyContent={`center`}
                        borderRadius={`lg`}
                        cursor={`pointer`}
                        width={`40px`}
                        height={`40px`}
                        bg={clickBg}
                        color={clickColor}
                        onClick={handleSlideLeft}
                      >
                        <MdOutlineKeyboardArrowLeft className="text-lg" />
                      </Box>
                      <Box
                        onClick={handleSlideRight}
                        display={{ md: "flex", base: "none" }}
                        alignItems={`center`}
                        justifyContent={`center`}
                        borderRadius={`lg`}
                        cursor={`pointer`}
                        width={`40px`}
                        height={`40px`}
                        bg={clickBg}
                        color={clickColor}
                      >
                        <MdOutlineKeyboardArrowRight className="text-lg" />
                      </Box>
                    </>
                  )}
                </Flex>
              </Box>
            </Flex>
          )}
          <ChakraBox {...gameListFromBottom}>
            <SwiperGameList
              isHot={mainTitle === "Top"}
              forceShow={forceShow}
              ref={splideRef}
              list={listSize}
              listType={listType}
              itemClass={itemClass}
              swiperProps={swiperProps}
              swiperClassName={swiperClassName}
              customGameList={customGameList}
              fullImageLayout={fullImageLayout}
            />
          </ChakraBox>
        </Box>
      </Box>
    </ObserveInView>
  );
};

export default React.memo(GameCatalog);
