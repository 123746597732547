import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../constant";
import SettingIndex from "./settingIndex";
import SettingIndexV4 from "./settingIndex_v4";

const SettingIndexSelector = () => {
  const settingIndex = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <SettingIndex />;
      case "v3":
        return <SettingIndex />;
      case "v4":
        return <SettingIndexV4 />;
      default:
        return <SettingIndex />;
    }
  }, [WEB_LAYOUT]);
  return settingIndex;
};

export default SettingIndexSelector;
