import {
  Box,
  Flex,
  Image,
  Text,
  useColorMode,
  Icon,
  chakra,
  shouldForwardProp,
  Divider,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { stylesConfig } from "../../helpers/stylesConfig";
import { COMMON_ALT } from "../../constant";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoIosArrowForward, IoMdClose } from "react-icons/io";
import { MdEventNote, MdOutlineLanguage } from "react-icons/md";
import { AnimatePresence, isValidMotionProp, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { CloseMenu, controlSubMenu } from "../../redux/action/action";
import useDeviceType from "../../hook/useDeviceType";
import { RiHomeSmileFill, RiMenuUnfoldLine } from "react-icons/ri";
import rouletteImage from "../../images/EventListSelect/active_04.webp";
import checkInImage from "../../images/EventListSelect/active_07.webp";
import useSubTypeList from "../../hook/useSubTypeList";
import { menuIcon } from "./helper/menuIcon";
import useCasinoType from "../../hook/useCasinoType";
import ProfileButton from "../../pages/PROFILE_SETTING/components/profileButton";
import CustomerNameText from "../../pages/PROFILE_SETTING/components/customerNameText";
import Balance from "../../pages/Profile/components/balance";
import { waitTime } from "../../utils/waitTime";
import {
  FaCalendarAlt,
  FaHeadphonesAlt,
  FaMobileAlt,
  FaNewspaper,
} from "react-icons/fa";
import {
  openChat,
  openLanguagePop,
} from "../../redux/action/member/memberAction";
import { headerMenu, v4Menu } from "./helper/headerMenu";
import useProcessEventList from "../../hook/useProcessEventList";
import ProfileCardNotLogin from "../../pages/PROFILE_SETTING/components/profileCardNotLogin";
import useCheckLogin from "../../hook/useCheckLogin";
import CreditPage from "../../utils/credit/credit_page";
import useAppDownloadLink from "../../pages/App/hooks/useAppDownloadLink";
import AppWrapper from "../../pages/App/AppWrapper";
import { TbPigMoney } from "react-icons/tb";
import { BiMoneyWithdraw } from "react-icons/bi";
import ProfileCardNotLoginV4 from "../../pages/PROFILE_SETTING/components/profileCardNotLogin_v4";
import { GoHomeFill } from "react-icons/go";
import ProfileCardV4 from "./components/ProfileCard_v4";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const otherMenu = [
  {
    text: "CUSTOMER SERVICE",
    link: "service-group",
    icon: FaHeadphonesAlt,
    hidden: isCredit,
    iconClassName: {
      color: "brand.500",
    },
    image:
      "https://d1h3qe8voog6w4.cloudfront.net/v4-layout/member-icon/service.svg",
    needLogin: false,
  },
  {
    text: "BASIC",
    link: "settingsOther",
    hidden: isCredit,
    iconClassName: {
      color: "brand.500",
    },
    image:
      "https://d1h3qe8voog6w4.cloudfront.net/v4-layout/member-icon/settings.svg",
    needLogin: true,
  },
  // {
  //   text: "LANGUAGE",
  //   link: "language",
  //   icon: MdOutlineLanguage,
  // },
  // {
  //   text: "BLOG",
  //   link: "blog",
  //   hidden: isCredit,
  //   icon: FaNewspaper,
  // },
];

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const menuSubVariants = {
  open: {
    height: "auto",
  },
  close: {
    height: "0",
  },
};

const MenuV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const textColor = useColorModeValue("light.600", "light.100");
  const menuBg = useColorModeValue("#EFEFF5", "#1A2036");
  const textColorHover = useColorModeValue("dark.600", "brand.500");
  const dividerColor = useColorModeValue("#E2E2E5", "#6574AD");
  const iconColor = useColorModeValue("#A7A7A7", "#6574AD");
  const homeColor = useColorModeValue("#FFFFFF", "#262D47");

  const { isLogin } = useCheckLogin();

  const deviceType = useDeviceType();

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const getCurrentPath = useMemo(() => {
    const result = pathname.split("/");
    return result?.[1];
  }, [pathname]);

  const animationProps = useMemo(() => {
    if (deviceType) {
      return deviceType === "PC"
        ? {
            initial: { x: "0%" },
            animate: { x: "0%" },
            exit: { x: "0%" },
            transition: { duration: 0.15, ease: "easeInOut" },
          }
        : {
            initial: { x: "-100%" },
            animate: { x: "0%" },
            exit: { x: "-100%" },
            transition: { duration: 0.15, ease: "easeInOut" },
          };
    }
  }, [deviceType]);

  const dispatch = useDispatch();
  const isStickyLeft = useSelector((state) => state.isStickyLeft);
  const subMenuControl = useSelector((state) => state.isSubMenuControl);

  return (
    <ChakraBox
      h={`100%`}
      position={isStickyLeft ? "fixed" : "relative"}
      left="0px"
      pb={{ base: "0px" }}
      {...animationProps}
    >
      <Flex
        w={{
          base: "315px",
          md: "100%",
          lg: "100%",
        }}
        minH="100%"
        maxH="100vh"
        bg={menuBg}
        direction={`column`}
        alignItems={`center`}
        gap={{
          base: "10px",
          lg: "25px",
        }}
        pb={{
          base: "0px",
          lg: "20px",
        }}
        pt={{
          base: "0px",
          lg: "20px",
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        overflowY="scroll"
        overflowX="hidden"
        className="no-scrollbar"
        position="relative"
      >
        <Flex w="100%" direction={`column`} gap="12px">
          <Flex pt="50px" px="5px" direction={`column`} gap="12px">
            {isLogin ? (
              <ProfileCardV4 />
            ) : (
              <Box
                display={{
                  base: "block",
                  lg: "none",
                }}
              >
                <ProfileCardNotLoginV4 />
              </Box>
            )}
          </Flex>
          {window.roulettes_activity == 1 ? (
            <Flex direction={`column`} gap="12px">
              <Box
                role="group"
                w="100%"
                pl={{
                  base: "20px",
                  md: "35px",
                }}
                py="5px"
              >
                <Link
                  to={"n-roulette"}
                  onClick={async () => {
                    await waitTime(100);
                    dispatch(CloseMenu());
                  }}
                >
                  <Flex
                    alignItems={`center`}
                    gap="10px"
                    _hover={{
                      color: textColorHover,
                    }}
                    cursor="pointer"
                    mr="20px"
                    _groupHover={{
                      color: textColorHover,
                    }}
                    color={textColor}
                  >
                    <Image
                      className="animate-spin"
                      src={rouletteImage}
                      w="25px"
                      h="25px"
                    />
                    <Text
                      as="h5"
                      fontSize="16px"
                      my="0px"
                      fontWeight={`medium`}
                    >
                      {i18n("LuckyRoulette")}
                    </Text>
                  </Flex>
                </Link>
              </Box>
              <Box h="1.5px" w="100%" bg={dividerColor} my="0px" />
            </Flex>
          ) : null}
          {window.check_in_activity == 1 ? (
            <Flex direction={`column`} gap="12px">
              <Box
                role="group"
                w="100%"
                pl={{
                  base: "20px",
                  md: "35px",
                }}
                py="5px"
              >
                <Link
                  to={"checkin"}
                  onClick={async () => {
                    await waitTime(100);
                    dispatch(CloseMenu());
                  }}
                >
                  <Flex
                    alignItems={`center`}
                    gap="10px"
                    _hover={{
                      color: textColorHover,
                    }}
                    cursor="pointer"
                    mr="20px"
                    _groupHover={{
                      color: textColorHover,
                    }}
                    color={textColor}
                  >
                    <Image src={checkInImage} w="25px" h="25px" />
                    <Text
                      as="h5"
                      fontSize="16px"
                      my="0px"
                      fontWeight={`medium`}
                    >
                      {i18n("check-in")}
                    </Text>
                  </Flex>
                </Link>
              </Box>
              <Box h="1.5px" w="100%" bg={dividerColor} my="0px" />
            </Flex>
          ) : null}

          {v4Menu
            ?.filter((item) => !item.hidden && (!item.needLogin || isLogin))
            // 過濾掉需要登入但未登入的項目
            ?.map((item) => {
              // 根據裝置類型決定導航路徑
              const navigationPath = item.customLink
                ? item.customLink(deviceType)
                : item.link;

              return (
                <Flex direction={`column`} gap="12px">
                  <Box
                    role="group"
                    w="100%"
                    pl={{
                      base: "20px",
                      md: "35px",
                    }}
                    pr={{
                      base: "20px",
                      lg: "10px",
                    }}
                    py="5px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      onClick={async () => {
                        await navigate(`/${navigationPath}`); // 使用動態路徑
                        await waitTime(100);
                        dispatch(CloseMenu());
                      }}
                      key={item.text}
                    >
                      <Flex
                        alignItems={`center`}
                        gap="10px"
                        w="100%"
                        _hover={{
                          color: textColorHover,
                        }}
                        cursor="pointer"
                        mr="20px"
                        _groupHover={{
                          color: textColorHover,
                        }}
                        color={
                          getCurrentPath === item.link
                            ? textColorHover
                            : textColor
                        }
                      >
                        <Image src={item.image} w="25px" h="25px" />
                        {/* <Icon
                          as={item.icon}
                          color="brand.500"
                          size="large"
                          fontSize="25px"
                        /> */}
                        <Text
                          as="h5"
                          fontSize="16px"
                          my="0px"
                          fontWeight={`bold`}
                        >
                          {i18n(item.text.toLowerCase())}
                        </Text>
                      </Flex>
                    </Box>

                    <Icon
                      cursor={"pointer"}
                      color={iconColor}
                      className={`transition duration-300 animate-rotate ${
                        subMenuControl?.[item.link] ? "rotate-180" : ""
                      }`}
                      as={IoIosArrowForward}
                    />
                  </Box>
                  <Box h="1.5px" w="100%" bg={dividerColor} my="0px" />
                </Flex>
              );
            })}
          <AppWrapper show>
            <Flex direction={`column`} gap="12px">
              <Box
                role="group"
                w="100%"
                pl={{
                  base: "20px",
                  md: "35px",
                }}
                py="5px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  as={Link}
                  onClick={() => {
                    dispatch(CloseMenu());
                  }}
                  to={"app"}
                >
                  <Flex
                    alignItems={`center`}
                    gap="10px"
                    _hover={{
                      color: textColorHover,
                    }}
                    cursor="pointer"
                    mr="20px"
                    _groupHover={{
                      color: textColorHover,
                    }}
                    color={textColor}
                  >
                    <Icon
                      as={FaMobileAlt}
                      color="brand.500"
                      size="large"
                      fontSize="25px"
                    />
                    <Text as="h5" fontSize="16px" my="0px" fontWeight={`bold`}>
                      {i18n("download-app")}
                    </Text>
                  </Flex>
                </Box>
                <Icon
                  cursor={"pointer"}
                  color={textColor}
                  className={`transition duration-300 animate-rotate`}
                  as={IoIosArrowForward}
                />
              </Box>
              <Box h="1.5px" w="100%" bg={dividerColor} my="0px" />
            </Flex>
          </AppWrapper>

          {otherMenu
            ?.filter((item) => !item.hidden && (!item.needLogin || isLogin))
            // 過濾掉需要登入但未登入的項目
            ?.map((item) => {
              return (
                <Flex direction={`column`} gap="12px">
                  <Box
                    role="group"
                    w="100%"
                    pl={{
                      base: "20px",
                      md: "35px",
                    }}
                    pr={{
                      base: "20px",
                      lg: "10px",
                    }}
                    py="5px"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      onClick={async () => {
                        if (item.text === "CUSTOMER SERVICE") {
                          await dispatch(openChat());
                          navigate(`/${item.link}`); // 導航到客服頁
                        } else if (item.text === "LANGUAGE") {
                          await dispatch(openLanguagePop());
                        } else {
                          navigate(`/${item.link}`);
                          await waitTime(100);
                          dispatch(CloseMenu());
                        }
                      }}
                      key={item.text}
                    >
                      <Flex
                        alignItems={`center`}
                        gap="10px"
                        w="100%"
                        _hover={{
                          color: textColorHover,
                        }}
                        cursor="pointer"
                        mr="20px"
                        _groupHover={{
                          color: textColorHover,
                        }}
                        color={
                          getCurrentPath === item.link
                            ? textColorHover
                            : textColor
                        }
                      >
                        <Image src={item.image} w="25px" h="25px" />
                        {/* <Icon
                          as={item.icon}
                          color={"brand.500"}
                          size="large"
                          fontSize="25px"
                        /> */}
                        <Text
                          as="h5"
                          fontSize="16px"
                          my="0px"
                          fontWeight={`bold`}
                        >
                          {i18n(item.text.toLowerCase())}
                        </Text>
                      </Flex>
                    </Box>
                    <Icon
                      cursor={"pointer"}
                      color={iconColor}
                      className={`transition duration-300 animate-rotate ${
                        subMenuControl?.[item.link] ? "rotate-180" : ""
                      }`}
                      as={IoIosArrowForward}
                    />
                  </Box>
                  <Box h="1.5px" w="100%" bg={dividerColor} my="0px" />
                </Flex>
              );
            })}
        </Flex>
        <Box
          borderRadius={"10px"}
          w={"40px"}
          h={"40px"}
          bg={homeColor}
          shadow="md"
          position="absolute"
          top="10px"
          right="10px"
          display={{
            base: "flex",
            md: "none",
          }}
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
        >
          <Icon
            as={GoHomeFill}
            fontSize="lg"
            color="#CFD1E1"
            onClick={() => dispatch(CloseMenu())}
          />
        </Box>

        {/* 活動banner */}
        <Box p="20px">
          <Box
            as={Link}
            onClick={() => {
              dispatch(CloseMenu());
            }}
            to={"/promotion"}
          >
            <Image
              borderRadius="10px"
              src="https://d2bqs56avak2y8.cloudfront.net/promotion/viet/social_mobile2.webp"
              w="full"
            />
          </Box>
        </Box>
      </Flex>
    </ChakraBox>
  );
};

export default MenuV4;
