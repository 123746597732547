import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";

const Count = ({ from, to, fixed }) => {
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 2.5,
      onUpdate(value) {
        node.textContent = value.toFixed(fixed || 0);
      },
    });

    return () => controls.stop();
  }, [from, to, fixed]);
  return <p ref={nodeRef} />;
};

export default Count;
