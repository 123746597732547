import {
  AbsoluteCenter,
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import i18next from "i18next";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useMatches, useParams } from "react-router-dom";
import { routeMapping } from "./utils/routeMapping";
import { COMMON_WEB_PATH } from "../../../constant";
import { gameTypeColor } from "./utils/gameTypeColor";
import { exceptGameType } from "./utils/exceptGameType";
import { routeMappingV4 } from "./utils/routeMapping_v4";

const GameTypeSelectorV4 = ({ gameMainTypeFromProps } = {}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const textColor = useColorModeValue("dark.100", "light.100");

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { gameType, gameList } = isHandleAllGame || {};

  const splideRef = useRef();

  const location = useLocation();

  const { pathname } = location;

  //   const getGameType = useMemo(() => {
  //     return pathname.split("/")[1];
  //   }, [pathname]);
  const { gameMainType } = useParams();

  //顯示的遊戲類型
  const combileType = useMemo(() => {
    if (gameType) {
      // 定義要保留的遊戲類型
      const allowedTypes = ["SLOT", "BINGO", "LIVE", "FH", "BLOCKCHAIN"];
      //   // 過濾 gameType，只保留 allowedTypes 中的類型
      //   const filteredGameType = gameType.filter((type) =>
      //     allowedTypes.includes(type)
      //   );
      // 過濾 gameType，只保留 allowedTypes 中的類型，並按照 allowedTypes 的順序排序
      const filteredGameType = allowedTypes.filter((type) =>
        gameType.includes(type)
      );

      return ["LOBBY", ...filteredGameType];
    }
    return [];
  }, [gameType]);

  const gameSubTypeList = useMemo(() => {
    if (gameType) {
      const convertType = combileType.map((item) => {
        return routeMappingV4(item)?.link;
      });

      return [
        ...combileType.map((item, index) => {
          const { link } = routeMappingV4(item);
          const current_game_type = routeMappingV4(item)?.origin;
          const current_game_icon = routeMappingV4(item)?.icon;
          // 對於home 和 provider 類型 不需要檢查 platform_list
          const platform_list =
            item === "home"
              ? ["home"]
              : item === "Providers"
              ? ["Providers"]
              : Object?.keys(gameList[current_game_type] || {});
          return platform_list?.length == 0 && !exceptGameType(item) ? null : (
            <SplideSlide key={index}>
              <Link
                as={(props) => <NavLink {...props} />}
                to={`${link}`}
                _hover={{
                  textDecoration: "none",
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {(props) => {
                  const { isActive } = props;
                  return (
                    <Flex
                      role="group"
                      direction="column"
                      alignItems="center"
                      position="relative"
                      height="80px" // 固定容器高度
                      w={item === "LIVE" ? "80px" : "80px"} // LIVE類型特別加寬
                      justifyContent="center"
                      transition="all 0.3s"
                      p="1px"
                      gap="1px"
                      color="black"
                    >
                      <Box
                        position="relative"
                        width={item === "LIVE" ? "80px" : "71px"} // 根據圖片原始寬度設定
                        height={"71px"} // 根據圖片原始高度設定
                        margin="auto" // 置中
                      >
                        <Image
                          src={`${COMMON_WEB_PATH}/FlyDragon/game-type-icon/mobile/${current_game_type?.toUpperCase()}${
                            isActive ? "_clicked" : ""
                          }.webp?11`}
                          position="absolute"
                          top="50%"
                          left="50%"
                          transform="translate(-50%, -50%)" // 確保圖片置中
                          width="100%"
                          height="100%"
                          objectFit="cover" // 確保圖片完整顯示
                          transition="0.3s"
                          _groupHover={{
                            src: `${COMMON_WEB_PATH}/FlyDragon/game-type-icon/mobile/${current_game_type?.toUpperCase()}_clicked.webp?11`,
                          }}
                        />
                      </Box>
                    </Flex>
                  );
                }}
              </Link>
            </SplideSlide>
          );
        }),
      ];
    }
  }, [gameType, i18next.language, gameList, combileType, textColor]);

  const findCurrentGameTypeIndex = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;

    const processGameType = gameType?.map((item) => {
      const textMapping = routeMappingV4(item);

      return textMapping?.link.replace("/", "");
    });

    return processGameType?.indexOf(mainType);
  }, [gameType, gameMainType, gameMainTypeFromProps]);

  const splideOptions = useMemo(() => {
    return {
      start: findCurrentGameTypeIndex,
      pagination: false,
      arrows: false,
      drag: "free",
      direction: "ttb",
      height: "auto",
      // perPage: 5,
      // perMove: 1,
      gap: 0,
      lazyLoad: "nearby",
      fixedWidth: "70px",
      dragMinThreshold: {
        mouse: 0,
        touch: 10,
      },
      breakpoints: {
        640: {
          fixedHeight: "70px",
          height: "350px",
        },
      },
    };
  }, [findCurrentGameTypeIndex]);

  useEffect(() => {
    if (splideRef.current) {
      const mainType = gameMainTypeFromProps || gameMainType;
      const splide = splideRef.current.splide;
      const processGameType = gameType?.map((item) => {
        const textMapping = routeMappingV4(item);
        return textMapping?.link.replace("/", "");
      });
      splide.go(processGameType?.indexOf(mainType));
    }
  }, [gameType, gameMainType, gameMainTypeFromProps]);

  return (
    <Box
      //borderRadius={"common"}
      overflow="hidden"
      // bgGradient={"linear(to-b,brand.500,brand.600,brand.300,brand.700)"}
      pt="3px"
      position="sticky"
      top={{
        base: "65px",
        md: "10px",
      }}
    >
      <Splide
        ref={splideRef}
        options={{
          start: findCurrentGameTypeIndex,
          pagination: false,
          arrows: false,
          drag: "free",
          // perPage: 5,
          // perMove: 1,
          //gap: "10px",
          lazyLoad: "nearby",
          // fixedWidth: `${100 / combileType?.length}%`,
          fixedWidth: "80px",
          dragMinThreshold: {
            mouse: 0,
            touch: 10,
          },
          breakpoints: {
            mediaQuery: "min",
            1280: {
              fixedHeight: "75px",
              height: "365px",
              // fixedWidth: "75px",
            },

            5000: {
              fixedHeight: "55px",
              height: "auto",
            },
          },
        }}
      >
        {gameSubTypeList}
      </Splide>
    </Box>
  );
};

export default GameTypeSelectorV4;
