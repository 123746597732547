import { stylesConfig } from "../helpers/stylesConfig";
import { useLocation, useNavigate } from "react-router";
import { COMMON_ALT, COMMON_WEB_PATH } from "../constant";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Image,
  Text,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import useCheckLogin from "../hook/useCheckLogin";
import HeaderAppDownload from "../pages/App/HeaderAppDownload";
import BalanceV4 from "./SignInUp/components/Balance_v4";
import { LANGUAGES_MENU } from "../i18n/config";
import MarqueeListV4 from "./marquee/marqueeList_v4";
import { machineLogin } from "../api/getApi";
import ClearCashRegisterButton from "./ClearCashRegisterButton";
import MusicToggleButton from "./MusicToggleButton";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const HeaderV4 = () => {
  const { t, i18n } = useTranslation();
  //const i18n = (key) => t(`common.${key}`);
  const i18n_header = (key) => t(`header.${key}`);
  const i18n_common = (key) => t(`common.${key}`);
  const i18n_profile = (key) => t(`profile.${key}`);

  const iconBg = useColorModeValue("#E9E8F2", "#262D47");
  // 取 登入狀態 機台相關的狀態
  const { isLogin, isMachineDomain, isMachineUser } = useCheckLogin();

  const memberInfo = useSelector((state) => state.isMemberInfo);

  const location = useLocation(); //返回一個location對象，其中包含當前URL的信息。它與window.location最大的區別是，它不會引起頁面重新加載。你可以在任何地方使用它，包括React Hooks組件中。

  const profilePath = useBreakpointValue({
    base: "profile",
    sm: "profile/data",
  });

  return (
    <Box
      width={{
        base: `100%`,
        xl: `100%`,
      }}
      minHeight={{
        sm: "80px",
        base: "80px",
        xl: "125px",
      }}
      position="fixed"
      //flexDirection={"column"}
      mx="auto"
      top="0px"
      // left="0px"
      // bg={headerBg}
      bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/titleBG5.webp')`}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="100%100%"
      //zIndex="1001"
      zIndex={{
        base: "999",
        sm: "1001",
      }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"

      // borderColor={headerBorder}
    >
      <HeaderAppDownload />

      <Flex
        position="absolute"
        left="0px"
        top="0%"
        w={{
          base: isLogin ? "150px" : "150px",
          sm: isLogin ? "150px" : "150px",
          md: isLogin ? "300px" : "250px",
          xl: isLogin ? "300px" : "250px",
        }}
        h={{
          base: isLogin ? "85px" : "85px",
          sm: isLogin ? "100px" : "100px",
          md: isLogin ? "110px" : "110px",
          xl: isLogin ? "130px" : "130px",
        }}
        bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/headerLeft.webp')`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="100% 100%"
        zIndex="1002"
        sx={{
          // 用 sx屬性加上自定義 CSS
          filter: "drop-shadow(0px 0px 10px rgba(0,0,0,0.5))",
        }}
      >
        <Flex
          // display={{
          //   base: "block",
          //   lg: isGameBox ? "block" : "none",
          // }}
          alignItems={`center`}
          color="light.100"
          justifyContent="center"
        >
          <Flex
            alignItems={"center"}
            gap="30px"
            justifyContent={{
              base: "flex-start",
              sm: "center",
            }}
          >
            <Link to="/">
              <Image //用三元運算 (? :) 去判斷 如果已登入就顯示 stylesConfig.loggedInLogo 圖片，否則顯示 stylesConfig.mainLogo 圖片
                //src={stylesConfig.mainLogo}
                src={`${COMMON_WEB_PATH}/FlyDragon/LOGO_animation.png`}
                cursor={"pointer"}
                mr={{
                  base: "0px",
                  lg: "50px",
                }}
                ml={{
                  base: "0px",
                  lg: "0px",
                }}
                // mt={{
                //   base: "5px",
                //   lg: "5px",
                // }}
                height={"100%"}
                maxH={{
                  base: isLogin ? "100%" : "60px",
                  lg: "105px",
                  xl: "105px",
                }}
                width={"100%"}
                maxW={{
                  base: "120px",
                  sm: "120px",
                  md: "135px",
                  lg: "165px",
                  xl: "185px",
                }}
                sx={{
                  "@media screen and (min-width: 1536px)": {
                    maxWidth: "205px",
                  },
                  "@media screen and (min-width: 1920px)": {
                    maxWidth: "225px",
                  },
                }}
                pointerEvents="all"
                alt={COMMON_ALT}
              />
            </Link>
          </Flex>
        </Flex>
      </Flex>

      <Flex justifyContent={"flex-end"} w={"100%"}>
        <Flex
          // ml={{
          //   base: "0px",
          //   lg: isGameBox ? "0px" : "219px",
          // }}
          //bg={headerBg}

          // bgGradient="linear(to-b, #F3F6FD,#)"
          alignItems={`center`}
          justifyContent={{
            base: "flex-end",
            sm: "space-between",
          }}
          pl={{
            md: "20px",
            base: "20px",
            // lg: isGameBox ? "0px" : "245px",
          }}
          pr={{
            md: "20px",
            base: "5px",
          }}
          pt={{
            base: "10px",
            sm: "18px",
          }}
          pb={{
            base: "0px",
            sm: "18px",
          }}
          //width="calc(100% - 300px)"
          width={{
            base: "calc(100% - 130px)",
            sm: "calc(100% - 150px)",
            md: "calc(100% - 250px)",
            lg: "calc(100% - 250px)",
            xl: "calc(100% - 350px)",
          }}
          // maxWidth={{
          //   sm: "1024px",
          //   lg: "1440px",
          // }}
          height="100%"
          overflowY="hidden"
        >
          {isMachineUser && ( //非機台使用者不顯示
            <Flex
              w="auto"
              minW="48px"
              px="25px"
              gap="8px"
              height={{
                base: "60px",
                xl: "70px",
              }}
              bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/NunberBG.webp')`}
              bgPosition="center"
              bgRepeat="no-repeat"
              bgSize="100%100%"
              alignItems={"center"}
              justifyContent={"center"}
              shadow="sm"
            >
              <Text
                color="#7D12ED"
                fontSize={{
                  base: "xl",
                  xl: "2xl",
                }}
                fontWeight="700"
              >
                NO.{memberInfo?.uid || "-"}
              </Text>
            </Flex>
          )}
          <Flex
            alignItems={`center`}
            gap="55px"
            justifyContent={{
              base: "flex-end",
              xl: `space-between`,
            }}
            //w="80%"
            w={{
              base: "100%",
              md: "100%",
              xl: "85%",
            }}
          >
            <Box
              as="section"
              w="auto"
              minW={{
                base: "20%",
                lg: "150px",
                xl: "40%",
              }}
              maxW={{
                base: "50%",
                xl: "70%",
              }}
              display={{
                base: "none",
                lg: "block",
              }}
            >
              <MarqueeListV4 />
            </Box>
            <Flex alignItems={"center"} gap="10px">
              <Box //電腦版
                display={{
                  md: "flex",
                  base: "none",
                }}
                gap={{
                  md: "15px",
                  base: "5px",
                }}
                alignItems={`center`}
              >
                {isLogin && <BalanceV4 />}
              </Box>
              {isLogin ? ( //手機版
                <Flex alignItems={"center"} gap="5px">
                  <Box
                    display={{
                      md: "none",
                      base: "flex",
                    }}
                    // mr={{
                    //   base: "130px",
                    //   sm: "130px",
                    //   xl: "150px",
                    // }}
                  >
                    <BalanceV4 />
                  </Box>
                  {/* <SignInDataV4 /> */}
                  {!isMachineUser && ( //機台使用者不顯示
                    <Flex gap="5px" alignItems={"center"}>
                      <Link to={profilePath}>
                        <Box
                          position="relative"
                          cursor="pointer"
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                          shadow="sm"
                        >
                          <Image
                            src={`${COMMON_WEB_PATH}/FlyDragon/memberBTN.webp`}
                            height={{
                              base: "50px",
                              sm: "60px",
                              xl: "70px",
                            }}
                            minW={{
                              base: "50px",
                              sm: "60px",
                              xl: "70px",
                            }}
                          />
                        </Box>
                      </Link>
                      <Text
                        display={{
                          base: "none",
                          md: "block",
                        }}
                        fontWeight={700}
                      >
                        {memberInfo?.memId}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              ) : (
                <Flex gap="5px">
                  <Link to="signin">
                    <Box
                      initial={{ rotate: 0 }}
                      position="absolute"
                      top="0px"
                      right="0px"
                      cursor="pointer"
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                      shadow="sm"
                    >
                      <Image
                        src={`${COMMON_WEB_PATH}/FlyDragon/loginBTN.webp`}
                        height={{
                          base: "60px",
                          sm: "60px",
                          xl: "70px",
                        }}
                        width={{
                          base: "130px",
                          sm: "130px",
                          xl: "150px",
                        }}
                      />
                    </Box>
                  </Link>
                </Flex>
              )}

              {/* 未登入的機台用戶按鈕 */}
              {/* {!isLogin && isMachineUser && <MachineLoginButton />} */}

              {/* 只有機台用戶已登入時才顯示的棄分 重整按鈕 */}
              {isLogin && isMachineUser && (
                <>
                  {/* 棄分按鈕 */}
                  <ClearCashRegisterButton />
                  {/* 重整按鈕 */}
                  <Box
                    onClick={() => {
                      window.location.reload(); // 重新載入頁面
                    }}
                    position="relative"
                    cursor="pointer"
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    shadow="sm"
                  >
                    <Image
                      src={`${COMMON_WEB_PATH}/FlyDragon/refresh.webp`}
                      height={{
                        base: "60px",
                        xl: "60px",
                      }}
                      minW="60px"
                    />
                  </Box>
                  {/* 音樂按鈕 */}
                  <MusicToggleButton />
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default HeaderV4;
