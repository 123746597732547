import { useSpring, useTransform } from "framer-motion";
import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { formatNumber } from "../../../utils/formatNumber";

const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

const AnimateCount = (
  { value, variant, playMusic } = {
    value: Number,
    variant: Object,
    playMusic: Boolean,
  }
) => {
  const transformNumber = Number(value);
  const spring = useSpring(
    transformNumber,
    { ...variant } || {
      mass: 0.8,
      stiffness: 60,
      damping: 17.5,
    }
  );
  const display = useTransform(spring, (current) => {
    const clampedCurrent = clamp(current, 0, Infinity);
    return clampedCurrent.toFixed(2);
  });

  useEffect(() => {
    setTimeout(() => {
      spring.set(transformNumber);
    }, variant?.delay || 0);
  }, [spring, transformNumber, variant]);

  return <motion.span>{display}</motion.span>;
};

export default AnimateCount;
