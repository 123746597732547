import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { routeMapping } from "../GameTypeSelector/utils/routeMapping";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  AbsoluteCenter,
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  chakra,
  shouldForwardProp,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import LazyImage from "../../Loading/lazyImage";
import { COMMON_WEB_PATH } from "../../../constant";
import { thisImageNeedDark } from "../../../utils/thisImageNeedDark";
import { IoLogoGameControllerB } from "react-icons/io";
import { useTranslation } from "react-i18next";
import NotFound from "../../ProgressBlock/NotFound";
import { TiThSmallOutline } from "react-icons/ti";
import TitleIcon from "./components/titleIcon";
import { gameListFromBottom } from "../../../animateConstant";
import { isValidMotionProp, motion } from "framer-motion";
import useSound from "../../../hook/useSound";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const PlatformSelectorV4 = ({ gameMainTypeFromProps }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);

  const boxBg = useColorModeValue("#E9E8F2", "#394367");
  const textColor = useColorModeValue("#757576", "#E9E8F2");

  const { colorMode, toggleColorMode } = useColorMode();

  const { gameList } = isHandleAllGame || {};

  const scrollRef = useRef();
  const splideRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  //   const getGameType = useMemo(() => {
  //     return pathname.split("/")[1];
  //   }, [pathname]);
  const { gameMainType, gamePlatform } = useParams(); // 獲取路由參數
  const playButtonSound = useSound();
  const hoverButtonSound = useSound({
    customSound: "slide_sound",
    volume: 0.3,
  });

  const getPlatform = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;
    if (gameList) {
      // 通過routeMapping轉換遊戲類型
      const current_game_type = routeMapping(mainType)?.origin;
      return {
        // 獲取該遊戲類型下所有平台
        platform_list: Object?.keys(gameList[current_game_type] || {}),
      };
    }
  }, [gameList, gameMainType, gameMainTypeFromProps]);
  const platformList = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;
    const { platform_list } = getPlatform || {};
    const current_game_type = routeMapping(mainType)?.origin;

    return platform_list?.map((item) => {
      // 獲取每個平台的遊戲數量
      const game_length = gameList?.[current_game_type]?.[item]?.length;
      return (
        <SplideSlide key={item}>
          <Link
            as={NavLink}
            to={`/${mainType}/category/${item}/AllGames`}
            h="170px"
            _hover={{
              textDecoration: "none",
            }}
            onMouseEnter={hoverButtonSound}
            onClick={playButtonSound}
          >
            {({ isActive }) => {
              return (
                <Box
                // borderRadius={`9px`}
                // border="1px solid"
                // borderColor="v3-second-brand.300"
                >
                  <Flex
                    //minW="170px"
                    maxW={{
                      base: "100%",
                      sm: "170px",
                    }}
                    // h="50px"
                    h={{ base: "67px", md: "170px" }}
                    justifyContent={`center`}
                    gap="2.5px"
                    //direction="column"
                    alignItems={`center`}
                    // bg={isActive ? "brand.500" : boxBg}
                    bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/${
                      isActive ? "ProviderNew_clicked" : "ProviderNew"
                    }.webp')`}
                    _hover={{
                      bgImage: `url('${COMMON_WEB_PATH}/FlyDragon/ProviderNew_clicked.webp')`,
                    }}
                    cursor="pointer"
                    bgPosition="center"
                    bgRepeat="no-repeat"
                    bgSize="100%100%"
                    borderRadius={{
                      base: "0px",
                      md: "15px",
                    }}
                    position={`relative`}
                    overflow={`hidden`}
                    py="10px"
                    px="20px"
                  >
                    {/* <AbsoluteCenter
                      h="100%"
                      borderRadius={"full"}
                      w="125%"
                      top="35%"
                      bg={
                        isActive
                          ? "v3-second-brand.500"
                          : "v3-second-brand.500-opacity"
                      }
                    ></AbsoluteCenter> */}
                    <Image
                      w="100%"
                      maxH={"95px"}
                      minH={"20.5px"}
                      className="object-contain relative z-[1]"
                      src={`${COMMON_WEB_PATH}/platformIcon/${thisImageNeedDark(
                        item.replace(/\s+/g, ""), // 移除所有空格
                        colorMode === "dark"
                      )}.png`}
                      alt={item}
                      marginBottom={`5px`}
                    />
                    <Text
                      display={{
                        base: "none",
                        sm: "block",
                      }}
                      fontWeight={`700`}
                      fontSize="24px"
                      color="#E29BFF"
                      position="absolute"
                      bottom="10px"
                    >
                      {item}
                    </Text>
                  </Flex>
                </Box>
              );
            }}
          </Link>
        </SplideSlide>
      );
    });
  }, [getPlatform, gameList, gameMainTypeFromProps, boxBg, textColor]);

  // useEffect(() => {
  //   if (scrollRef.current) {
  //     if (gamePlatform) {
  //       const elementPosition = scrollRef.current.getBoundingClientRect().top;
  //       const offsetPosition = elementPosition + window.pageYOffset;
  //       console.log(elementPosition, offsetPosition, window.pageYOffset);
  //       window.scrollTo({
  //         top: offsetPosition,
  //         behavior: "smooth",
  //       });
  //     }
  //   }
  // }, [gamePlatform]);

  const handlePrev = () => {
    splideRef.current.splide.go(">");
  };

  const handleNext = () => {
    splideRef.current.splide.go("<");
  };

  useEffect(() => {
    const { platform_list } = getPlatform || {};

    if (splideRef.current) {
      const splide = splideRef.current.splide;

      splide.go(platform_list?.indexOf(gamePlatform));
    }
  }, [getPlatform, gamePlatform]);

  return (
    <Box
      position="sticky"
      top="65px"
      ref={scrollRef}
      bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/PlatformBG2.webp')`}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="100% 100%"
      //px="40px"
      px={{
        base: "15px",
        sm: "40px",
      }}
      //py="40px"
      py={{
        base: "15px",
        sm: "40px",
      }}
    >
      {/* <Box mb="10px">
        <Flex>
          <Flex alignItems={`center`} gap="10px" fontWeight={`700`}>
             <TitleIcon icon={IoLogoGameControllerB} />
            <Text fontSize="16px">{i18n("Providers")}</Text>
          </Flex>
        </Flex>
      </Box> */}
      {getPlatform?.platform_list?.length === 0 && <NotFound />}
      <ChakraBox
        key={gameMainTypeFromProps || gameMainType}
        {...gameListFromBottom}
        position="relative"
      >
        <Splide
          ref={splideRef}
          options={{
            pagination: false,
            arrows: false,
            drag: "free",
            // perPage: 4,
            // perMove: 1,
            gap: "15px",
            lazyLoad: "nearby",
            fixedWidth: "170px",
            dragMinThreshold: {
              mouse: 0,
              touch: 10,
            },
            breakpoints: {
              640: {
                fixedWidth: "105px",
                gap: "8px",
              },
            },
          }}
        >
          {platformList}
        </Splide>
        <AbsoluteCenter
          onClick={handlePrev}
          cursor="pointer"
          w="45px"
          left="102%"
          display={{
            base: "none",
            md: "block",
          }}
        >
          {" "}
          <Image
            src={`${COMMON_WEB_PATH}/FlyDragon/R_slide.webp`}
            alt="arrow-left"
            h={{
              base: "40px",
              sm: "100%",
            }}
          />
        </AbsoluteCenter>
        <AbsoluteCenter
          onClick={handleNext}
          cursor="pointer"
          w="45px"
          left="-2%"
          display={{
            base: "none",
            md: "block",
          }}
        >
          {" "}
          <Image
            src={`${COMMON_WEB_PATH}/FlyDragon/L_slide.webp`}
            alt="arrow-left"
            h={{
              base: "40px",
              sm: "100%",
            }}
          />
        </AbsoluteCenter>
      </ChakraBox>
    </Box>
  );
};

export default PlatformSelectorV4;
