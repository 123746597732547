import { FaChess, FaChessBoard, FaRocket } from "react-icons/fa";
import { FaFishFins } from "react-icons/fa6";
import { GiEightBall } from "react-icons/gi";
import { IoGameController } from "react-icons/io5";
import { MdCasino, MdSportsSoccer } from "react-icons/md";
import { PiNumberSquareSevenFill } from "react-icons/pi";

export const menuIconV2 = (casino) => {
  switch (casino) {
    case "slots":
    case "SLOT":
      return {
        icon: PiNumberSquareSevenFill,
        text: "SLOT",
      };
    case "fish":
    case "FH":
      return {
        icon: FaFishFins,
        text: "FH",
      };
    case "table":
    case "TABLE":
      return {
        icon: FaChess,
        text: "TABLE",
      };
    case "block-chain":
    case "BLOCKCHAIN":
      return {
        icon: FaRocket,
        text: "BLOCKCHAIN",
      };
    case "arcade":
    case "EGAME":
      return {
        icon: IoGameController,
        text: "EGAME",
      };
    case "sports":
    case "SPORT":
      return {
        icon: MdSportsSoccer,
        text: "SPORT",
      };
    case "live-casino":
    case "LIVE":
      return {
        icon: MdCasino,
        text: "LIVE",
      };
    case "bingo":
    case "BINGO":
      return {
        icon: GiEightBall,
        text: "BINGO",
      };
    default:
      return {
        icon: null,
      };
  }
};
