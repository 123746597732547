import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COMMON_WEB_PATH, COMMON_WEB_PATH_JP } from "../../../constant";
import { REPLACE_AIR } from "../../../replace_str_constant";
import { languageTransfer } from "../../../utils/languageTransfer";
import i18next from "i18next";
import { RiUserFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import useCheckLogin from "../../../hook/useCheckLogin";
import { CloseMenu, notice } from "../../../redux/action/action";
import {
  clearGameLink,
  startGameLoading,
  storeGameConfig,
} from "../../../redux/action/game/gameAction";
import useDeviceType from "../../../hook/useDeviceType";
import { getRankingList } from "../../../api/getApi";
import { formatNumber } from "../../../utils/formatNumber";
import useCurrencySymbol from "../../../hook/useCurrencySymbol";
import LeaderBoardWrapper from "../LeaderBoard/LeaderBoardWrapper";

const TopGameBoardV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const boxBg = useColorModeValue("#EBEDFE", "#394367");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.200");

  const handleAllGame = useSelector((state) => state.isHandleAllGame);

  const symbol = useCurrencySymbol();

  const splideRef = useRef();

  const { randomlySelectedGames } = handleAllGame;

  const [rankingList, setRankingList] = useState([]);

  const isMobile = useDeviceType() === "Mobile";
  const dispatch = useDispatch();
  const { isLogin } = useCheckLogin();
  const navigate = useNavigate();
  const location = useLocation();

  const handleEnterGame = useCallback(
    (gameItem) => {
      const { platform, game_name_en, game_uid } = gameItem || {};
      if (!isLogin) {
        dispatch(
          notice({
            title: i18n("Please login first"),
            type: "error",
          })
        );
        navigate("signin");
        return;
      }

      if (isMobile) {
        navigate(`game-confirm/${game_name_en}/${platform}/${game_uid}`);
      } else {
        navigate(`/gamebox/${game_name_en}/${platform}/${game_uid}`);
      }
    },
    [isLogin, navigate, dispatch, isMobile, i18next.language]
  );

  useEffect(() => {
    if (window?.profit_ranking_status == 1) {
      getRankingList().then((data) => {
        const result = data?.data;
        setRankingList(result);
      });
    }
  }, []);
  return (
    <>
      <Flex
        w="100%"
        justifyContent={"space-between"}
        mb={{
          base: "5px",
          md: "0px",
        }}
        alignItems={"flex-end"}
      >
        <Text
          fontSize="xl"
          fontWeight={700}
          sx={{
            color: "#000", //預設黑色
            "@media screen and (max-width: 48em)": {
              background:
                "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(247,249,79,1) 0%, rgba(254,238,54,1) 50%, rgba(245,174,12,1) 75%, rgba(236,208,59,1) 100%)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
              textShadow: "0px 0px 3px rgba(255, 215, 0, 0.3)",
            },
          }}
        >
          {/* {i18n("TOP GAMES")} */}
          {i18n("BETTING PAYOUT")}
        </Text>
        {/* <Text color="brand.500" fontSize="sm">
          more
        </Text> */}
      </Flex>

      {/* 在 md 以上使用垂直列表 */}
      <Box
        display={{ base: "none", md: "block" }}
        maxH="250px" // 設置最大高度，超出後可滾動
        overflowY="auto" // 啟用垂直滾動
        className="no-scrollbar" // 隱藏滾動條但保持功能
        pr="10px" // 為滾動條預留空間
      >
        <Flex
          direction="column"
          gap={{
            base: "10px",
            md: "0px",
          }}
        >
          {rankingList?.map((item, index) => {
            const { platform, game_code, profit } = item;
            return (
              <Box
                key={index}
                //bg={boxBg}
                display={"flex"}
                h="65px"
                alignItems={"center"}
                gap="10px"
                w="full"
                cursor="pointer"
                borderBottom="1px solid"
                borderBottomColor={borderColor}
                _last={{ borderBottom: "none" }} // 最後一個元素不要邊框
                _hover={{
                  // 添加 hover 效果
                  transform: "translateX(5px)",
                  transition: "all 0.2s",
                }}
                onClick={() => handleEnterGame(item)}
              >
                <Box
                  alignItems={"center"}
                  display="flex"
                  gap="5px"
                  overflow={"hidden"}
                  justifyContent={"space-between"}
                  w="100%"
                >
                  <Flex gap="10px" alignItems={"center"}>
                    <Image
                      src={`${COMMON_WEB_PATH_JP}/game-icon-ds88/en/${platform}/${REPLACE_AIR(
                        game_code
                      )}.webp`}
                      w="40px"
                      h="40px"
                      borderRadius={"full"}
                    />
                    <Text
                      className="text-overflow"
                      fontSize="16px"
                      color="#582D0C"
                      fontWeight={700}
                    >
                      {item?.[`game_name_en`] || "-"}
                    </Text>
                  </Flex>
                  <Flex
                    fontSize="24px"
                    color="#fff"
                    alignItems={"center"}
                    gap="5px"
                    fontWeight={600}
                  >
                    {/* <Icon as={RiUserFill} /> */}
                    <Text as="span" color="v3-second-brand.500">
                      {symbol}
                    </Text>
                    <Text>
                      {formatNumber(profit, {
                        point: false,
                      })}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            );
          })}
        </Flex>
      </Box>

      {/* 在 md 以下使用原有的水平輪播 */}
      <Box display={{ base: "block", md: "none" }}>
        <Splide
          ref={splideRef}
          options={{
            pagination: false,
            arrows: false,
            drag: "free",
            // perPage: 5,
            // perMove: 1,
            gap: "10px",
            lazyLoad: "nearby",
            fixedWidth: "175px",
            dragMinThreshold: {
              mouse: 0,
              touch: 10,
            },
            breakpoints: {
              mediaQuery: "min",
              640: {
                fixedHeight: "70px",
                height: "365px",
              },
              5000: {
                fixedHeight: "70px",
                height: "auto",
              },
            },
          }}
        >
          {rankingList?.map((item, index) => {
            const { platform, game_code, profit } = item;
            return (
              <SplideSlide>
                <Box
                  //bg={boxBg}
                  bgGradient="linear(to-b, #fed37f, #ffa92e)"
                  borderRadius={"common"}
                  display={"flex"}
                  h={{
                    base: "65px",
                  }}
                  px="10px"
                  alignItems={"center"}
                  gap="10px"
                  cursor={"pointer"}
                  onClick={() => handleEnterGame(item)}
                >
                  <Box
                    bg="brand.500"
                    borderRadius={"full"}
                    minW="22px"
                    minH="20px"
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    color="#fff"
                  >
                    <Text fontSize="12pt">{index + 1}</Text>
                  </Box>
                  <Box
                    alignItems={"center"}
                    display="flex"
                    gap="5px"
                    overflow={"hidden"}
                  >
                    <Image
                      src={`${COMMON_WEB_PATH_JP}/game-icon-ds88/en/${platform}/${REPLACE_AIR(
                        game_code
                      )}.webp`}
                      w="40px"
                      h="40px"
                      borderRadius={"full"}
                    />

                    <Box>
                      <Text
                        className="text-overflow"
                        fontSize="12pt"
                        fontWeight={700}
                        color="#582D0C"
                      >
                        {item?.[`game_name_en`] || "-"}
                      </Text>
                      <Flex
                        fontSize="10pt"
                        color="#fff"
                        alignItems={"center"}
                        gap="2.5px"
                      >
                        {/* <Icon color="#fff" as={RiUserFill} /> */}
                        <Text as="span" color="brand.700">
                          {symbol}
                        </Text>
                        <Text color="#fff">
                          {" "}
                          {formatNumber(profit, {
                            point: false,
                          })}
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                </Box>
              </SplideSlide>
            );
          })}
        </Splide>
      </Box>
    </>
  );
};

export default TopGameBoardV4;
