import { AbsoluteCenter, Box, Icon, Spinner } from "@chakra-ui/react";
import React from "react";
import { ImSpinner6 } from "react-icons/im";

const LoadingContainer = () => {
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      bg="blackAlpha.700"
      w="100%"
      h="100%"
      zIndex={999}
    >
      <AbsoluteCenter>
        <Icon
          as={ImSpinner6}
          fontSize={"40px"}
          className="animate-spin"
          color="yellow.500"
        />
      </AbsoluteCenter>
    </Box>
  );
};

export default LoadingContainer;
