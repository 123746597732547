import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";

import BankWithdraw from "./components/bankWithdraw";
import { Box } from "@chakra-ui/react";
import BankWithdrawSelector from "./bankWithdrawSelector";

const WithdrawStepOne = ({ washConfig, selectedCurrency }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`withdraw.${key}`);

  return (
    <Box as="section" mb="24px">
      <BankWithdrawSelector
        washConfig={washConfig}
        selectedCurrency={selectedCurrency}
      />
    </Box>
  );
};

export default WithdrawStepOne;
