const BGMReducers = (
  state = {
    enabled: true,
    song: "back2", //預設音樂
  },
  action
) => {
  switch (action.type) {
    case "toggleBGM":
      return action.payload;
    default:
      return state;
  }
};

export default BGMReducers;
