import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const useCheckLogin = () => {
  const isMemberInfo = useSelector((state) => state.isMemberInfo);
  const isLoaderStatus = useSelector((state) => state.isLoaderStatus);
  const { uid, is_machine } = isMemberInfo || {};

  // 判斷是否為機台域名https://pc.ds88開頭
  const isMachineDomain = window.location.hostname.startsWith("pc.");

  // 如果是機台域名 存mac地址的參數到cookie
  React.useEffect(() => {
    if (isMachineDomain) {
      const urlParams = new URLSearchParams(window.location.search);
      const macFromUrl = urlParams.get("mac");
      const existingMac = Cookies.get("mac_address");

      // 只有當 URL 中有 mac 參數，且與現在的 cookie 不同時，更新 cookie
      if (macFromUrl && macFromUrl !== existingMac) {
        Cookies.set("mac_address", macFromUrl);
      }
    }
  }, [isMachineDomain]);

  const state = useMemo(() => {
    const isLogin = !!uid;
    return {
      //原本的isLogin 判斷是否登入
      isLogin,
      isLoading: isLoaderStatus,
      // 新增 機台相關的狀態
      isMachineDomain,
      // 登入後根據 is_machine 判斷，未登入時根據 domain 判斷
      isMachineUser: isLogin ? is_machine === 1 : isMachineDomain,
      // 取 存在cookie的mac地址的參數
      macAddress: isMachineDomain ? Cookies.get("mac_address") : null,
    };
  }, [isMemberInfo, isLoaderStatus, isMachineDomain, is_machine]);

  return state;
};

export default useCheckLogin;
