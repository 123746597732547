import React from "react";
import OriginInputCustom from "../../../components/InputComponents/originInputCustom";
import { useTranslation } from "react-i18next";

const TabInputReferCode = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`referral.${key}`);
  return (
    <>
      <section className="flex flex-col gap-[10px] mb-[20px]">
        <div className="text-center text-transaction-text dark:text-base-light-white">
          {i18n("ReferCode.ReferCodeintro")}
        </div>
        <span className="text-base font-medium text-transaction-text dark:text-base-light-white tracking-tighter">
          {i18n("ReferCode.Bonus code")}
        </span>
        <OriginInputCustom input="text" />
        <button
          type="button"
          className="bg-main-color rounded-[5px] hover:bg-hover-main-color text-white h-[44px] flex items-center justify-center font-semibold text-lg mt-[10px]"
        >
          {i18n("ReferCode.ACTIVATE")}
        </button>
      </section>
    </>
  );
};

export default TabInputReferCode;
