import { useSelector } from "react-redux";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import InputCopy from "../../components/CopyComponent/InputCopy";
import DetailWrapper from "../../components/detailWrapper";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ButtonTab from "../../components/TabComponent/ButtonTab";
import TabYourReferCode from "./components/tabYourReferCode";
import TabInputReferCode from "./components/tabInputReferCode";

const SimpleReferCode = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`referral.${key}`);

  const TAB = [
    { label: i18n("Your referral code"), value: "1" },
    { label: i18n("Enter referral code"), value: "2" },
  ];

  const [tab, setTab] = useState(TAB[0].value);

  const memberData = useSelector((state) => state.isMemberInfo);
  return (
    <DetailWrapper>
      <CloseDetailPage title={i18n("Refer-code")} />
      {/* <section className="mb-[20px]">
        <ButtonTab TABS={TAB} setTab={setTab} tab={tab} />
      </section> */}

      {tab == 1 ? <TabYourReferCode /> : <TabInputReferCode />}
    </DetailWrapper>
  );
};

export default SimpleReferCode;
