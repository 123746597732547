import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import { WEB_LAYOUT } from "../../constant";

const brandPrimary = defineStyle({
  background: WEB_LAYOUT === "v3" ? null : "brand.500",
  bgGradient:
    WEB_LAYOUT === "v3"
      ? "linear(to-br,brand.300,brand.900,brand.700,brand.500,brand.900)"
      : null,
  color: "white",
  fontWeight: "normal",
  fontSize: "md",
  borderRadius: "common",
  shadow: "md",
  _hover: {
    background: WEB_LAYOUT === "v3" ? null : "brand.600",
    bgGradient:
      WEB_LAYOUT === "v3"
        ? "linear(to-br,brand.400,brand.1000,brand.800,brand.600,brand.1000)"
        : null,
    _disabled: {
      background: "brand.600",
      color: "white",
    },
  },
  // let's also provide dark mode alternatives
  //   _dark: {
  //     background: "orange.300",
  //     color: "orange.800",
  //   },
});

const brandGradientPrimary = defineStyle({
  bgGradient: "linear(to-b, brand.500, brand.700)",
  color: "white",
  fontWeight: "normal",
  fontSize: "md",
  borderRadius: "common",
  _hover: {
    background: "brand.600",
    _disabled: {
      background: "brand.600",
      color: "white",
    },
  },
  // let's also provide dark mode alternatives
  //   _dark: {
  //     background: "orange.300",
  //     color: "orange.800",
  //   },
});

const secondBrandPrimary = defineStyle({
  bgGradient: "linear(to-b, v3-second-brand.600, v3-second-brand.900)",
  color: "white",
  fontWeight: "normal",
  fontSize: "md",
  borderRadius: "common",
  _hover: {
    bgGradient: "linear(to-b, v3-second-brand.700, v3-second-brand.800)",
    _disabled: {
      bgGradient: "linear(to-b, v3-second-brand.700, v3-second-brand.800)",
      color: "white",
    },
  },
  // let's also provide dark mode alternatives
  //   _dark: {
  //     background: "orange.300",
  //     color: "orange.800",
  //   },
});

const specialGoldPrimary = defineStyle({
  bg: "special.gold",
  color: "black",
  fontWeight: "normal",
  fontSize: "md",
  borderRadius: "common",
  _hover: {
    background: "special.gold",
    _disabled: {
      background: "special.gold",
      color: "black",
    },
  },
  // let's also provide dark mode alternatives
  //   _dark: {
  //     background: "orange.300",
  //     color: "orange.800",
  //   },
});

export const buttonTheme = defineStyleConfig({
  variants: {
    brandPrimary,
    brandGradientPrimary,
    specialGoldPrimary,
    secondBrandPrimary,
  },
});
