import { Box, chakra, Flex, shouldForwardProp, Text } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { parseSomething } from "../../utils/parseSomething";
import { useDispatch, useSelector } from "react-redux";
import { isValidMotionProp, motion, AnimatePresence } from "framer-motion";
import { COMMON_WEB_PATH } from "../../constant";
import Count from "./components/Count";
import dayjs from "dayjs";
import useSound from "../../hook/useSound";
import { notice } from "../../redux/action/action";
import { useTranslation } from "react-i18next";
import { storeCurrencyExchange } from "../../redux/action/member/memberAction";
import earningMusic from "../../images/music/earning-money.mp3";

const gradientStyle = {
  background:
    "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(247,249,79,1) 0%, rgba(254,238,54,1) 50%, rgba(245,174,12,1) 75%, rgba(236,208,59,1) 100%)",
  WebkitBackgroundClip: "text",
  backgroundClip: "text",
  color: "transparent",
};

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const animationVariants = {
  hidden: { scale: 0, opacity: 0 },
  visible: {
    scale: 1.5,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 260,
      damping: 20,
      mass: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
  exit: {
    scale: 0,
    opacity: 0,
    transition: { duration: 0.2 },
  },
};

const HEARTBEAT_INTERVAL = 30000;

const EarnMoneyFromSocket = () => {
  const { t } = useTranslation();
  const i18n_backend = (key) => t(`backend_response.${key}`);

  const [isVisible, setIsVisible] = useState(false);
  const [socketUrl, setSocketUrl] = useState("wss://wb.ds88.tw:8443");
  const [messages, setMessages] = useState([]);

  const dispatch = useDispatch();
  const memberInfo = useSelector((state) => state.isMemberInfo);
  const { uid } = memberInfo || {};

  const earningSound = useSound({ customSound: "earning_money" });

  const didUnmount = useRef(false);

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    queryParams: {
      key: "wsky",
    },
    onOpen: () => console.log("連線成功"),
    shouldReconnect: (closeEvent) => {
      /*
      useWebSocket will handle unmounting for you, but this is an example of a 
      case in which you would not want it to automatically reconnect
    */
      return didUnmount.current === false;
    },
    reconnectAttempts: 9999,
    reconnectInterval: 1000,
  });

  const socketReceivedData = useMemo(() => {
    if (lastMessage?.data) {
      return parseSomething(lastMessage.data);
    }
  }, [lastMessage?.timeStamp]);

  const compareMemberData = useMemo(() => {
    if (socketReceivedData?.member_info_uid == uid) {
      return true;
    }
    return false;
  }, [socketReceivedData, uid]);

  const responseComponents = useMemo(() => {
    return (
      <Box
        position="fixed"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Flex direction={"column"} gap="10px" alignItems={"center"}>
          {messages?.map((msg) => {
            const { amount = 0, id, isVisible } = msg;
            return (
              <ChakraBox
                key={id}
                variants={animationVariants}
                initial="hidden"
                animate={isVisible ? "visible" : "exit"}
                bg={"rgba(0,0,0,0.5)"}
                px="30px"
                py="10px"
                borderRadius={"common"}
                bgRepeat="no-repeat"
                bgSize="100% 100%"
                bgPosition="center"
                // onAnimationComplete={() => {
                //   setMessages((prev) => prev.filter((m) => m.id !== id));
                // }}
              >
                <Flex
                  gap="10px"
                  alignItems="center"
                  fontSize="90px"
                  fontWeight={900}
                  {...gradientStyle}
                >
                  <Text>+</Text>
                  <Text>
                    <Count from={0} to={Number(amount)} />
                  </Text>
                </Flex>
              </ChakraBox>
            );
          })}
        </Flex>{" "}
      </Box>
    );
  }, [messages, dispatch]);

  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    if (socketReceivedData) {
      const { type, member_info_uid, data } = socketReceivedData;
      if (type === "deposit" && member_info_uid === uid && data != null) {
        const { amount, exchange } = data || {};
        if (amount == "error") {
          return dispatch(
            notice({
              title: i18n_backend("Server Error"),
              type: "error",
            })
          );
        }
        dispatch(storeCurrencyExchange(exchange || {}));
        new Audio(earningMusic).play();

        const messageWithId = {
          amount: amount,
          id: dayjs().toString(),
          isVisible: true,
        };
        setMessages((prev) => [...prev, messageWithId]);

        setTimeout(() => {
          setMessages((prev) =>
            prev.map((msg) =>
              msg.id === messageWithId.id ? { ...msg, isVisible: false } : msg
            )
          );
          setTimeout(() => {
            setMessages((prev) =>
              prev.filter((msg) => msg.id !== messageWithId.id)
            );
          }, 500);
        }, 4000);
      }
    }
  }, [socketReceivedData, uid]);

  useEffect(() => {
    const heartbeat = setInterval(() => {
      sendMessage("ping");
    }, HEARTBEAT_INTERVAL);

    return () => {
      clearInterval(heartbeat);
    };
  }, [sendMessage]);
  return (
    <Box zIndex={999999} position={"relative"}>
      {/* <AnimatePresence>{responseComponents}</AnimatePresence> */}
      <AnimatePresence>{responseComponents}</AnimatePresence>
    </Box>
  );
};

export default EarnMoneyFromSocket;
