import { useTranslation } from "react-i18next";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import DetailWrapper from "../../components/detailWrapper";
import { useEffect, useState } from "react";
import { getMyRebate } from "../../api/getApi";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { COMMON_WEB_PATH } from "../../constant";
import useCurrencySymbol from "../../hook/useCurrencySymbol";
import { formatNumber } from "../../utils/formatNumber";
import NotFound from "../../components/ProgressBlock/NotFound";
import { waitTime } from "../../utils/waitTime";
import LoadingBlock from "../../components/Loading/LoadingBlock";
import LoadingIcon from "../../components/Loading/LoadingIcon";
import { getMonth, getToday, getWeek, getYesterday } from "../../utils/getDay";
import FromWithdrawHint from "../Withdraw/components/fromWithdrawHint";
import FromWithdrawHintSelector from "../Withdraw/fromWithdrawHintSelector";

const time_list = [
  {
    label: "thisWeek",
    timeFunc: () => getWeek(0, 6),
  },
  {
    label: "lastWeek",
    timeFunc: () => getWeek(-7, -1),
  },
  {
    label: "thisMonth",
    timeFunc: () => getMonth(0),
  },
  {
    label: "lastMonth",
    timeFunc: () => getMonth(1),
  },
];

const PopOver = ({ label, valueText, symbol } = {}) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false);

  return (
    <Tooltip
      label={`${symbol} ${formatNumber(valueText)}`}
      isOpen={isLabelOpen}
    >
      <Box
        display="flex"
        alignItems={`center`}
        gap="3px"
        // bg="linear-gradient(90deg,#f3df00,#ff7300)"
        // bgClip={`text`}
        onMouseEnter={() => setIsLabelOpen(true)}
        onMouseLeave={() => setIsLabelOpen(false)}
        onClick={() => setIsLabelOpen(true)}
      >
        <Text fontSize="12px">{symbol}</Text>
        <Text fontSize="16px" fontWeight={900} whiteSpace={`pre`}>
          {formatNumber(valueText)}
        </Text>
      </Box>
    </Tooltip>

    // <Popover placement="bottom-start" variant={`brandPrimary`}>
    //   <PopoverTrigger>
    //     <Box
    //       display="flex"
    //       alignItems={`center`}
    //       gap="3px"
    //       bg="linear-gradient(90deg,#f3df00,#ff7300)"
    //       bgClip={`text`}
    //     >
    //       <Text fontSize="12px">{symbol}</Text>
    //       <Text fontSize="16px" fontWeight={900} whiteSpace={`pre`}>
    //         {formatNumber(valueText)}
    //       </Text>
    //     </Box>
    //   </PopoverTrigger>

    //   <PopoverContent inset="-5px auto auto -30px" w="100%" minW="100px">
    //     <PopoverBody>
    //       <Box
    //         display="flex"
    //         gap="3px"
    //         bg="linear-gradient(90deg,#f3df00,#ff7300)"
    //         bgClip={`text`}
    //       >
    //         <Text fontSize="12px">{symbol}</Text>
    //         <Text fontSize="16px" fontWeight={900} whiteSpace={`pre`}>
    //           {formatNumber(valueText)}
    //         </Text>
    //       </Box>
    //     </PopoverBody>
    //   </PopoverContent>
    // </Popover>
  );
};

const SimpleRebate = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`rebate.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const symbol = useCurrencySymbol();

  const buttonBg = useColorModeValue("light.100", "dark.100");
  const buttonText = useColorModeValue("dark.100", "light.100");

  const [rebateData, setRebateData] = useState({
    totalBet: 0,
    totalPaycom: 0,
    totalRebate: 0,
    totalWinOrLose: 0,
    weekListDetail: [],
  });
  const [timeZone, setTimeZone] = useState(() => {
    const { start, end } = getWeek(0, 6);
    return { start, end, label: "thisWeek" };
  });

  const [loading, setLoading] = useState(true);
  const {
    totalBet,
    totalRebate,
    totalWinOrLose,
    weekListDetails,
    totalPaycom,
  } = rebateData || {};

  const bgColor = useColorModeValue("light.100", "dark.100");

  useEffect(() => {
    const apiFetching = async () => {
      setLoading(true);
      await getMyRebate({
        paramsData: {
          start_time: timeZone.start,
          end_time: timeZone.end,
        },
      }).then((res) => {
        setRebateData(res.data);
      });
      await waitTime(500);
      setLoading(false);
    };

    apiFetching();
  }, [timeZone]);

  return (
    <DetailWrapper>
      <CloseDetailPage title={i18n_common("rebate")} />
      {/* <Box mb="10px">
        <FromWithdrawHintSelector />
      </Box> */}
      <Box as="section" display={`flex`} flexDirection={`column`} gap="20px">
        <Box as="section">
          <Grid templateColumns="repeat(3, minmax(0, 1fr))" gap="10px">
            <GridItem
              colSpan={3}
              p="10px"
              bg={bgColor}
              borderRadius="common"
              display="flex"
              gap="2.5px"
              overflow="hidden"
            >
              <Image
                w="40px"
                h="40px"
                src={`${COMMON_WEB_PATH}/vip/friend.avif`}
              />
              <Flex direction={`column`} gap="1px">
                <Text
                  className="text-overflow"
                  fontSize="12px"
                  fontWeight={`bold`}
                  color="special.1"
                >
                  {i18n("Total Bet")}
                </Text>
                <Box
                  // bg="linear-gradient(90deg,#f3df00,#ff7300)"
                  // bgClip={`text`}
                  display="flex"
                  alignItems={`center`}
                  gap="3px"
                >
                  <LoadingIcon commonLoading={loading}>
                    <Text fontSize="12px">{symbol}</Text>
                    <Text fontSize="16px" fontWeight={900} whiteSpace={`pre`}>
                      {totalBet}
                    </Text>
                  </LoadingIcon>
                </Box>
              </Flex>
            </GridItem>
            <GridItem
              colSpan={1}
              py="10px"
              px="5px"
              bg={bgColor}
              borderRadius="common"
              display="flex"
              gap="5px"
            >
              <Flex direction={`column`} gap="1px">
                <Box display="flex" gap="5px">
                  {/* <Image
                    w="17px"
                    h="17px"
                    src={`${COMMON_WEB_PATH}/vip/upgrade.avif`}
                  /> */}
                  <Text fontSize="10px" fontWeight={`bold`} color="special.1">
                    {i18n("Total Rewards")}
                  </Text>
                </Box>

                <Box
                // bg="linear-gradient(90deg,#f3df00,#ff7300)"
                // bgClip={`text`}
                >
                  <LoadingIcon commonLoading={loading}>
                    <PopOver valueText={totalRebate} symbol={symbol} />
                  </LoadingIcon>
                </Box>
              </Flex>
            </GridItem>
            <GridItem
              py="10px"
              px="5px"
              bg={bgColor}
              borderRadius="common"
              display="flex"
              gap="5px"
              overflow="hidden"
            >
              <Flex direction={`column`} gap="1px">
                <Box display="flex" gap="5px">
                  {/* <Image
                    w="17px"
                    h="17px"
                    src={`${COMMON_WEB_PATH}/vip/commission.avif`}
                  /> */}
                  <Text
                    className="text-overflow"
                    fontSize="10px"
                    fontWeight={`bold`}
                    color="special.1"
                  >
                    {i18n("Win/Lose")}
                  </Text>
                </Box>

                <Box
                // bg="linear-gradient(90deg,#f3df00,#ff7300)"
                // bgClip={`text`}
                >
                  <LoadingIcon commonLoading={loading}>
                    <Text fontSize="16px" fontWeight={900} whiteSpace={`pre`}>
                      <PopOver valueText={totalWinOrLose} symbol={symbol} />
                    </Text>
                  </LoadingIcon>
                </Box>
              </Flex>
            </GridItem>{" "}
            <GridItem
              py="10px"
              px="5px"
              bg={bgColor}
              borderRadius="common"
              display="flex"
              gap="5px"
              overflow="hidden"
            >
              <Flex direction={`column`} gap="1px">
                <Box display="flex" gap="5px">
                  {/* <Image
                    w="17px"
                    h="17px"
                    src={`${COMMON_WEB_PATH}/vip/task_weekly_icon.avif`}
                  /> */}
                  <Text
                    className="text-overflow"
                    fontSize="10px"
                    fontWeight={`bold`}
                    color="special.1"
                  >
                    {i18n("Paycom")}
                  </Text>
                </Box>

                <Box
                // bg="linear-gradient(90deg,#f3df00,#ff7300)"
                // bgClip={`text`}
                >
                  <LoadingIcon commonLoading={loading}>
                    <PopOver valueText={totalPaycom} symbol={symbol} />
                  </LoadingIcon>
                </Box>
              </Flex>
            </GridItem>
          </Grid>
        </Box>
        {/* <Divider /> */}
        <Box as="section">
          <Grid templateColumns="repeat(4, minmax(0, 1fr))" gap="5px">
            {time_list?.map((item) => {
              return (
                <GridItem>
                  <Button
                    bg={timeZone.label === item.label ? "brand.500" : buttonBg}
                    color={
                      timeZone.label === item.label ? "light.100" : buttonText
                    }
                    _hover={{
                      bg: "brand.600",
                    }}
                    onClick={() => {
                      const { start, end } = item.timeFunc();
                      setTimeZone({ start, end, label: item.label });
                    }}
                    px="3px"
                    w="100%"
                  >
                    <Text className="text-overflow" fontSize="sm">
                      {i18n(item.label)}
                    </Text>
                  </Button>
                </GridItem>
              );
            })}

            {/* <GridItem>
              <Button>自訂</Button>
            </GridItem> */}
          </Grid>
        </Box>
        <Box as="section" display="flex" flexDirection={`column`} gap="10px">
          <Text fontWeight={600} fontSize="20px">
            {i18n("past_betting_detail")}
          </Text>
          <LoadingBlock commonLoading={loading}>
            <Accordion allowMultiple>
              {weekListDetails?.map((item) => {
                const {
                  weekEnd,
                  weekStart,
                  totalBet,
                  totalPaycom,
                  totalRebate,
                  totalWinOrLose,
                  details = {},
                } = item || {};

                const process_detail = Object.keys(details || {});

                return (
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        _expanded={{
                          bg: "brand.500",
                          color: "white",
                          borderRadius: "common",
                        }}
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={`600`}
                        >
                          {weekStart} - {weekEnd}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel>
                      <Grid
                        mb="10px"
                        templateColumns="repeat(2, minmax(0, 1fr))"
                        gap="10px"
                      >
                        <GridItem
                          p="10px"
                          bg={bgColor}
                          borderRadius="common"
                          display="flex"
                          gap="5px"
                          overflow="hidden"
                        >
                          <Flex direction={`column`} gap="1px">
                            <Text
                              fontSize="12px"
                              fontWeight={`bold`}
                              color="special.1"
                              className="text-overflow"
                            >
                              {i18n("Bet")}
                            </Text>
                            <Box
                            // bg="linear-gradient(90deg,#f3df00,#ff7300)"
                            // bgClip={`text`}
                            >
                              <PopOver symbol={symbol} valueText={totalBet} />
                            </Box>
                          </Flex>
                        </GridItem>
                        <GridItem
                          colSpan={1}
                          p="10px"
                          bg={bgColor}
                          borderRadius="common"
                          display="flex"
                          gap="5px"
                          overflow="hidden"
                        >
                          <Flex direction={`column`} gap="1px">
                            <Text
                              fontSize="12px"
                              fontWeight={`bold`}
                              color="special.1"
                              className="text-overflow"
                            >
                              {i18n("Rewards")}
                            </Text>
                            <PopOver symbol={symbol} valueText={totalRebate} />
                          </Flex>
                        </GridItem>

                        <GridItem
                          p="10px"
                          bg={bgColor}
                          borderRadius="common"
                          display="flex"
                          gap="5px"
                          overflow="hidden"
                        >
                          <Flex direction={`column`} gap="1px">
                            <Text
                              fontSize="12px"
                              fontWeight={`bold`}
                              color="special.1"
                              className="text-overflow"
                            >
                              {i18n("Win/Lose")}
                            </Text>
                            <PopOver
                              symbol={symbol}
                              valueText={totalWinOrLose}
                            />
                          </Flex>
                        </GridItem>

                        <GridItem
                          p="10px"
                          bg={bgColor}
                          borderRadius="common"
                          display="flex"
                          gap="5px"
                          overflow="hidden"
                        >
                          <Flex direction={`column`} gap="1px">
                            <Text
                              fontSize="12px"
                              fontWeight={`bold`}
                              color="special.1"
                              className="text-overflow"
                            >
                              {i18n("Paycom")}
                            </Text>

                            <PopOver symbol={symbol} valueText={totalPaycom} />
                          </Flex>
                        </GridItem>
                      </Grid>

                      <TableContainer>
                        <Table size="sm">
                          <Thead>
                            <Tr>
                              <Th fontSize="12px" letterSpacing={`tighter`}>
                                {i18n("DATE")}
                              </Th>
                              <Th fontSize="12px" letterSpacing={`tighter`}>
                                {i18n("Platform")}
                              </Th>
                              <Th
                                fontSize="12px"
                                isNumeric
                                letterSpacing={`tighter`}
                              >
                                {i18n("Bet")}
                              </Th>

                              <Th
                                fontSize="12px"
                                isNumeric
                                letterSpacing={`tighter`}
                              >
                                {i18n("Win/Lose")}
                              </Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {process_detail?.map((item) => {
                              return details[item]?.map((plat) => {
                                const { all_platform, netProfit, wagering } =
                                  plat || {};
                                return (
                                  <Tr>
                                    <Td>{item}</Td>
                                    <Td fontWeight={600}>{all_platform}</Td>
                                    <Td fontWeight={600} isNumeric>
                                      <Text as="span" fontSize="10px">
                                        {symbol}
                                      </Text>
                                      {formatNumber(wagering)}
                                    </Td>
                                    <Td
                                      fontWeight={600}
                                      color={
                                        netProfit >= 0 ? "green.500" : "red.500"
                                      }
                                      isNumeric
                                    >
                                      <Text as="span" fontSize="10px">
                                        {symbol}
                                      </Text>
                                      {formatNumber(netProfit)}
                                    </Td>
                                  </Tr>
                                );
                              });
                            })}
                          </Tbody>
                        </Table>
                      </TableContainer>
                      {process_detail?.every(
                        (key) => details[key]?.length == 0
                      ) && <NotFound />}
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </LoadingBlock>
        </Box>
      </Box>
    </DetailWrapper>
  );
};

export default SimpleRebate;
