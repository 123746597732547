import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import TopTab from "../Home/components/topTab";
import { Link } from "react-router-dom";
import LazyImage from "../../components/Loading/lazyImage";
import i18next, { use } from "i18next";
import useDeviceType from "../../hook/useDeviceType";
import Empty from "../../components/Empty/Empty";
import Seo from "./seo";
import NotFound from "../../components/ProgressBlock/NotFound";
import useProcessEventList from "../../hook/useProcessEventList";
import { useTranslation } from "react-i18next";
import { Box, Flex, Grid, Text, useColorModeValue } from "@chakra-ui/react";
import { DATA2_PATH } from "../../constant";
import { languageMapping } from "../../i18n/config";
import GetEventImagePath from "../../hook/useEventImagePath";
import GameTypeSelector from "../../components/v3Layout/GameTypeSelector/GameTypeSelector";
import GameTypeSelectorV4 from "../../components/v4Layout/GameTypeSelector/GameTypeSelector_v4";

const EventPageV4 = ({ gameMainTypeFromProps }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`promotions.${key}`);
  const eventList = useSelector((state) => state.isEventList);
  const isMobile = useDeviceType() === "Mobile";

  const textColor = useColorModeValue("dark.100", "light.100");
  const bgColor = useColorModeValue("light.200", "dark.100");

  const processEventList = useProcessEventList({ event_list: eventList });
  const filter_event_list = useMemo(() => {
    return processEventList?.filter((item) => item?.copywriting_is_active == 1);
  }, [processEventList]);

  return (
    <Box as="main">
      <Seo />
      <Box as="section" px="common-y">
        <GameTypeSelectorV4 gameMainTypeFromProps={gameMainTypeFromProps} />
      </Box>
      <Box
        overflowY="scroll"
        flex={1}
        // h={{
        //   base: "80vh",
        //   md: "auto",
        // }}
      >
        <Box pb="home-space" px="common-y">
          {filter_event_list.length === 0 ? (
            <NotFound />
          ) : (
            <Grid
              as="section"
              // mt="16px"
              templateColumns={{
                base: "repeat(1, minmax(0, 1fr))",
                md: "repeat(2, minmax(0, 1fr))",
                "2xl": "repeat(3, minmax(0, 1fr))",
              }}
              gap="20px"
              w="100%"
            >
              {filter_event_list?.map((item, index) => {
                const { reward_title, reward_sub_title, uid } = item || {};
                const lang = languageMapping(i18next.language);
                return (
                  <Link to={`/promotion/detail/${uid}`} key={index}>
                    <Box
                      as="section"
                      w="100%"
                      borderRadius={`common`}
                      role="group"
                      overflow="hidden"
                      h="270px"
                      position={`relative`}
                      mb="15px"
                      cursor="pointer"
                      border="1px solid"
                      borderColor="leaderBoard.v3-border"
                    >
                      <Box w="100%" h="200px" overflow="hidden">
                        <LazyImage
                          className="object-cover h-full w-full group-hover:scale-[1.15] group-hover:-rotate-3 transition "
                          onError={({ currentTarget }) => {
                            if (!currentTarget.src.includes("fallback")) {
                              currentTarget.onerror = null;
                              currentTarget.src = `${DATA2_PATH}/event/${uid}/en_US/${uid}_en_US_mobile_image.png`;
                            }
                          }}
                          src={`${DATA2_PATH}/event/${uid}/${lang}/${uid}_${lang}_mobile_image.png`}
                        />
                      </Box>
                      <Box
                        bg={"brand.500"}
                        display="flex"
                        flexDirection="column"
                        gap="2.5px"
                        py="10px"
                        px="20px"
                      >
                        <Text
                          as="h4"
                          fontWeight={`semibold`}
                          fontSize="lg"
                          color={textColor}
                        >
                          {reward_title}
                        </Text>
                        <Text
                          as="h5"
                          fontWeight={`medium`}
                          fontSize="sm"
                          color={textColor}
                          className="text-overflow"
                        >
                          {reward_sub_title}
                        </Text>
                      </Box>
                    </Box>
                    {/* <img
              className="w-[150px] h-[160px] absolute top-0 right-0 object-cover"
              src={item}
            /> */}
                  </Link>
                );
              })}
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EventPageV4;
