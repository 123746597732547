import React from "react";
import { useSelector } from "react-redux";
import Marquee from "react-fast-marquee";
import { AiFillSound } from "react-icons/ai";
import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import useMarqueeList from "../../hook/useMarqueeList";
import { COMMON_WEB_PATH } from "../../constant";

const MarqueeListV4 = () => {
  const marqueeList = useMarqueeList();

  const bg = useColorModeValue("light.200", "#262D47");
  const color = useColorModeValue("dark.100", "text.100");

  return (
    <Flex
      //bg={bg}
      color="#fff"
      borderRadius={`full`}
      alignItems={`center`}
      gap={`10px`}
      px="5px"
      py="1.5px"
      w="100%"
      overflow="hidden"
    >
      {/* <Icon as={AiFillSound} color="#fff" /> */}
      <Image src={`${COMMON_WEB_PATH}/FlyDragon/megaphone.webp`} w="40px" />
      <Marquee className="">
        {marqueeList?.map((item, index) => {
          const { subject, content } = item;
          return (
            <Flex mr="40px" key={`marquee-${index}`}>
              {/* <Text fontSize="sm" fontWeight={`semibold`}>
                {subject}:
              </Text> */}
              <Text color="#fff" fontSize="md">
                {content}
              </Text>
            </Flex>
          );
        })}
      </Marquee>
    </Flex>
  );
};

export default MarqueeListV4;
