import {
  Box,
  chakra,
  Flex,
  Image,
  shouldForwardProp,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { COMMON_WEB_PATH } from "../constant";
import { useDispatch, useSelector } from "react-redux";
import { toggleBGM } from "../redux/action/action";
import MyBalance from "../utils/myBalance";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isValidMotionProp, motion } from "framer-motion";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const EndGameDetail = ({ onClose, previousBalance }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const [isClosing, setIsClosing] = useState(false);
  const memberInfo = useSelector((state) => state.isMemberInfo);
  // 只在組件初始化時設置一次時間
  const [currentTime] = useState(dayjs().format("YYYY-MM-DD HH:mm:ss"));
  const navigate = useNavigate();

  const isBGMEnabled = useSelector((state) => state.isBGMEnabled);
  const dispatch = useDispatch();
  // 處理確認按鈕
  const handleConfirm = () => {
    setIsClosing(true);
    dispatch(
      toggleBGM({
        ...isBGMEnabled,
        song: "back2",
        musicVolume: 0.2,
      })
    );
    navigate("/"); // 導航到首頁
    onClose();
  };

  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="rgba(0, 0, 0, 0.8)"
      zIndex="9999"
      backdropFilter="blur(2px)"
      justifyContent="center"
      alignItems="center"
    >
      <ChakraBox
        as={Flex}
        w="520px"
        h="auto"
        bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/hintBG.webp')`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="100% 100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        p={6}
        // 根據狀態使用不同的動畫
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{ duration: 0.3 }}
      >
        <Flex
          fontSize="24px"
          color="white"
          textAlign="center"
          mt={4}
          bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/hintTitleBG.webp')`}
          bgPosition="center"
          bgRepeat="no-repeat"
          bgSize="100% 100%"
          sx={{ width: "-webkit-fill-available" }}
          justifyContent="center"
          alignItems="center"
        >
          <Image
            src={`${COMMON_WEB_PATH}/FlyDragon/LOGO_animation.png`}
            height={{
              base: "60px",
              xl: "80px",
            }}
          />
        </Flex>

        <Text color="#FC97FF" fontWeight="700" fontSize="32px" my={4}>
          {i18n("BETTING PAYOUT")}
        </Text>

        <Box fontSize="32px" color="white" textAlign="center" mb={1}>
          {i18n("machineCode")}
          <Text color="#FC97FF" fontWeight="700">
            {"<NO."}
            {memberInfo?.uid || "-"}
            {">"}
          </Text>
        </Box>
        <Box
          sx={{
            "& > p": {
              // 應用到所有子Text元素
              background:
                "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(247,249,79,1) 0%, rgba(254,238,54,1) 50%, rgba(245,174,12,1) 75%, rgba(236,208,59,1) 100%)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              color: "transparent",
              textShadow: "0px 0px 3px rgba(255, 215, 0, 0.3)",
            },
          }}
          fontSize="32px"
          color="white"
          textAlign="center"
          mb={1}
        >
          {i18n("GAME RESULT")}
          <Text color="#FC97FF" fontWeight="700">
            ${previousBalance}
          </Text>
        </Box>
        <Box fontSize="22px" textAlign="center" mb={2} mt={4}>
          <Text color="#C5B3C7" fontWeight="500">
            {currentTime}
          </Text>
        </Box>

        <Flex gap={4} my={6} justifyContent="center" alignItems="center">
          <Flex
            as="button"
            w="165px"
            h="62px"
            bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/yesBTN.webp')`}
            bgPosition="center"
            bgRepeat="no-repeat"
            bgSize="100% 100%"
            cursor="pointer"
            justifyContent="center"
            alignItems="center"
            onClick={handleConfirm}
            _hover={{ filter: "brightness(1.1)" }}
          >
            <Image
              src={`${COMMON_WEB_PATH}/FlyDragon/yesWORD.webp`}
              width="70px"
              height={{
                base: "30px",
                xl: "30px",
              }}
            />
          </Flex>
        </Flex>
      </ChakraBox>
    </Flex>
  );
};

export default EndGameDetail;
