import { useNavigate } from "react-router";
import { capitalizeFirstLetter } from "../../../replace_str_constant";
import {
  MdKeyboardArrowRight,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import LazyImage from "../../../components/Loading/lazyImage";
import { COMMON_WEB_PATH, CURRENCY_SYMBOL } from "../../../constant";
import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useMemo } from "react";

const SelectMethod = ({
  currency,
  min,
  max,
  methodProps,
  isDuplicate = false,
}) => {
  const navigate = useNavigate();

  const boxBg = useColorModeValue("light.100", "dark.100");
  const boxHoverBg = useColorModeValue("light.300", "dark.300");
  const textColor = useColorModeValue("dark.100", "light.100");

  const {
    method_name,
    isVip,
    uid,
    method_id,
    address = "",
    chain = "",
    isIndex,
    bank_name,
    currency_code = "",
  } = methodProps || {};

  const paymentRouter = useMemo(() => {
    if (address !== "") {
      return "payment-crypto";
    } else if (isVip) {
      return "payment-vip";
    } else {
      return "payment-method";
    }
  }, [address, isVip]);
  console.log(min, max);
  return (
    <Box
      as="section"
      bg={boxBg}
      _hover={{
        bg: boxHoverBg,
      }}
      shadow="md"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      p="12px"
      mb="10px"
      h="72px"
      cursor={`pointer`}
      borderRadius={`10px`}
      onClick={() => {
        navigate(
          `${paymentRouter}/${
            isVip ? uid : method_id
          }?method=${method_name}&min=${min}&max=${max}&currency=${currency_code}&cryptoAddress=${address}&chain=${chain}`
        );
      }}
    >
      <Flex gap="20px" alignItems="center" justifyContent="flex-start">
        <Box display="flex" justifyContent={`center`}>
          <Image
            w="45px"
            h="45px"
            borderRadius={"5px"}
            objectFit={`cover`}
            src={`${COMMON_WEB_PATH}/paymentIcon/${method_name?.toLowerCase()}pay.png`}
          />
        </Box>
        <Flex
          direction={`column`}
          gap="10px"
          className="flex flex-col gap-[10px]"
        >
          <Text as="p" color={textColor} fontWeight={`semibold`} mb="0">
            {isVip ? bank_name : capitalizeFirstLetter(method_name)}
            &nbsp;
            {isVip && (
              <Text
                as="span"
                ml="5px"
                bg="yellow.400"
                color="white"
                borderRadius={`full`}
                fontSize="xs"
                px="7px"
                py="1px"
              >
                VIP
              </Text>
            )}
          </Text>
          <Text as="p" fontSize="sm" mb="0" color={textColor}>
            {isDuplicate ? null : min || max ? (
              `${min} ${CURRENCY_SYMBOL?.[currency]} - ${max} ${CURRENCY_SYMBOL?.[currency]}`
            ) : (
              <div>&nbsp;</div>
            )}
          </Text>
        </Flex>
      </Flex>
      <Icon
        as={MdKeyboardArrowRight}
        fontSize="lg"
        fontWeight={`semibold`}
        color={textColor}
      />
    </Box>
  );
};

export default SelectMethod;
