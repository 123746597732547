import {
  Box,
  Flex,
  Image,
  Text,
  useColorMode,
  chakra,
  shouldForwardProp,
  useColorModeValue,
  AbsoluteCenter,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isValidMotionProp, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import useDeviceType from "../../hook/useDeviceType";
import useCasinoType from "../../hook/useCasinoType";
import { pcMenu } from "./helper/headerMenu";
import useCheckLogin from "../../hook/useCheckLogin";
import useAppDownloadLink from "../../pages/App/hooks/useAppDownloadLink";
import { COMMON_WEB_PATH } from "../../constant";
import useSound from "../../hook/useSound";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const MenuPcV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const i18n_header = (key) => t(`header.${key}`);

  const borderColor = useColorModeValue("#707070", "#FAFAFA");
  const { colorMode } = useColorMode();

  const deviceType = useDeviceType();

  const location = useLocation();
  const { pathname } = location;
  const currentPath = useMemo(() => {
    const paths = pathname.split("/").filter(Boolean); // 過濾空字串
    return paths[0] || "/"; // 如果是空的（首頁），返回 '/'
  }, [pathname]);

  const playButtonSound = useSound();
  const hoverButtonSound = useSound({
    customSound: "slide_sound",
    volume: 0.3,
  });

  const animationProps = useMemo(() => {
    if (deviceType) {
      return deviceType === "PC"
        ? {
            initial: { x: "0%" },
            animate: { x: "0%" },
            exit: { x: "0%" },
            transition: { duration: 0.15, ease: "easeInOut" },
          }
        : {
            initial: { x: "-100%" },
            animate: { x: "0%" },
            exit: { x: "-100%" },
            transition: { duration: 0.15, ease: "easeInOut" },
          };
    }
  }, [deviceType]);

  const isStickyLeft = useSelector((state) => state.isStickyLeft);

  return (
    <ChakraBox
      h={`100%`}
      position={isStickyLeft ? "fixed" : "relative"}
      left="0px"
      pb={{ base: "0px" }}
      {...animationProps}
    >
      <Flex
        w={{
          base: "315px",
          md: "100%",
          lg: "100%",
        }}
        minH="100%"
        maxH="100vh"
        bg="transparent"
        direction={`column`}
        alignItems={`center`}
        gap={{
          base: "10px",
          lg: "25px",
        }}
        py="20px"
        // mt="20px"
        onClick={(event) => {
          event.stopPropagation();
        }}
        overflowY="scroll"
        overflowX="hidden"
        className="no-scrollbar"
        position="fixed"
      >
        <Flex
          w="100%"
          direction={`column`}
          alignItems={`flex-start`}
          px="10px"
          gap="20px"
        >
          {pcMenu.map((item) => {
            // 統一格式來比對
            const itemPath = item.link.startsWith("/")
              ? item.link.slice(1)
              : item.link;
            const isActive =
              (itemPath === "" && currentPath === "/") ||
              (itemPath !== "" && currentPath === itemPath);

            return (
              <Box
                as={Link}
                onClick={playButtonSound}
                onMouseEnter={hoverButtonSound}
                key={item.text}
                to={item.link}
                display="flex"
                flexDirection={"column"}
                alignItems="center"
                justifyContent="center"
                gap="5px"
              >
                <Flex
                  direction="column"
                  align="center"
                  justify="flex-start"
                  // w={item.text === "Lobby" ? "150px" : "150px"} // Lobby 特別大
                  // h={item.text === "Lobby" ? "150px" : "150px"} // Lobby 特別大
                  w={{
                    base: "230px",
                    // sm: "130px",
                    // md: "140px",
                    // lg: "150px",
                    // xl: "160px",
                  }}
                  minH={{
                    base: "98px",
                  }}
                  borderRadius="full"
                  bgImage={`url(${COMMON_WEB_PATH}/FlyDragon/${item.text?.toUpperCase()}${
                    isActive ? `_active` : ""
                  }.png)`}
                  _hover={{
                    bgImage: `url(${COMMON_WEB_PATH}/FlyDragon/${item.text?.toUpperCase()}_active.png)`,
                  }}
                  transition={`all 0.3s`}
                  bgSize="cover"
                  position="relative"
                  role="group"
                >
                  {/* <Box
                    // w={item.text === "Lobby" ? "95px" : "95px"}
                    // h={item.text === "Lobby" ? "95px" : "95px"}
                    w={{
                      base: "95px",
                      sm: "90px",
                      md: "95px",
                      lg: "105px",
                      xl: "110px",
                    }}
                    h={{
                      base: "95px",
                      sm: "90px",
                      md: "95px",
                      lg: "105px",
                      xl: "110px",
                    }}
                    bgImage={`url(${COMMON_WEB_PATH}/FlyDragon/game-type-icon/${item.text?.toUpperCase()}Pic.webp)`}
                    _groupHover={{
                      transform: "scale(1.2)",
                      bgImage: `url(${COMMON_WEB_PATH}/FlyDragon/game-type-icon/${item.text?.toUpperCase()}Pic_clicked.webp)`,
                    }}
                    transition={`all 0.3s`}
                    bgSize="contain"
                    bgRepeat="no-repeat"
                    position="relative"
                  ></Box> */}

                  {/* <AbsoluteCenter
                    //w="150px"
                    w={{
                      base: "150px",
                      sm: "150px",
                      md: "150px",
                      lg: "160px",
                      xl: "170px",
                    }}
                    top="90%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      // w={(() => {
                      //   if (item.text?.toUpperCase() === "LIVE") return "130px";
                      //   if (item.text?.toUpperCase() === "SLOT") return "110px";
                      //   if (item.text === "Lobby") return "95px";
                      //   return "95px";
                      // })()}
                      w={(() => {
                        if (item.text?.toUpperCase() === "LIVE") {
                          return {
                            base: "130px",
                            sm: "130px",
                            md: "130px",
                            lg: "140px",
                            xl: "150px",
                          };
                        }
                        if (item.text?.toUpperCase() === "SLOT") {
                          return {
                            base: "110px",
                            sm: "11px",
                            md: "110px",
                            lg: "120px",
                            xl: "130px",
                          };
                        }
                        if (item.text === "Lobby") {
                          return {
                            base: "95px",
                            sm: "90px",
                            md: "95px",
                            lg: "105px",
                            xl: "110px",
                          };
                        }
                        return {
                          base: "95px",
                          sm: "90px",
                          md: "95px",
                          lg: "105px",
                          xl: "110px",
                        };
                      })()}
                      //h={item.text === "Lobby" ? "95px" : "95px"}
                      h={{
                        base: "95px",
                        sm: "90px",
                        md: "95px",
                        lg: "105px",
                        xl: "110px",
                      }}
                      bgImage={`url(${COMMON_WEB_PATH}/FlyDragon/game-type-icon/${item.text?.toUpperCase()}_tw.webp)`}
                      _groupHover={{
                        transform: "rotate(-10deg) scale(1.2)",
                        bgImage: `url(${COMMON_WEB_PATH}/FlyDragon/game-type-icon/${item.text?.toUpperCase()}_tw_clicked.webp)`,
                      }}
                      transition={`all 0.3s`}
                      bgSize="contain"
                      bgRepeat="no-repeat"
                      position="relative"
                    ></Box>
                    
                  </AbsoluteCenter> */}
                </Flex>
              </Box>
            );
          })}
        </Flex>
      </Flex>
    </ChakraBox>
  );
};

export default MenuPcV4;
