import React, { useEffect, useState } from "react";
import useDeviceType from "./useDeviceType";

const useIsLandScape = ({ isMobile = false } = {}) => {
  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia("(orientation: landscape)").matches
  );

  const isMobileDevice = useDeviceType() === "Mobile";

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: landscape)");
    const handleOrientationChange = () => setIsLandscape(mediaQuery.matches);

    mediaQuery.addEventListener("change", handleOrientationChange);
    return () =>
      mediaQuery.removeEventListener("change", handleOrientationChange);
  }, []);
  if (isMobile) {
    return isMobileDevice && isLandscape;
  }
  return isLandscape;
};

export default useIsLandScape;
