import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../constant";
import BankWithdrawV4 from "./components/bankWithdraw_v4";
import BankWithdraw from "./components/bankWithdraw";

const BankWithdrawSelector = (props) => {
  const bankWithdraw = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v4":
        return <BankWithdrawV4 {...props} />;
      default:
        return <BankWithdraw {...props} />;
    }
  }, [props]);
  return bankWithdraw;
};

export default BankWithdrawSelector;
