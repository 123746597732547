import React, { useMemo } from "react";
import { useSelector } from "react-redux";

const useMyBalance = () => {
  const walletCurrency = useSelector((state) => state.isWalletCurrency); //從redux store中取isWalletCurrency的值 存到walletCurrency變數中
  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);

  const balance = useMemo(() => {
    return (
      isCurrencyExchange?.[walletCurrency]?.exchange_amount || "Loading..."
    );
  }, [walletCurrency, isCurrencyExchange]);

  return balance;
};

export default useMyBalance;
