import { useDispatch, useSelector } from "react-redux";

import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import {
  animatePageNotOpacity,
  gameListFromBottom,
} from "../../animateConstant";
import { getFixedStyle } from "../../hook/useDeviceType";
import GetParams from "../../utils/getParams";
import { Base64 } from "js-base64";
import CommonBorder from "../../components/BorderComponent/CommonBorder";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { HiChevronLeft } from "react-icons/hi";
import CloseComponent from "../../components/CloseComponent/CloseComponent";
import DetailWrapper from "../../components/detailWrapper";
import { useEffect, useState } from "react";
import { readInbox } from "../../api/postApi";
import { notice } from "../../redux/action/action";
import { isEmptyObject } from "../../utils/isEmptyObject";
import {
  Box,
  Divider,
  Text,
  chakra,
  shouldForwardProp,
  useColorModeValue,
} from "@chakra-ui/react";
import { getInboxList } from "../../api/getApi";
import { storeInboxList } from "../../redux/action/member/memberAction";
import "../../styles/jodit.css";
import { isValidMotionProp, motion } from "framer-motion";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const InboxDetailV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`inbox.${key}`);

  const navigate = useNavigate();

  const { inboxId } = useParams();

  const titleColor = useColorModeValue("dark.100", "light.100");
  const dateColor = useColorModeValue("light.400", "light.100");
  const dotColor = useColorModeValue("#32383E", "dark.250");

  const [inboxDetail, setInboxDetail] = useState({
    main_subject: "",
    subject: "",
    notification_content: "",
    send_date: "",
    isInbox: 0,
  });
  const { subject, notification_content, send_date, isInbox } = inboxDetail;

  const isInboxDetail = useSelector((state) => state.isInboxDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isInbox == 1) {
      readInbox({ inboxUid: inboxId }).then((data) => {
        getInboxList().then((data) => {
          dispatch(storeInboxList(data?.data));
        });
      });
    }
  }, [inboxId, isInbox]);
  useEffect(() => {
    if (!isEmptyObject(isInboxDetail)) {
      const {
        main_subject,
        notification_content,
        notification_id,
        subject,
        send_date,
        isInbox,
      } = isInboxDetail || {};
      setInboxDetail({
        main_subject,
        notification_content,
        subject,
        send_date,
        isInbox,
      });
    } else {
      navigate("../");
    }
  }, [isInboxDetail]);

  return (
    <DetailWrapper
      wrapperClassName={`h-full w-full md:absolute fixed top-0 left-0 max-h-full z-[1]`}
      closeAnimate
    >
      <CloseDetailPage
        hasPrev={
          <HiChevronLeft
            className="cursor-pointer"
            onClick={() => navigate("../")}
          />
        }
        title={i18n("title")}
      />
      <ChakraBox
        {...gameListFromBottom}
        gap="5px"
        as="section"
        borderRadius={`20px`}
        // borderBottom="5px solid"
        // borderColor="v3-profile.banner-border"
        overflow="hidden"
      >
        <Text
          px="20px"
          color={titleColor}
          fontSize="xl"
          fontWeight={`600`}
          //bg="v3-profile.banner-title"
          //   borderBottom="1px solid"
          //   borderColor="brand.100"
          position="relative"
        >
          {subject}
        </Text>
        <Text
          px="20px"
          color={dateColor}
          fontSize="sm"
          fontWeight={`500`}
          //bg="v3-profile.banner-title"
          //   borderBottom="1px solid"
          //   borderColor="brand.100"
          position="relative"
        >
          {send_date}
        </Text>
        <Box
          pt="15px"
          pb="30px"
          px="20px"
          className="break-words jodit-wysiwyg"
          color={dateColor}
          dangerouslySetInnerHTML={{ __html: notification_content }}
        ></Box>
      </ChakraBox>
    </DetailWrapper>
  );
};

export default InboxDetailV4;
