import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../constant";
import InboxDetail from "./InboxDetail";
import InboxDetailV4 from "./InboxDetail_v4";

const InboxDetailSelector = (props) => {
  const inboxDetail = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v4":
        return <InboxDetailV4 {...props} />;
      default:
        return <InboxDetail {...props} />;
    }
  }, [props]);
  return inboxDetail;
};

export default InboxDetailSelector;
