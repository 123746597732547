import { isValidMotionProp, motion } from "framer-motion";
import React, { useMemo } from "react";
import { commonOpacity } from "../animateConstant";
import useDeviceType, { getFixedStyle } from "../hook/useDeviceType";
import {
  Box,
  chakra,
  shouldForwardProp,
  useColorModeValue,
} from "@chakra-ui/react";
import PageAnimateWrapper from "./Animate/pageAnimateWrapper";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const DetailWrapper = ({
  children,
  wrapperClassName,
  closeAnimate,
  customAnimate,
}) => {
  const indexBg = useColorModeValue("light.200", "dark.200");

  const deviceType = useDeviceType();

  const motionProps = useMemo(() => {
    return closeAnimate
      ? {}
      : closeAnimate
      ? {}
      : deviceType === "PC"
      ? {
          initial: commonOpacity.initial,
          animate: commonOpacity.animate,
          exit: commonOpacity.exit,
          transition: commonOpacity.transition,
        }
      : {
          initial: {
            x: "100%",
          },
          animate: {
            x: "0%",
          },
          transition: {
            duration: 0.3,
            ease: "linear",
          },
        };
  }, [closeAnimate, deviceType, closeAnimate]);

  return (
    <Box
      bg={indexBg}
      overflowX={`hidden`}
      className={` ${getFixedStyle()} ${wrapperClassName}`}
    >
      {closeAnimate ? (
        <ChakraBox {...motionProps}>{children}</ChakraBox>
      ) : (
        <PageAnimateWrapper>
          <ChakraBox {...motionProps}>{children}</ChakraBox>{" "}
        </PageAnimateWrapper>
      )}
    </Box>
  );
};

export default DetailWrapper;
