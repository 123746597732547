import React, { useEffect, useMemo, useState } from "react";
import { BALANCE_RELOAD, COMMON_WEB_PATH } from "../constant";
import { Box, Image } from "@chakra-ui/react";
import StartGameButton from "../pages/Home/StartGameButton";
import { actionLogout, clearCashRegister } from "../api/postApi";
import LoadingPage from "./Loading/LoadingPage";
import { useDispatch, useSelector } from "react-redux";
import ClearCashConfirm from "./ClearCashConfirm";
import EndGameDetail from "./EndGameDetail";
import { AnimatePresence } from "framer-motion";
import useMyBalance from "../hook/useMyBalance";

const ClearCashRegisterButton = () => {
  const walletCurrency = useSelector((state) => state.isWalletCurrency); //從redux store中取isWalletCurrency的值 存到walletCurrency變數中
  const [showStartGame, setShowStartGame] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showEndGame, setShowEndGame] = useState(false);
  const [savedBalance, setSavedBalance] = useState(null); // 儲存棄分前的餘額

  const currentBalance = useMyBalance();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "GET_BALANCE" });
  }, []);

  const handleClearCashRegister = async () => {
    if (isProcessing) return;
    setIsProcessing(true); //防止重複點擊

    try {
      await clearCashRegister().then((data) => {
        const { balance } = data || {};
        setSavedBalance(balance || 0);

        dispatch({ type: "GET_BALANCE" });

        setTimeout(() => {
          setShowConfirm(false);
          setShowEndGame(true);
          // 關閉確認視窗
        }, 0);
      });

      // 等待動畫完成

      // 2.觸發登出但不跳轉
      // dispatch({
      //   type: "USER_LOGOUT",
      //   shouldRedirect: false, // 指定不要跳轉
      // });

      // 3.顯示開始遊戲按鈕
      //setShowStartGame(true);
    } catch (error) {
      console.error("棄分失敗:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  // 點擊棄分按鈕時，顯示確認彈窗
  const handleClick = () => {
    if (currentBalance == 0) return;
    setShowConfirm(true);
  };

  // 取消棄分
  const handleCancel = () => {
    setShowConfirm(false);
  };

  // 處理 EndGameDetail 關閉
  const handleEndGameClose = () => {
    setShowEndGame(false);
  };

  return (
    <AnimatePresence>
      {/* 確認彈窗 */}
      {showConfirm && (
        <ClearCashConfirm
          onConfirm={handleClearCashRegister}
          onCancel={handleCancel}
          isProcessing={isProcessing}
          setIsProcessing={setIsProcessing}
        />
      )}

      {showEndGame && (
        <EndGameDetail
          onClose={handleEndGameClose}
          previousBalance={savedBalance}
        />
      )}

      {/* 棄分按鈕 */}
      <Box
        onClick={handleClick}
        position="relative"
        cursor={isProcessing || currentBalance == 0 ? "not-allowed" : "pointer"}
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        shadow="sm"
      >
        <Image
          filter={currentBalance == 0 ? "grayscale(100%)" : ""}
          src={`${COMMON_WEB_PATH}/FlyDragon/getREAL.webp`}
          height={{
            base: "60px",
            xl: "70px",
          }}
          minW="90px"
        />
      </Box>

      {/* {showStartGame && <StartGameButton />} */}
    </AnimatePresence>
  );
};

export default ClearCashRegisterButton;
