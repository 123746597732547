import React, { useEffect } from "react";
import { COMMON_WEB_PATH } from "../constant";
import { Box, Image } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { toggleBGM } from "../redux/action/action";

const MusicToggleButton = () => {
  const isBGMEnabled = useSelector((state) => state.isBGMEnabled);
  const { song, enabled } = isBGMEnabled || {};
  const dispatch = useDispatch();

  // 組件掛載時從 localStorage 讀取狀態
  // useEffect(() => {
  //   const musicEnabled = localStorage.getItem("bgmEnabled");
  //   if (musicEnabled === "false" && window.backgroundAudio) {
  //     stopBackgroundMusic();
  //   }
  // }, []);

  return (
    <Box
      onClick={() => {
        dispatch(
          toggleBGM({
            ...isBGMEnabled,
            enabled: !enabled,
          })
        );
      }}
      position="relative"
      cursor="pointer"
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
      shadow="sm"
    >
      <Image
        src={
          enabled
            ? `${COMMON_WEB_PATH}/FlyDragon/music.webp`
            : `${COMMON_WEB_PATH}/FlyDragon/mute.webp`
        }
        height={{
          base: "60px",
          xl: "60px",
        }}
        minW="60px"
      />
    </Box>
  );
};

export default MusicToggleButton;
