import { BiSolidGame } from "react-icons/bi";
import { FaFish, FaHome, FaHorseHead } from "react-icons/fa";
import { GiEightBall } from "react-icons/gi";
import { IoLogoGameControllerB, IoMdFootball } from "react-icons/io";
import { IoRocket, IoTicket } from "react-icons/io5";
import { MdCasino, MdEventNote } from "react-icons/md";

export const routeMapping = (route) => {
  switch (route) {
    case "Home":
      return {
        origin: "Home",
        link: "/",
        icon: FaHome,
      };
    case "fish":
    case "FH":
      return {
        origin: "FH",
        link: "/fish",
        icon: FaFish,
      };
    case "table":
    case "TABLE":
      return {
        origin: "TABLE",
        link: "/table",
        icon: FaHorseHead,
      };
    case "slots":
    case "SLOT":
      return {
        origin: "SLOT",
        link: "/slots",
        icon: IoLogoGameControllerB,
      };
    case "live-casino":
    case "LIVE":
      return {
        origin: "LIVE",
        link: "/live-casino",
        icon: MdCasino,
      };
    case "arcade":
    case "EGAME":
      return {
        origin: "EGAME",
        link: "/arcade",
        icon: BiSolidGame,
      };
    case "sports":
    case "SPORT":
      return {
        origin: "SPORT",
        link: "/sports",
        icon: IoMdFootball,
      };
    case "lottery":
    case "LOTTERY":
      return {
        origin: "LOTTERY",
        link: "/lottery",
        icon: IoTicket,
      };
    case "block-chain":
    case "BLOCKCHAIN":
      return {
        origin: "BLOCKCHAIN",
        link: "/block-chain",
        icon: IoRocket,
      };
    case "bingo":
    case "BINGO":
      return {
        origin: "BINGO",
        link: "/bingo",
        icon: GiEightBall,
      };
    case "promotion":
    case "PROMOTION":
      return {
        origin: "PROMOTION",
        link: "/promotion",
        icon: MdEventNote,
      };
    default:
      return {
        link: `/`,
      };
  }
};
