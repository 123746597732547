import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  formatTime,
  getCustomDays,
  getSevenDays,
  getThirtyDays,
  getToday,
  getYesterday,
} from "../../utils/getDay";
import { useTranslation } from "react-i18next";
import { Button, Select } from "@chakra-ui/react";
import dayjs from "dayjs";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const DateSelectV4 = ({ setDatePicker, className, trigger }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`betRecord.${key}`);

  const [current_option, setCurrentOption] = useState(1);

  const dateList = [
    {
      text: "Last 24 hours",
      value: 1,
      startDate: `${formatTime(getYesterday())} `,
      endDate: `${formatTime(getToday())} `,
    },
    {
      text: "Last 7 days",
      value: 7,
      startDate: `${formatTime(getSevenDays())}`,
      endDate: `${formatTime(getToday())}`,
    },

    {
      text: "Last 30 days",
      value: 30,
      startDate: `${formatTime(getThirtyDays())}`,
      endDate: `${formatTime(getToday())}`,
    },
    {
      text: "Last 60 days",
      value: 60,
      startDate: `${formatTime(getCustomDays(60))}`,
      endDate: `${formatTime(getToday())}`,
    },
  ];

  const splideRef = useRef();

  useEffect(() => {
    const find_option = dateList.find((item) => {
      return item.value == current_option;
    });
    setDatePicker({
      str_date: find_option.startDate,
      end_date: find_option.endDate,
    });
  }, [trigger, current_option]);

  return (
    <>
      <Splide
        ref={splideRef}
        options={{
          pagination: false,
          arrows: false,
          drag: "free",
          perPage: 3,
          perMove: 1,
          fixedWidth: "auto",
          gap: "10px",
          lazyLoad: "nearby",
          dragMinThreshold: {
            mouse: 0,
            touch: 10,
          },
        }}
      >
        {dateList.map((opt) => {
          return (
            <SplideSlide>
              <Button
                value={opt.value}
                onClick={(e) => {
                  setCurrentOption(e.target.value);
                }}
                size="sm"
                borderRadius={"30px"}
                fontSize={"sm"}
                _hover={{
                  bg: "brand.500",
                  color: "white",
                }}
                bg={current_option == opt.value ? "brand.500" : "#D8D8D8"}
                color={current_option == opt.value ? "white" : "#707070"}
              >
                {i18n(opt.text)}
              </Button>
            </SplideSlide>
          );
        })}
      </Splide>
    </>
  );
};

export default DateSelectV4;
