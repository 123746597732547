import { Box, Flex } from "@chakra-ui/react";
import React, { Fragment } from "react";
import PlatformSelector from "../../components/v4Layout/PlatformSelector/PlatformSelector";
import GameSelector from "../../components/v4Layout/GameSelector/GameSelector";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import Seo from "./seo";
import GameTypeSelectorV4 from "../../components/v4Layout/GameTypeSelector/GameTypeSelector_v4";
import SubListSelector from "../../components/v4Layout/SubListSelector/SubListSelector";
import MobileSearchV4 from "../Search/mobile-search_v4";
import PlatformSelectorV4 from "../../components/v4Layout/PlatformSelector/PlatformSelector_v4";
import useCheckLogin from "../../hook/useCheckLogin";

const FishV4 = ({ gameMainTypeFromProps }) => {
  const { isMachineUser } = useCheckLogin();
  return (
    <Fragment>
      <Seo />
      <Box
        as="section"
        px="common-y"
        pb="home-space"
        display={{ base: "block", xl: "none" }}
      >
        <GameTypeSelectorV4 />
      </Box>
      {/* {!isMachineUser && ( //機台使用者不顯示
        <Box as="section" px="common-y" display={{ base: "block", md: "none" }}>
          <GameTypeSelectorV4 gameMainTypeFromProps={gameMainTypeFromProps} />
        </Box>
      )}
      {!isMachineUser && ( //機台使用者不顯示
        <Box as="section" pt="home-space" px="common-y">
          <SubListSelector
            forceShowAll
            gameMainTypeFromProps={gameMainTypeFromProps}
          />
        </Box>
      )} */}

      {/* <Box as="section" pt="home-space" px="common-y">
        <MarqueeListV2 />
      </Box> */}
      {/* {!isMachineUser && ( //機台使用者不顯示
        <Box
          mt={{
            md: "0px",
            // base: "10px",
          }}
          pt="home-space"
        >
          <MobileSearchV4 listHidden />
        </Box>
      )} */}
      <Box flex={1}>
        <Box
          //pb="home-space"
          pb={{
            base: "0px",
            sm: "home-space",
          }}
          px="common-y"
          mt={
            isMachineUser
              ? {
                  md: "25px",
                  base: "15px",
                }
              : "0"
          }
        >
          <PlatformSelectorV4 gameMainTypeFromProps={gameMainTypeFromProps} />
        </Box>
        <Box
          py="home-space"
          px="common-y"
          mb={{
            base: "60px",
            sm: "0px",
          }}
        >
          <GameSelector gameMainTypeFromProps={gameMainTypeFromProps} />
        </Box>
      </Box>
    </Fragment>
  );
};

export default FishV4;
