import {
  AbsoluteCenter,
  Box,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { COMMON_WEB_PATH } from "../../../constant";
import { useSelector } from "react-redux";
import CopyIcon from "../../CopyComponent/CopyIcon";
import { useTranslation } from "react-i18next";
import MyBalance from "../../../utils/myBalance";
import { TbPigMoney } from "react-icons/tb";
import { BiMoneyWithdraw } from "react-icons/bi";
import CopyIconV4 from "../../CopyComponent/CopyIcon_v4";

const ProfileCardV4 = () => {
  const { t } = useTranslation();
  const i18n_profile = (key) => t(`profile.${key}`);
  const i18n_common = (key) => t(`common.${key}`);
  const isMemberInfo = useSelector((state) => state.isMemberInfo);
  const { memId, uid } = isMemberInfo || {};

  const textColor = useColorModeValue("dark.100", "dark.100");

  return (
    <>
      <Flex
        alignItems={`center`}
        justifyContent={`center`}
        gap="20px"
        w={"auto"}
        cursor="pointer"
        borderRadius={{
          base: "common",
          md: "0",
        }}
      >
        <Box
          w={"100%"}
          h="280px"
          bgSize="contain"
          bgRepeat={"no-repeat"}
          bgPos="center"
          position="relative"
          bgImage={`url(${COMMON_WEB_PATH}/v4-layout/menu_profile_card.png)`}
          pt="85px"
          display={"flex"}
          flexDirection={"column"}
          //   justifyContent={"center"}
          alignItems={"center"}
          gap="10px"
        >
          <AbsoluteCenter
            top="8.5%"
            w="75px"
            h="75px"
            borderRadius={"full"}
            bgSize="100% 100%"
            bgRepeat={"no-repeat"}
            bgPos="center"
            bgImage={`url(${COMMON_WEB_PATH}/v4-layout/menu_profile_icon.png)`}
          ></AbsoluteCenter>

          <Flex direction={"column"} alignItems={"center"}>
            <Flex gap="15px" alignItems={"center"}>
              <Text fontWeight={"bold"} fontSize="18px" color={textColor}>
                {memId}
              </Text>
              <CopyIconV4 copyLink={memId} />
            </Flex>
            <Flex gap="15px" alignItems={"center"}>
              <Text fontSize="16px" fontWeight={"medium"} color={textColor}>
                UID：{uid}
              </Text>
              <CopyIconV4 copyLink={uid} />
            </Flex>
          </Flex>

          <Box>
            <Flex gap="5px" alignItems={"center"}>
              <Text fontSize="14px" fontWeight={"medium"} color={textColor}>
                {i18n_profile("wallet")}：
              </Text>

              <MyBalance
                balanceStyle={{
                  color: "brand.500",
                  fontWeight: "bold",
                  fontSize: "22px",
                }}
              />
            </Flex>
          </Box>

          <Box
            w={"90%"}
            display={"flex"}
            bg="white"
            borderRadius={"common"}
            py="10px"
            px="10px"
          >
            <Box w="50%">
              <Flex
                gap="10px"
                alignItems="center"
                as={Link}
                to="profile/deposit"
              >
                <Box
                  w="36px"
                  h="36px"
                  borderRadius={"10px"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                  bg="#77A4FF"
                  color="white"
                >
                  <Image
                    src={`${COMMON_WEB_PATH}/v4-layout/member-icon/deposit.svg`}
                    alt="deposit"
                  />
                  {/* <Icon as={TbPigMoney} /> */}
                </Box>
                <Text fontSize="14px" fontWeight={"bold"} color={textColor}>
                  {i18n_common("deposit")}
                </Text>
              </Flex>
            </Box>
            <Box w="50%">
              <Flex
                gap="10px"
                alignItems="center"
                as={Link}
                to="profile/withdraw"
              >
                <Box
                  w="36px"
                  h="36px"
                  borderRadius={"10px"}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                  bg="#F7A5FF"
                  color="white"
                >
                  <Image
                    src={`${COMMON_WEB_PATH}/v4-layout/member-icon/transfer.svg`}
                    alt="withdraw"
                  />
                  {/* <Icon as={BiMoneyWithdraw} /> */}
                </Box>
                <Text fontSize="14px" fontWeight={"bold"} color={textColor}>
                  {i18n_common("withdraw")}
                </Text>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default ProfileCardV4;
