import { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router";

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  const { gameMainType } = useParams();

  const { pathname } = location;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return <>{children}</>;
};

export default ScrollToTop;
