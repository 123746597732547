import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { REPLACE_AIR } from "../../replace_str_constant";
import { useDispatch, useSelector } from "react-redux";
import {
  clearGameLink,
  startGameLoading,
  storeGameConfig,
} from "../../redux/action/game/gameAction";
import LazyImage from "../Loading/lazyImage";
import LoadingLine from "../Loading/LoadingLine";
import { COMMON_WEB_PATH, COMMON_WEB_PATH_JP } from "../../constant";
import useDeviceType from "../../hook/useDeviceType";
import { useTranslation } from "react-i18next";
import { CloseMenu, globalTrigger, notice } from "../../redux/action/action";
import { languageTransfer } from "../../utils/languageTransfer";
import i18next from "i18next";
import { BsTools } from "react-icons/bs";
import { BiSolidTag } from "react-icons/bi";
import Cookies from "js-cookie";
import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Skeleton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { RiUser3Fill } from "react-icons/ri";
import useCheckLogin from "../../hook/useCheckLogin";
import { thisImageNeedDark } from "../../utils/thisImageNeedDark";
import useSound from "../../hook/useSound";

const GAMEITEMV4 = ({
  originProps,
  gameProps,
  specMask,
  isHot,
  isNew,
  itemIndex,
  forceShow,
  fullImageLayout, // 是否使用全圖模式
  liveLayout,
  customHotTag,
  customHeight,
  hideGameName,
}) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);
  const { gameCode, platform, gameName, gameSubType } = gameProps;
  const { gameNameEn } = originProps;
  const isMobile = useDeviceType() === "Mobile";

  const { isLobby, isLiveLayoutOpen, showLogoInCenter } = liveLayout || {};

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const onlinePlayer = useSelector((state) => state.isGameOnlinePlayer);
  const { game } = onlinePlayer || {};

  const textColor = useColorModeValue("dark.100", "light.100");
  const itemBg = useColorModeValue("light.100", "dark.250");
  const textBgColor = useColorModeValue("light.200", "dark.100");

  const { isLogin } = useCheckLogin();

  const playButtonSound = useSound(); // 進遊戲按鈕音效
  const hoverButtonSound = useSound({
    customSound: "slide_sound",
    volume: 0.3,
  });

  const { allGamePlatform = [] } = isHandleAllGame;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const getPrevPath = useMemo(() => {
    const path = location.pathname.split("/");
    const currentPathIndex = path.findIndex((item) => item === "game-confirm");

    if (currentPathIndex === -1) return "";

    return `${path.slice(0, currentPathIndex).join("/")}/`;
  }, [location.pathname]);

  const currentPlayer = useMemo(() => {
    return game?.[originProps.gameUid] || 0;
  }, [game]);

  const getPlatformIsMaintain = useMemo(() => {
    const findPlatform = allGamePlatform.find(
      (item) => item.platform === platform
    );
    const { maintenanceEndTime, maintenanceStartTime } = findPlatform || {};
    const isMaintain = findPlatform?.isUnderMaintenance;
    if (isMaintain) {
      return {
        state: true,
        component: (
          <Box
            borderRadius={{
              base: "10px",
              md: "15px",
              xl: "20px",
            }}
            bg="rgba(0,0,0,0.7)"
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            zIndex="2"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={"column"}
            gap="10px"
            color="white"
          >
            <Icon as={BsTools} fontSize="30px" />
            <Flex
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              fontSize="sm"
              fontWeight={"500"}
            >
              {maintenanceStartTime ? (
                <>
                  <Text as="span">{maintenanceStartTime}</Text>
                  <Text as="span">~</Text>
                  <Text as="span">{maintenanceEndTime}</Text>
                </>
              ) : (
                <>
                  <Text as="span">{i18n_common("platformMaintain")}</Text>
                </>
              )}
            </Flex>
          </Box>
        ),
      };
    }
  }, [platform]);

  const handleEnterGame = useCallback(() => {
    // 播放按鈕音效
    playButtonSound();
    if (!isLogin) {
      if (isLiveLayoutOpen && !isLobby) {
        navigate(`${getPrevPath}category/${originProps.game_platform}`);
      } else {
        dispatch(
          notice({
            title: i18n_common("Please login first"),
            type: "error",
          })
        );
        navigate("signin");
        return;
      }
    }
    if (getPlatformIsMaintain?.state) return;
    dispatch(storeGameConfig(originProps));
    dispatch(clearGameLink());
    dispatch(CloseMenu());
    dispatch(startGameLoading());

    const game_name = gameNameEn.replace(/ /g, "-");
    const game_platform = originProps.game_platform.replace(/ /g, "-");

    if (isMobile) {
      if (isLiveLayoutOpen && !isLobby) {
        navigate(`${getPrevPath}category/${originProps.game_platform}`);
      } else {
        navigate(
          `${getPrevPath}game-confirm/${game_name}/${originProps.game_platform}/${originProps.gameUid}`
        );
      }
    } else {
      if (isLiveLayoutOpen && !isLobby) {
        navigate(`${getPrevPath}category/${originProps.game_platform}`);
      } else {
        navigate(
          `/gamebox/${game_name}/${game_platform}/${originProps.gameUid}`
        );
      }
    }
  }, [originProps, gameNameEn]);

  return (
    <Flex
      role="group"
      direction={`column`}
      position={`relative`}
      cursor={
        getPlatformIsMaintain?.state && !forceShow ? `not-allowed` : `pointer`
      }
      px={{
        base: "5px",
        md: "10px",
      }}
      py={{
        base: "5px",
        md: "10px",
      }}
      onClick={handleEnterGame}
      onMouseEnter={hoverButtonSound}
      boxShadow={`base`}
      overflow="hidden"
      my="2px"
      hover={{
        background: {
          md: getPlatformIsMaintain?.state
            ? `none`
            : specMask
            ? `rgba(0,0,0,0.1)`
            : `brand.500`,
        },
      }}
      p={(isHot || isLiveLayoutOpen) && itemIndex + 1 < 11 ? "" : ""}
    >
      <Box
        position={"absolute"}
        w="100%"
        h="100%"
        top="0px"
        left="0px"
        // bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/game_BFrame2x.webp')`}
        bgImage={
          hideGameName
            ? `url('${COMMON_WEB_PATH}/FlyDragon/game_BFrame_mobile.webp')`
            : {
                base: `url('${COMMON_WEB_PATH}/FlyDragon/game_BFrame2x_mobile2.webp')`,
                sm: `url('${COMMON_WEB_PATH}/FlyDragon/game_BFrame2x.webp')`, // sm 以上使用的圖片
              }
        }
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="100%100%"
        zIndex={2}
        onClick={handleEnterGame}
        cursor={"pointer"}
        _groupHover={{
          // bgImage: `url('${COMMON_WEB_PATH}/FlyDragon/game_BFrame2x_active4.png?123123')`,
          bgImage: getPlatformIsMaintain?.state
            ? hideGameName
              ? `url('${COMMON_WEB_PATH}/FlyDragon/game_BFrame_mobile.webp')`
              : {
                  base: `url('${COMMON_WEB_PATH}/FlyDragon/game_BFrame2x_mobile2.webp')`,
                  sm: `url('${COMMON_WEB_PATH}/FlyDragon/game_BFrame2x.webp')`, // sm 以上使用的圖片
                }
            : `url(https://911win.s3.ap-southeast-1.amazonaws.com/FlyDragon/game_BFrame2x_active4.png)`,
          cursor: "pointer",
          borderRadius: "common",
          // _after: {
          //   content: '""',
          //   position: "absolute",
          //   bottom: "8%",
          //   left: "50%",
          //   transform: "translate(-50%,-0%)",
          //   bgImage: `url('${COMMON_WEB_PATH}/FlyDragon/PLAY.webp')`,
          //   //w: "100px",
          //   w: {
          //     base: "60px", // 移動設備
          //     sm: "80px", // 螢幕 (480px+)
          //     md: "100px", // 中等螢幕 (768px+)
          //     lg: "120px", // 大螢幕 (992px+)
          //     xl: "150px", // 特大螢幕 (1280px+)
          //     "2xl": "180px", //超大螢幕 (1536px+)
          //   },
          //   //h: "30px",
          //   h: {
          //     base: "20px", // 移動設備
          //     sm: "20px", // 螢幕 (480px+)
          //     md: "30px", // 中等螢幕 (768px+)
          //     lg: "40px", // 大螢幕 (992px+)
          //     xl: "50px", // 特大螢幕 (1280px+)
          //     "2xl": "60px", //超大螢幕 (1536px+)
          //   },
          //   bgSize: "cover",
          //   bgPosition: "center",
          //   bgRepeat: "no-repeat",
          // },
        }}
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent={"center"}
        pb="2vw"
      >
        {!hideGameName && (
          <Text
            display={{
              base: "block",
              sm: "none",
            }}
            mb="0px"
            px="5px"
            textAlign={`center`}
            width={`100%`}
            maxW="100%"
            fontWeight={`700`}
            color="white"
            position="absolute"
            bottom="5%"
            left="0px"
            zIndex="4"
            fontSize={{
              base: "16px",
              md: "18px",
            }}
            isTruncated // Chakra UI的內建屬性 會自動處理省略號
            _groupHover={{
              display: "none",
            }}
          >
            {gameNameEn}
          </Text>
        )}
      </Box>

      <Box borderRadius={`sm`} overflow="hidden">
        {showLogoInCenter ? (
          <>
            <Box
              position="absolute"
              bg="rgba(0,0,0,0.7)"
              w="100%"
              h="100%"
              top="0px"
              left="0px"
              zIndex={1}
            ></Box>
            <AbsoluteCenter top="40%" zIndex={2}>
              <Image
                src={`${COMMON_WEB_PATH}/platformIcon/${thisImageNeedDark(
                  platform,
                  true
                )}.png`}
                w="140px"
                transform={"scale(1.3)"}
              />
            </AbsoluteCenter>
          </>
        ) : null}
        {currentPlayer > 0 && (
          <Box
            position="absolute"
            right="5px"
            top="5px"
            borderRadius={`full`}
            py="3px"
            px="10px"
            zIndex={1}
            bg={itemBg}
            color={textColor}
            display="flex"
            alignItems={`center`}
            gap="3px"
            fontSize="12px"
          >
            <Icon as={RiUser3Fill} />
            {currentPlayer}
          </Box>
        )}
        {getPlatformIsMaintain?.component}
        {isHot && itemIndex + 1 < 11 ? (
          <Box
            position={`absolute`}
            top={{
              base: "3px",
              sm: "13px",
            }}
            left={{
              base: "3px",
              sm: "13px",
            }}
            zIndex={`1`}
          >
            <Flex
              gap="-5px"
              width={`55px`}
              // height={`21px`}
              py="5px"
              //borderRadius={`full`}
              borderBottomRightRadius={`full`}
              // direction={`column`}
              alignItems={`center`}
              justifyContent={`center`}
              fontWeight={`bold`}
              color="white"
              bgGradient={
                customHotTag?.color || `linear(to-r, #eb0200, #ff9726)`
              }
            >
              {" "}
              {customHotTag?.text || (
                <>
                  <Text as="span" fontSize={`sm`} letterSpacing={`tighter`}>
                    Top
                  </Text>
                  <Text fontSize={`sm`} as="span">
                    {itemIndex + 1}
                  </Text>
                </>
              )}
            </Flex>
          </Box>
        ) : null}

        {isNew && itemIndex < 5 ? (
          <Box
            position={`absolute`}
            top={{
              base: "13px",
              sm: "13px",
            }}
            left={{
              base: "13px",
              sm: "13px",
            }}
            zIndex={`1`}
          >
            <Flex
              gap="-5px"
              width={`55px`}
              py="5px"
              borderBottomRightRadius={`full`}
              alignItems={`center`}
              justifyContent={`center`}
              fontWeight={`bold`}
              color="white"
              bgGradient="linear(to-r, #7c3af6, #f100ff)"
            >
              <Text as="span" fontSize={`sm`} letterSpacing={`tighter`}>
                New
              </Text>
            </Flex>
          </Box>
        ) : null}

        <Box
          pt={
            customHeight
              ? {
                  base: fullImageLayout ? "110%" : "162%",
                  // 105 120
                  md: fullImageLayout ? "150%" : "162%",
                }
              : {
                  base: fullImageLayout ? "110%" : "130%",
                  // 105 120
                  md: fullImageLayout ? "150%" : "130%",
                }
          }
          position="relative"
          borderRadius={`8px`}
          overflow="hidden"
        >
          <Box
            position="absolute"
            top="0px"
            left="0px"
            height="100%"
            width="100%"
            // overflow="hidden"
            isolation={`isolate`}
            borderRadius={`common`}
          >
            <Box
              position="absolute"
              top="0px"
              left="0px"
              height={
                hideGameName
                  ? "100%"
                  : {
                      base: "80%",
                      sm: "100%",
                    }
              }
              width="100%"
              // overflow="hidden"
            >
              <LazyImage
                //預設嘗試載入gif  如果gif不存在或載入失敗，自動切換到 webp
                src={`${COMMON_WEB_PATH_JP}/game-icon-ds88/en/${platform}/${REPLACE_AIR(
                  gameCode
                )}.gif`}
                alt={gameNameEn}
                closeInverted
                className={`w-full h-full ${
                  fullImageLayout ? "rounded-[5px]" : "rounded-t-[5px]"
                }`}
                onError={(e) => {
                  e.target.src = `${COMMON_WEB_PATH_JP}/game-icon-ds88/en/${platform}/${REPLACE_AIR(
                    gameCode
                  )}.webp`;
                }}
              />
            </Box>
            <Box
              position={`absolute`}
              top="0px"
              left="0px"
              height="100%"
              width="100%"
              borderRadius={`common`}
              display={{
                base: "none",
                md: "flex",
              }}
              alignItems={`center`}
              justifyContent={`center`}
              opacity={0}
              className="transition duration-300"
              _groupHover={{
                md: {
                  opacity: 1,
                },
              }}
              bg={specMask ? "rgba(0,0,0,0.6)" : "game-item.mask"}
            ></Box>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default React.memo(GAMEITEMV4);
