import { Icon, Spinner } from "@chakra-ui/react";
import React from "react";
import { ImSpinner6 } from "react-icons/im";
import { useSelector } from "react-redux";

const LoadingIcon = ({ children, commonLoading, size }) => {
  const loadingStatus = useSelector((state) => state.isLoaderStatus);

  return loadingStatus || commonLoading ? (
    // <Spinner variant={"brandPrimary"} size={size ? size : "sm"}>
    //   {/* {children} */}
    // </Spinner>
    <Icon
      as={ImSpinner6}
      fontSize={size || "md"}
      className="animate-spin"
      color="yellow.500"
    />
  ) : (
    children
  );
};

export default LoadingIcon;
