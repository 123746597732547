import { BiSolidCrown, BiSolidGift } from "react-icons/bi";
import { GiEightBall, GiPokerHand } from "react-icons/gi";
import { IoLogoGameControllerB, IoMdFootball } from "react-icons/io";
import { IoTicket, IoWallet } from "react-icons/io5";
import { MdEventNote, MdHistory } from "react-icons/md";
import { TbMessage } from "react-icons/tb";
import { COMMON_WEB_PATH } from "../../../constant";

const isCredit = process.env.REACT_APP_CREDIT == 1;

export const headerMenu = [
  { text: "SLOTS", link: "casino", icon: IoLogoGameControllerB },
  { text: "LIVE CASINO", link: "live-casino", icon: GiPokerHand },
  { text: "SPORTS", link: "sports", icon: IoMdFootball },
  { text: "BINGO", link: "bingo", icon: GiEightBall },
  // { text: "LOTTERY", link: "lottery", icon: IoTicket },
  { text: "PROMOTION", link: "promotion", icon: MdEventNote, hidden: isCredit },
];

export const v4Menu = [
  // {
  //   text: "profile",
  //   link: "profile",
  //   icon: IoWallet,
  //   customLink: (deviceType) => {
  //     // 如果是 md 以上的寬度，導向 profile/deposit
  //     return deviceType === "PC" ? "profile/deposit" : "profile";
  //   },
  // },
  {
    text: "accountsetting",
    link: "profile/settings",
    icon: BiSolidGift,
    image:
      "https://d1h3qe8voog6w4.cloudfront.net/v4-layout/member-icon/account.svg",
    needLogin: true,
  },
  {
    text: "reward",
    link: "profile/rewards",
    icon: BiSolidGift,
    image:
      "https://d1h3qe8voog6w4.cloudfront.net/v4-layout/member-icon/award2.svg",
    needLogin: true,
  },
  // {
  //   text: "MyFavorite",
  //   link: "/category/all-platform/MyFavorite",
  //   icon: BiSolidGift,
  //   image:
  //     "https://d1h3qe8voog6w4.cloudfront.net/v4-layout/member-icon/favorite3.svg",
  //   needLogin: true,
  // },
  {
    text: "vip",
    link: "profile/vip",
    icon: BiSolidCrown,
    image:
      "https://d1h3qe8voog6w4.cloudfront.net/v4-layout/member-icon/vip.svg",
    needLogin: true,
  },
  {
    text: "bet Record",
    link: "profile/betRecord",
    icon: MdHistory,
    image:
      "https://d1h3qe8voog6w4.cloudfront.net/v4-layout/member-icon/records.svg",
    needLogin: true,
  },
  {
    text: "promotion",
    link: "/promotion",
    icon: MdHistory,
    image:
      "https://d1h3qe8voog6w4.cloudfront.net/v4-layout/member-icon/discount.svg",
    needLogin: false,
  },
  {
    text: "inbox",
    link: "profile/inbox",
    icon: TbMessage,
    image:
      "https://d1h3qe8voog6w4.cloudfront.net/v4-layout/member-icon/mail.svg",
    needLogin: true,
  },
];

export const pcMenu = [
  {
    text: "Lobby",
    link: "/",
    image: {
      light: `${COMMON_WEB_PATH}/v4-layout/menu-icon/lobby0.svg`,
      dark: `${COMMON_WEB_PATH}/v4-layout/menu-icon/lobby1.svg`,
    },
    activeBg: "brand.500",
    activeColor: "white",
  },
  {
    text: "Slot",
    link: "/slots",
    image: {
      light: `${COMMON_WEB_PATH}/v4-layout/menu-icon/slot0.svg`,
      dark: `${COMMON_WEB_PATH}/v4-layout/menu-icon/slot1.svg`,
    },
    activeBg: "#A346E9",
    activeColor: "white",
  },

  {
    text: "LIVE",
    link: "/live-casino",
    image: {
      light: `${COMMON_WEB_PATH}/v4-layout/menu-icon/casino0.svg`,
      dark: `${COMMON_WEB_PATH}/v4-layout/menu-icon/casino1.svg`,
    },
    activeBg: "#FF8C09",
    activeColor: "white",
  },
  {
    text: "FH",
    link: "/fish",
    image: {
      light: `${COMMON_WEB_PATH}/v4-layout/menu-icon/fish0.svg`,
      dark: `${COMMON_WEB_PATH}/v4-layout/menu-icon/fish1.svg`,
    },
    activeBg: "#00B9FF",
    activeColor: "white",
  },
  {
    text: "BINGO",
    link: "/bingo",
    image: {
      light: `${COMMON_WEB_PATH}/v4-layout/menu-icon/bingo0.svg`,
      dark: `${COMMON_WEB_PATH}/v4-layout/menu-icon/bingo1.svg`,
    },
    activeBg: "#2BC17B",
    activeColor: "white",
  },
  // {
  //   text: "Sport",
  //   link: "/sports",
  //   image: {
  //     light: `${COMMON_WEB_PATH}/v4-layout/menu-icon/sport0.svg`,
  //     dark: `${COMMON_WEB_PATH}/v4-layout/menu-icon/sport1.svg`,
  //   },
  //   activeBg: "#E63626",
  //   activeColor: "white",
  // },
  // {
  //   text: "Egame",
  //   link: "/arcade",
  //   image: {
  //     light: `${COMMON_WEB_PATH}/v4-layout/menu-icon/egame0.svg`,
  //     dark: `${COMMON_WEB_PATH}/v4-layout/menu-icon/egame1.svg`,
  //   },
  //   activeBg: "#F548B6",
  //   activeColor: "white",
  // },

  // {
  //   text: "Table",
  //   link: "/table",
  //   image: {
  //     light: `${COMMON_WEB_PATH}/v4-layout/menu-icon/table0.svg`,
  //     dark: `${COMMON_WEB_PATH}/v4-layout/menu-icon/table1.svg`,
  //   },
  //   activeBg: "#8ECF2A",
  //   activeColor: "white",
  // },
  {
    text: "BLOCKCHAIN",
    link: "/block-chain",
    image: {
      light: `${COMMON_WEB_PATH}/v4-layout/menu-icon/block0.svg`,
      dark: `${COMMON_WEB_PATH}/v4-layout/menu-icon/block1.svg`,
    },
    activeBg: "#FF6723",
    activeColor: "white",
  },
  // {
  //   text: "Providers",
  //   link: "casino-group",
  //   image: {
  //     light: `${COMMON_WEB_PATH}/v4-layout/menu-icon/providers0.svg`,
  //     dark: `${COMMON_WEB_PATH}/v4-layout/menu-icon/providers1.svg`,
  //   },
  //   activeBg: "#0DC2C2",
  //   activeColor: "white",
  // },
];
