import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import DetailWrapper from "../../components/detailWrapper";
import { BiSolidLockAlt, BiSolidUser, BiSolidUserCircle } from "react-icons/bi";
import { HiChevronRight } from "react-icons/hi";
import { MdLockPerson, MdOutlineLanguage } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { openLanguagePop } from "../../redux/action/member/memberAction";
import { useNavigate } from "react-router";
import { RiProfileFill } from "react-icons/ri";
import { BsShieldFillCheck } from "react-icons/bs";
import { IoIosMoon, IoMdSunny } from "react-icons/io";
import ThemeTab from "./components/themeTab";
import { Box, Flex, useColorModeValue, Text } from "@chakra-ui/react";

const isCredit = process.env.REACT_APP_CREDIT == 1;

const SettingsV4 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`settings.${key}`);
  const i18n_common = (key) => t(`common.${key}`);

  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.isTheme === "dark");
  const memberData = useSelector((state) => state.isMemberInfo);

  const withdraw_password_set = useMemo(() => {
    return memberData?.withdraw_password_set == 1;
  }, [memberData]);

  const boxBg = useColorModeValue("light.100", "dark.250");
  const textColor = useColorModeValue("light.450", "light.100");
  const textHoverColor = useColorModeValue("brand.500", "light.100");
  const borderColor = useColorModeValue("light.300", "dark.300");
  const dividerColor = useColorModeValue("light.350", "dark.350");

  const [settingTab, setSettingTab] = useState("");

  const navigate = useNavigate();

  const profile_settings = [
    {
      label: i18n("personalInformation"),
      icon: <BiSolidUserCircle />,
      path: "personal-data",
    },
    {
      label: i18n("bindIdCard"),
      icon: <RiProfileFill />,
      path: "bind-id-card",
      hidden: isCredit,
    },
    {
      label: i18n("resetPassword"),
      icon: <BiSolidLockAlt />,
      path: "reset-password",
    },
    {
      label: `${withdraw_password_set ? i18n("reset") : i18n("set")} ${i18n(
        "resetWithdrawPassword"
      )}`,
      icon: <MdLockPerson />,
      path: "reset-withdraw-password",
      hidden: isCredit,
    },
    {
      label: i18n("userSecureVerity"),
      icon: <BsShieldFillCheck />,
      divider: true,
      path: "user-secure-verity",
      hidden: isCredit,
    },

    // {
    //   label: i18n("Language"),
    //   icon: <MdOutlineLanguage />,
    //   function: () => {
    //     dispatch(openLanguagePop());
    //   },
    // },
    // {
    //   label: i18n("ColorTheme"),
    //   icon: isDarkMode ? <IoIosMoon /> : <IoMdSunny />,
    //   divider: true,
    //   tab: "color-theme",
    //   children: <ThemeTab />,
    //   function: () => {
    //     if (settingTab === "color-theme") {
    //       setSettingTab("");
    //       return;
    //     }
    //     setSettingTab("color-theme");
    //   },
    // },
  ];

  return (
    <DetailWrapper>
      <Box as="section">
        <CloseDetailPage title={i18n("title")} />

        <Flex
          direction={`column`}
          gap="0px"
          border={`1px solid`}
          borderColor={borderColor}
          borderRadius="common"
        >
          {profile_settings
            ?.filter((route) => {
              return !route.hidden;
            })
            ?.map((item, index) => {
              return (
                <Box
                  as="section"
                  bg={boxBg}
                  cursor={`pointer`}
                  borderTopRadius={index == 0 ? "common" : ""}
                  borderBottomRadius={
                    index ==
                    profile_settings?.filter((item) => !item.hidden).length - 1
                      ? "common"
                      : ""
                  }
                  py="16px"
                  px="20px"
                  color="special.1"
                  fontSize="lg"
                  fontWeight={`bold`}
                  display={`flex`}
                  flexDirection={`column`}
                  gap="10px"
                  borderBottom={!item.divider ? `1px solid` : "none"}
                  borderColor={dividerColor}
                  //mb={item.divider && "8px"}
                >
                  <Box
                    as="section"
                    display={`flex`}
                    alignItems={`center`}
                    justifyContent={`space-between`}
                    onClick={() => {
                      item.function && item.function();
                      if (item.path) {
                        navigate(item.path);
                      }
                    }}
                    _hover={{
                      color: textHoverColor,
                    }}
                  >
                    <Flex alignItems={`center`} gap="5px">
                      {/* <Text as="span" fontSize={`xl`}>
                        {item.icon}
                      </Text> */}
                      <Text
                        as="span"
                        fontWeight={`bold`}
                        fontSize="16px"
                        color={textColor}
                      >
                        {item.label}
                      </Text>
                    </Flex>
                    <HiChevronRight
                      size="22px"
                      color={textColor}
                      className={`${item.active && "rotate-90"}`}
                    />
                  </Box>
                  {settingTab === item.tab && item.children}
                </Box>
              );
            })}
        </Flex>
      </Box>
    </DetailWrapper>
  );
};

export default SettingsV4;
