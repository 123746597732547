import {
  AbsoluteCenter,
  Box,
  Flex,
  Image,
  Text,
  chakra,
  shouldForwardProp,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { API_RELOAD, COMMON_WEB_PATH } from "../../../constant";
import {
  animate,
  isValidMotionProp,
  motion,
  useMotionValue,
  useTransform,
} from "framer-motion";
import { formatNumber } from "../../../utils/formatNumber";
import { getJackpot } from "../../../api/getApi";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const JackpotV4 = () => {
  const [number, setNumber] = useState(0);

  const count = useMotionValue(0);
  const rounded = useTransform(count, (value) =>
    formatNumber(value, { point: false })
  );

  useEffect(() => {
    const animation = animate(count, Number(number), {
      duration: 2,

      ease: "easeInOut",
    });

    return animation.stop;
  }, [number]);

  useEffect(() => {
    const fetchDataPeriodically = () => {
      getJackpot().then((data) => {
        const result = data?.data;

        const getFirstJackpot = result[Object?.keys(result)?.[1]];
        const { jackpot } = getFirstJackpot || {};
        setNumber(jackpot);
      });
    };

    fetchDataPeriodically();

    const apiIterval = setInterval(() => {
      fetchDataPeriodically();
    }, API_RELOAD);

    return () => clearInterval(apiIterval);
  }, []);

  const gradientStyle = {
    background:
      "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(247,249,79,1) 0%, rgba(254,238,54,1) 50%, rgba(245,174,12,1) 75%, rgba(236,208,59,1) 100%)",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    color: "transparent",
    display: "inline-block",
  };

  return (
    <Box w="100%">
      <Box
        w="100%"
        pt="8px"
        pb="8px"
        px={{
          base: "0px",
          md: "10px",
        }}
        position={`relative`}
        display="flex"
        flexDirection={`column`}
        gap="5px"
        alignItems={`center`}
        justifyContent={`center`}
      >
        {/* <AbsoluteCenter zIndex={1} w="100%" h="100%">
          <Box
            w="100%"
            h="100%"
            bgSize="100% 100%"
            bgImage={`url(${COMMON_WEB_PATH}/b69-home/game-type-icon/jackpot-bg.png)`}
          />
        </AbsoluteCenter> */}
        <Flex w="100%" alignItems="center" direction={`column`} gap="0px">
          <Box
            px="25px"
            pb={{
              base: "10px",
              md: "20px",
              lg: "25px",
              xl: "3%",
            }}
            w={{
              base: "100%",
              md: "60%",
            }}
            display={`flex`}
            alignItems={`center`}
            justifyContent={`center`}
          >
            <Text
              bgGradient="linear(to-b,  #fbff93, #fef92a, #f2c70a, #f2c70a)"
              bgClip="text"
              fontSize={{
                base: "20px",
                sm: "32px",
                md: "36px",
              }}
              fontWeight="900"
            >
              <ChakraBox>{rounded}</ChakraBox>
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default JackpotV4;
