import {
  AnimatePresence,
  isValidMotionProp,
  motion,
  useWillChange,
} from "framer-motion";

import { appName } from "../../config";
import { popUpVariant } from "../../animateConstant";
import { useEffect, useRef, useState } from "react";
import { getFixedStyle } from "../../hook/useDeviceType";

import { useTranslation } from "react-i18next";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Text,
  chakra,
  shouldForwardProp,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import useAppDownloadLink from "./hooks/useAppDownloadLink";
import QRCode, { QRCodeCanvas } from "qrcode.react";
import { Link } from "react-router-dom";
import { FaMobileAlt } from "react-icons/fa";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const DownloadApp = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`app.${key}`, { ...props });
  const i18n_common = (key) => t(`common.${key}`);

  const qrRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");

  const willChange = useWillChange();

  const { colorMode, toggleColorMode } = useColorMode();
  const boxBg = useColorModeValue("light.200", "dark.200");
  const boxLightBg = useColorModeValue("light.100", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");

  const [isClosing, setIsClosing] = useState(false);

  const navigate = useNavigate();

  const app_download_link = useAppDownloadLink();

  useEffect(() => {
    if (qrRef.current) {
      const canvas = qrRef.current.children[0];
      const image = canvas.toDataURL("image/png");
      setImgSrc(image);
    }
  }, [app_download_link]);

  return (
    <AnimatePresence>
      {!isClosing && (
        <Box
          zIndex={9999}
          backdropBlur="md"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          px={"10px"}
          className={`${getFixedStyle()}`}
        >
          <ChakraBox
            variants={popUpVariant}
            initial="initial"
            animate="animate"
            exit="exit"
            style={{ willChange }}
            transition={{
              duration: 0.2,
            }}
            maxH="90vh"
            overflowY="scroll"
            pb={"25px"}
            pt={"10px"}
            px={"15px"}
            borderRadius={"lg"}
            maxW={"450px"}
            w={"full"}
            bg={boxBg}
            color={textColor}
          >
            <Flex
              alignItems={"center"}
              justifyContent={"flex-end"}
              color={textColor}
              fontSize="3xl"
            >
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
                fontSize="3xl"
                size="sm"
                icon={<IoCloseOutline />}
              />
            </Flex>

            <Text
              mt="20px"
              fontSize="lg"
              color="brand.500"
              textAlign={"center"}
              fontWeight={600}
            >
              {i18n("DownloadNow", { appName })}
            </Text>
            <Flex alignItems={"center"} justifyContent={"center"} py="20px">
              <Box bg={boxLightBg} borderRadius={"common"} p="10px">
                <Box display="none" ref={qrRef}>
                  <QRCodeCanvas value={app_download_link} renderAs="canvas" />
                </Box>
                {imgSrc && (
                  <Image
                    w="128px"
                    h="128px"
                    className={`img-can-select`}
                    src={imgSrc}
                    alt="QR Code"
                  />
                )}
              </Box>
            </Flex>
            <Button
              as={Link}
              to={app_download_link}
              target="_blank"
              variant={"brandPrimary"}
              fontSize="sm"
              size="md"
              w="100%"
              rightIcon={<Icon as={FaMobileAlt} />}
            >
              {i18n_common("DOWNLOAD NOW")}
            </Button>
            {/* <Menu pointing inverted={isDarkMode} secondary>
              <Menu.Item
                name="IPHONE"
                active={activeItem === "IPHONE"}
                onClick={() => {
                  setActiveItem("IPHONE");
                }}
              />
              <Menu.Item
                name="ANDROID"
                active={activeItem === "ANDROID"}
                onClick={() => {
                  setActiveItem("ANDROID");
                }}
              />
            </Menu> */}
          </ChakraBox>
        </Box>
      )}
    </AnimatePresence>
  );
};

export default DownloadApp;
