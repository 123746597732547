import { useEffect, useMemo, useRef, useState } from "react";
import SwiperGameList from "./SwiperGameList/SwiperGameList";
import { useLocation, useNavigate, useParams } from "react-router";
import React from "react";
import useDeviceType from "../hook/useDeviceType";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useCasinoType from "../hook/useCasinoType";
import ObserveComponents from "../utils/observeComponents";
import LoadingLine from "./Loading/LoadingLine";
import LoadingImage from "./Loading/LoadingImage";
import { useSelector } from "react-redux";
import ObserveInView from "../utils/observeInView";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import {
  Box,
  Flex,
  Heading,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import SwiperGameListV2 from "./SwiperGameList/SwiperGameList_v2";
import UseGetGameBoxBackPathV2 from "../hook/useGetGameBoxBackPath_v2";
import TitleIcon from "./v2Layout/PlatformSelector/components/titleIcon";
import { menuIcon } from "./Menu/helper/menuIcon";
import SwiperGameListV3 from "./SwiperGameList/SwiperGameList_v3";

const OBSERVE_TIME = 1000;

const GameCatalogV3 = ({
  mainTitle,
  gameType,
  itemClass, //遊戲item class
  swiperProps,
  swiperClassName,
  closeComponent, //關閉組件
  wrapperClassName, //外層class
  hiddenHeader, //隱藏header
  directToPlatform, //直接導向平台
  dontTranslateMainTitle, //不翻譯mainTitle
  listType = "game", //game,promotion,sponsor
  list = [], // 列表
  observeFalse,
  hiddenButtonNavigator, //隱藏All ... 按鈕
  forceShow, //強制顯示維護的遊戲
  customGameList, //自定義遊戲列表
  fullImageLayout, // 全圖版型
  isHot,
  customHotTag,
  customFixedWidth,
}) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const splideRef = useRef();

  const titleColor = useColorModeValue("dark.100", "light.100");
  const clickBg = useColorModeValue("light.300", "dark.100");
  const clickColor = useColorModeValue("dark.100", "light.200");

  const casinoType = useCasinoType();

  const { gameMainType } = useParams();
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { game_subtype } = isHandleAllGame;

  const location = useLocation();

  const getMainType = useMemo(() => {
    return location.pathname.split("/")?.[1];
  }, [location.pathname]);

  const getPrevPath_v2 = UseGetGameBoxBackPathV2();

  const isGameBox = useMemo(() => {
    return location.pathname.includes("gamebox");
  }, [location.pathname]);

  const findCasinoType = useMemo(() => {
    if (isGameBox) return "casino";
    const subTypeConfig = game_subtype?.find(
      (item) => item.game_subtype === mainTitle
    );
    const belongType =
      mainTitle === "MyFavorite" ||
      mainTitle === "News" ||
      mainTitle === "Top" ||
      mainTitle === "ClickedGames"
        ? casinoType
        : subTypeConfig?.belong_type === "Slots"
        ? "casino"
        : subTypeConfig?.belong_type?.toLowerCase() || "casino";

    return belongType;
  }, [mainTitle]);

  const isMobile = useDeviceType() === "Mobile";

  const handleSlideRight = () => {
    if (splideRef.current) {
      const splide = splideRef.current.splide;
      const currentIndex = splide.index;
      const newIndex = currentIndex + 3;

      splide.go(newIndex);
    }
  };
  const handleSlideLeft = () => {
    if (splideRef.current) {
      const splide = splideRef.current.splide;
      const currentIndex = splide.index;
      const newIndex = currentIndex - 3;

      splide.go(newIndex);
    }
  };

  const listSize = useMemo(() => {
    return isMobile ? list?.slice(0, 15) : list?.slice(0, 30);
  }, [isMobile, list]);

  return (
    <ObserveInView
      props={{
        itemClass: itemClass,
        swiperProps: swiperProps,
        swiperClassName: swiperClassName,
      }}
      observeFalse={observeFalse}
    >
      <Box
        position={"relative"}
        borderRadius={"30px"}
        px={"10px"}
        py={"5px"}
        my={"5px"}
        className={`${closeComponent} ${wrapperClassName}`}
      >
        <Box position={`relative`} zIndex={`10`}>
          {hiddenHeader || (
            <>
              <Flex justifyContent={`space-between`} mb="10px">
                <Flex alignItems={`center`}>
                  <LoadingLine
                    className={`min-w-[130px] h-[20px] rounded-full`}
                  >
                    <Flex gap="5px" alignItems={`center`}>
                      <TitleIcon
                        icon={menuIcon(mainTitle || "AllGames", "icon")}
                      />
                      <Heading
                        as="h3"
                        color={"white"}
                        fontSize={{
                          base: "16px",
                          md: "30px",
                        }}
                        fontWeight={700}
                        mb="0px"
                      >
                        {mainTitle && !dontTranslateMainTitle
                          ? i18n(mainTitle)
                          : mainTitle}
                      </Heading>
                    </Flex>
                  </LoadingLine>
                </Flex>
                <Box
                  h={{
                    base: "unset",
                    md: "40px",
                  }}
                  display="flex"
                  alignItems={`flex-end`}
                >
                  <Flex alignItems={`center`} gap="5px">
                    {listType === "game" && (
                      <>
                        {mainTitle && !hiddenButtonNavigator ? (
                          <Link
                            to={
                              directToPlatform
                                ? `/${
                                    gameMainType && gameMainType !== "undefined"
                                      ? gameMainType
                                      : getMainType
                                  }/category/${directToPlatform}`
                                : `/${
                                    gameMainType && gameMainType !== "undefined"
                                      ? gameMainType
                                      : getMainType
                                  }/category/all-platform/${mainTitle}`
                            }
                          >
                            <Flex
                              // bg={"brand.500"}
                              border="1px solid"
                              borderColor="special.gold"
                              color={"special.gold"}
                              cursor={`pointer`}
                              py="8px"
                              px="20px"
                              height="30px"
                              fontWeight={`medium`}
                              fontSize={`sm`}
                              alignItems={`center`}
                              borderRadius={`lg`}
                            >
                              <LoadingLine className={``}>
                                {i18n("all")}{" "}
                              </LoadingLine>
                            </Flex>
                          </Link>
                        ) : null}
                        <Box
                          display={{ md: "flex", base: "none" }}
                          alignItems={`center`}
                          justifyContent={`center`}
                          borderRadius={`lg`}
                          width={`30px`}
                          // bg={clickBg}
                          border="1px solid"
                          borderColor="special.gold"
                          color={"special.gold"}
                          cursor={`pointer`}
                          py="8px"
                          px="20px"
                          height="30px"
                          onClick={handleSlideLeft}
                        >
                          <Icon as={MdOutlineKeyboardArrowLeft} fontSize="lg" />
                        </Box>
                        <Box
                          onClick={handleSlideRight}
                          display={{ md: "flex", base: "none" }}
                          alignItems={`center`}
                          justifyContent={`center`}
                          borderRadius={`lg`}
                          width={`30px`}
                          // bg={clickBg}
                          border="1px solid"
                          borderColor="special.gold"
                          color={"special.gold"}
                          cursor={`pointer`}
                          py="8px"
                          px="20px"
                          height="30px"
                        >
                          <Icon
                            as={MdOutlineKeyboardArrowRight}
                            fontSize="lg"
                          />
                        </Box>
                      </>
                    )}
                  </Flex>
                </Box>
              </Flex>
            </>
          )}
          <SwiperGameListV3
            isHot={isHot}
            customHotTag={customHotTag}
            forceShow={forceShow}
            ref={splideRef}
            list={listSize}
            listType={listType}
            itemClass={itemClass}
            swiperProps={swiperProps}
            swiperClassName={swiperClassName}
            customGameList={customGameList}
            fullImageLayout={fullImageLayout}
            customFixedWidth={customFixedWidth}
          />
        </Box>
      </Box>
    </ObserveInView>
  );
};

export default React.memo(GameCatalogV3);
