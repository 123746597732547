import React, { useCallback } from "react";
import { COMMON_WEB_PATH } from "../constant";

const useSound = ({ customSound, volume, loop } = {}) => {
  const playSound = useCallback(() => {
    const audio = new Audio(
      `${COMMON_WEB_PATH}/FlyDragon/${customSound || "play"}.mp3`
    );
    audio.loop = loop ? true : false;
    audio.volume = volume || 0.3;

    audio.play().catch((error) => {
      console.error(error);
    });

    audio.addEventListener("ended", () => {
      audio.pause();
      audio.src = "";
    });

    return () => {
      audio.pause();
      audio.src = "";
    };
  }, [customSound, volume]);

  return playSound;
};

export default useSound;
