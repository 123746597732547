import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { formatNumber } from "./formatNumber";
import i18next from "i18next";
import { BALANCE_RELOAD } from "../constant";
import { Text, useColorModeValue } from "@chakra-ui/react";
import AnimateCount from "../pages/WebSocket/components/AnimateCount";

const MyBalance = ({ balanceStyle }) => {
  const walletCurrency = useSelector((state) => state.isWalletCurrency); //從redux store中取isWalletCurrency的值 存到walletCurrency變數中
  const isCurrencyExchange = useSelector((state) => state.isCurrencyExchange);
  const dispatch = useDispatch();

  const textColor = useColorModeValue("dark.100", "light.100");

  const location = useLocation();

  const { pathname } = location;

  const { color, fontSize, fontWeight } = balanceStyle || {};

  useEffect(() => {
    const fetchDataPeriodically = () => {
      dispatch({ type: "GET_BALANCE" });
    };

    const apiIterval = setInterval(() => {
      fetchDataPeriodically();
    }, BALANCE_RELOAD);

    return () => clearInterval(apiIterval);
  }, []);

  const balance = useMemo(() => {
    return (
      isCurrencyExchange?.[walletCurrency]?.exchange_amount || "Loading..."
    );
  }, [walletCurrency, isCurrencyExchange]);

  const gradientStyle = {
    background:
      "linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(247,249,79,1) 0%, rgba(254,238,54,1) 50%, rgba(245,174,12,1) 75%, rgba(236,208,59,1) 100%)",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    color: "transparent",
    display: "inline-block",
  };

  return (
    <Text
      as="span"
      sx={color ? {} : gradientStyle}
      color={color || "transparent"}
      fontSize={fontSize}
      fontWeight="{fontWeight}"
    >
      {pathname.includes("gamebox") ? (
        `(${i18next.t("common.playing")})`
      ) : (
        <AnimateCount
          variant={{
            mass: 0.8,
            stiffness: 60,
            damping: 17.5,
          }}
          value={balance}
        />
      )}
    </Text>
  );
};

export default MyBalance;
