import React, { Fragment, useMemo } from "react";
import Seo from "../Slot/seo";
import MarqueeListV2 from "../../components/marquee/marqueeList_v2";
import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import SubListSelector from "../../components/v4Layout/SubListSelector/SubListSelector";
import GameCatalogV3 from "../../components/GameCatalog_v3";
import { useSelector } from "react-redux";
import useSubTypeListV2 from "../../hook/useSubTypeList_v2";
import CarouselV4 from "../../components/Carousel_v4";
import MobileSearchV4 from "../Search/mobile-search_v4";
import TopGameBoard from "../../components/v4Layout/TopGameBoard/TopGameBoard";
import GameSelector from "../../components/v4Layout/GameSelector/GameSelector";
import EventCarouselV4 from "./components/event_carousel_v4";
import GameTypeSelectorV4 from "../../components/v4Layout/GameTypeSelector/GameTypeSelector_v4";
import MarqueeListV4 from "../../components/marquee/marqueeList_v4";
import TopGameBoardV4 from "../../components/v4Layout/TopGameBoard/TopGameBoard_v4";
import SubListSelectorV4 from "../../components/v4Layout/SubListSelector/SubListSelector_v4";
import { COMMON_WEB_PATH } from "../../constant";
import JackpotWrapper from "../../components/v3Layout/Jackpot/JackpotWrapper";
import GameCatalogV4 from "../../components/GameCatalog_v4";
import GameCatalogV2 from "../../components/GameCatalog_v2";
import GameCatalog_v4Dragon from "../../components/GameCatalog_v4Gragon";
import HomeTitle from "../../components/HomeTitle";
import StartGameButton from "./StartGameButton";
import useCheckLogin from "../../hook/useCheckLogin";
import JackpotV4 from "../../components/v3Layout/Jackpot/Jackpot_v4";
import useDeviceType from "../../hook/useDeviceType";
import LeaderBoardWrapper from "../../components/v4Layout/LeaderBoard/LeaderBoardWrapper";

const HomeV4 = () => {
  const handleAllGame = useSelector((state) => state.isHandleAllGame);
  const currentCurrency = useSelector((state) => state.isWalletCurrency);
  const { isLogin, isMachineUser, isMachineDomain } = useCheckLogin();
  const boxBg = useColorModeValue("#EBEDFE", "#394367");

  const isMobile = useDeviceType() === "Mobile";

  const {
    platform,
    topGameList,
    newGameList,
    categorizedGames,
    exclusiveBrandGames,
    gameListByTypeAndSubType,
  } = handleAllGame;

  const subList = useSubTypeListV2({
    forceShowAll: true,
    gameMainTypeFromProps: "SLOT",
  });
  const subGames = useMemo(() => {
    const slot_game_list = gameListByTypeAndSubType?.["SLOT"];
    return Object?.keys(slot_game_list || {})?.map((sub_type, index) => {
      const subGamelist = slot_game_list[sub_type];
      return (
        subGamelist?.length > 0 && (
          <Fragment key={sub_type}>
            <GameCatalogV3
              list={subGamelist}
              //   wrapperClassName={subGamelist?.length === 0 && "hidden"}
              mainTitle={sub_type}
              observeFalse
              swiperProps={{
                customPerView: "v3-normal-layout",
              }}
              fullImageLayout
              itemClass="2xl:h-[225px] xl:h-[265px] h-[180px]"
              hiddenButtonNavigator
            />
          </Fragment>
        )
      );
    });
  }, [gameListByTypeAndSubType, subList, currentCurrency]);
  return (
    <Fragment>
      <Box minH="calc(100vh - 125px)" display="flex" flexDirection="column">
        <Box
          flex="1"
          minH="0" // 這很重要，確保內容不會溢出
          display="flex"
          flexDirection="column"
        >
          <Seo />
          <Box
            as="section"
            px="common-y"
            display={{ base: "block", xl: "none" }}
          >
            <GameTypeSelectorV4 />
          </Box>
          {/* <Box as="section" pt="home-space" px="common-y">
        <SubListSelectorV4 forceShowAll gameMainTypeFromProps={"SLOT"} />
      </Box> */}
          {!isMachineDomain && ( //機台域名不顯示
            <Flex
              my={{
                base: "0px",
                md: "10px",
              }}
              flexDirection={{
                base: "column",
                md: "row",
              }}
              gap={{
                base: "10px",
                md: "0px",
              }}
              px={{
                base: "5px",
                md: "0px",
              }}
            >
              <Box
                as="section"
                pt={{
                  base: "5px",
                  md: "0px",
                }}
                w="100%"
              >
                <CarouselV4 />
              </Box>

              {!isMobile ? (
                <LeaderBoardWrapper>
                  <Box
                    w={{
                      base: "100%",
                      md: "35%",
                    }}
                    display={{ base: "none", xl: "block" }}
                    bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/TOP_GAMES_BG2x2.webp')`}
                    bgPosition="center"
                    bgRepeat="no-repeat"
                    bgSize="100%100%"
                    borderRadius={{
                      base: "0px",
                      md: "common",
                    }}
                    py={{
                      base: "20px",
                      md: "35px",
                    }}
                    px={{
                      base: "20px",
                      md: "35px",
                    }}
                    mx={{
                      base: "0px",
                      md: "10px",
                    }}
                  >
                    <TopGameBoardV4 />
                  </Box>
                </LeaderBoardWrapper>
              ) : null}
            </Flex>
          )}

          {/* <Box as="section" pt="home-space" px="common-y">
        <MarqueeListV4 />
      </Box> */}
          <Box
            display={{ base: "block", md: "none" }}
            mt={{
              md: "0px",
              // base: "10px",
            }}
            pt={{
              base: "0px",
              md: "home-space",
            }}
            px={{
              base: "common-y",
              md: "0px",
            }}
          >
            <MobileSearchV4 listHidden />
          </Box>

          {/* 手機板熱門遊戲 */}
          {isMobile ? (
            <LeaderBoardWrapper>
              <Box
                w="100%"
                px={{
                  base: "common-y",
                  md: "20px",
                }}
                display={{ base: "block", md: "none" }}
              >
                <TopGameBoardV4 />
              </Box>{" "}
            </LeaderBoardWrapper>
          ) : null}
        </Box>
        <Box
          flex="none" // 確保這部分不會被拉伸
          // overflowY="scroll"
          // h={{
          //   base: "80vh",
          //   md: "auto",
          // }}
        >
          {/* 手機板TOP GAME */}
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            px={{
              base: "common-y",
              md: "10px",
            }}
            my="10px"
            display={{ base: "flex", md: "none" }}
          >
            <Flex
              bgImage={`${COMMON_WEB_PATH}/FlyDragon/TopGameTitleBG.webp`}
              bgRepeat={`no-repeat`}
              bgSize={`100% 100%`}
              justifyContent="center"
              alignItems="center"
              w="80%"
            >
              <Image
                src={`${COMMON_WEB_PATH}/FlyDragon/TOPGAME_Title_mobile.svg`}
                w="150px"
                h="25px"
                my="5px"
              />
            </Flex>
            <Box
              bgImage={`${COMMON_WEB_PATH}/FlyDragon/topGameBG_mobile2.webp?11`}
              bgRepeat={`no-repeat`}
              bgSize={`100% 100%`}
              w="100%"
            >
              <GameCatalogV4
                wrapperClassName={`!px-[0px]`}
                list={topGameList}
                // mainTitle={`BINGO`}
                // swiperProps={{
                //   customPerView: "popular-layout",
                // }}
                isHot
                observeFalse
                //customFixedWidth="155px"
                hiddenHeader
                hideGameName
                fullImageLayout
              />
            </Box>
          </Flex>

          {/* 電腦板TOP GAME */}
          <Box
            px={{ base: "10px", md: "10px" }}
            display={{ base: "none", md: "block" }}
          >
            <HomeTitle gameType="TOP10" />
            <GameCatalogV4
              wrapperClassName={`!px-[0px]`}
              list={topGameList}
              // mainTitle={`BINGO`}
              // swiperProps={{
              //   customPerView: "popular-layout",
              // }}
              isHot
              observeFalse
              //customFixedWidth="155px"
              hiddenHeader
              customHeight // 自定義高度 輪播的要比較長
            />
          </Box>
          <Box
            px={{
              base: "common-y",
              md: "10px",
            }}
          >
            <HomeTitle gameType="EXCLUSIVE" />
            <GameSelector
              gameMainTypeFromProps={"SLOT"}
              customGamePlatform={"EG"}
              hiddenTitle
              sliceCount={8}
              mb="10px"
            />
          </Box>
          <Box
            p="10px"
            px={{
              base: "common-y",
              md: "10px",
            }}
            mb={{
              base: "60px",
              sm: "0px",
            }}
          >
            {/* <GameCatalogV4
            wrapperClassName={`!px-[0px]`}
            list={topGameList}
            //mainTitle={`BINGO`}
            swiperProps={{
              delay: 1,
              speed: 25000,
              swiperClassName: "custom-swiper-wrapper",
              customPerView: "live-layout",
            }}
            isHot
            observeFalse
            //customFixedWidth="155px"
            hiddenHeader
          /> */}
            <HomeTitle gameType="POPULAR" />
            <GameSelector
              gameMainTypeFromProps={"SLOT"}
              customGamePlatform={"BNG"}
              hiddenTitle
              customSubType="Popular" // 顯示熱門推薦 最多四十個
              isNew // 顯示最新遊戲標籤
            />
          </Box>
          {/* <Box px="common-y">
            <GameCatalogV2
              wrapperClassName={`!px-[0px]`}
              list={topGameList}
              mainTitle={`Top`}
              // swiperProps={{
              //   customPerView: "popular-layout",
              // }}
              isHot
              observeFalse
              customFixedWidth="155px"
            />
          </Box>{" "}
          <Box px="common-y">
            <GameCatalogV2
              wrapperClassName={`!px-[0px]`}
              list={newGameList}
              mainTitle={`News`}
              customHotTag={{
                color: "linear(to-r,#9DF87B,#0E9117)",
                text: (
                  <Text as="span" fontSize={`sm`} letterSpacing={`tighter`}>
                    New
                  </Text>
                ),
              }}
              observeFalse
              // swiperProps={{
              //   customPerView: "popular-layout",
              // }}
              isHot
              customFixedWidth="155px"
            />
          </Box> */}
          {/* <Box py="home-space" px="common-y">
            <GameSelector
              hiddenTitle
              gameMainTypeFromProps={gameMainTypeFromProps}
            />
          </Box> */}
        </Box>
      </Box>

      {/* 未登入 且判斷是機台 顯示全螢幕半透明黑色背景 讓他按開始遊戲 */}
      {/* {!isLogin && isMachineUser && <StartGameButton />} */}
    </Fragment>
  );
};

export default HomeV4;
