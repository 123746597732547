import {
  Box,
  chakra,
  Flex,
  Icon,
  Image,
  shouldForwardProp,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { COMMON_WEB_PATH } from "../constant";
import { useDispatch, useSelector } from "react-redux";
import { toggleBGM } from "../redux/action/action";
import { useTranslation } from "react-i18next";
import { isValidMotionProp, motion } from "framer-motion";
import LoadingContainer from "./Loading/LoadingContainer";
import useSound from "../hook/useSound";
import dayjs from "dayjs";
import { ImSpinner6 } from "react-icons/im";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const WAIT_TIME = 30;

const ClearCashConfirm = ({ onConfirm, onCancel }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);
  const [isClosing, setIsClosing] = useState(false);
  const [remainingTime, setRemainingTime] = useState("-");
  const [isLoading, setIsLoading] = useState(true);

  const settle_music = useSound({ customSound: "settle", volume: 0.5 });

  const isBGMEnabled = useSelector((state) => state.isBGMEnabled);
  const dispatch = useDispatch();

  // 處理確認按鈕
  const handleConfirm = () => {
    if (remainingTime !== "done") return;
    setIsClosing(true);
    onConfirm();
  };

  // 處理取消按鈕
  const handleCancel = () => {
    setIsClosing(true);
    dispatch(
      toggleBGM({
        ...isBGMEnabled,
        song: "back2",
        musicVolume: 0.2,
      })
    );
    onCancel();
  };

  useEffect(() => {
    dispatch(
      toggleBGM({
        ...isBGMEnabled,
        enabled: true,
        song: "settle",
        musicVolume: 0.5,
      })
    );
  }, []);

  useEffect(() => {
    const playedTime = localStorage.getItem("played_time");
    if (playedTime) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const playedTime = localStorage.getItem("played_time");
    const playedTimeDate = dayjs(playedTime);

    if (playedTime && dayjs(playedTime).isValid()) {
      const interval = setInterval(() => {
        const now = dayjs();
        const diff = now.diff(playedTimeDate, "second");
        if (diff <= WAIT_TIME) {
          if (diff === WAIT_TIME) {
            setRemainingTime("done");
          } else {
            setRemainingTime(WAIT_TIME - diff);
          }
        } else {
          clearInterval(interval);
          setRemainingTime("done");
          localStorage.removeItem("played_time");
        }
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setRemainingTime("done");
    }
  }, []);
  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="rgba(0, 0, 0, 0.8)"
      zIndex="9999"
      backdropFilter="blur(2px)"
      justifyContent="center"
      alignItems="center"
    >
      <ChakraBox
        as={Flex}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3, delay: 0.3 }}
        w="520px"
        h="300px"
        bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/hintBG.webp')`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="100% 100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        position={"relative"}
        p={6}
      >
        {isClosing && <LoadingContainer />}
        <Flex
          fontSize="24px"
          color="white"
          textAlign="center"
          mt={4}
          bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/hintTitleBG.webp')`}
          bgPosition="center"
          bgRepeat="no-repeat"
          bgSize="100% 100%"
          sx={{ width: "-webkit-fill-available" }}
          justifyContent="center"
          alignItems="center"
        >
          <Image
            src={`${COMMON_WEB_PATH}/FlyDragon/hintWORD.webp`}
            height={{
              base: "60px",
              xl: "60px",
            }}
          />
        </Flex>
        {isLoading ? (
          <Flex alignItems={"center"} justifyContent={"center"}>
            <Icon
              as={ImSpinner6}
              fontSize={"30px"}
              className="animate-spin"
              color="yellow.500"
            />
          </Flex>
        ) : (
          <>
            <Box fontSize="32px" color="white" textAlign="center" mb={2}>
              {remainingTime == "done"
                ? i18n("confirm-clearCash")
                : i18n("please-wait-game-settle")}
            </Box>
            {remainingTime !== "done" && (
              <Box fontSize="24px" color="white" textAlign="center" mb={2}>
                {i18n("remaining-time")}: {remainingTime} {i18n("seconds")}
              </Box>
            )}
          </>
        )}

        <Flex gap={4} mb={4} justifyContent="space-between" alignItems="center">
          <Flex
            as="button"
            w="165px"
            h="62px"
            bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/yesBTN.webp')`}
            bgPosition="center"
            bgRepeat="no-repeat"
            bgSize="100% 100%"
            justifyContent="center"
            alignItems="center"
            filter={remainingTime == "done" ? "" : "grayscale(100%)"}
            onClick={handleConfirm}
            _hover={{
              filter:
                remainingTime == "done" ? "brightness(1.1)" : "grayscale(100%)",
            }}
            cursor={remainingTime == "done" ? "pointer" : "not-allowed"}
          >
            <Image
              src={`${COMMON_WEB_PATH}/FlyDragon/yesWORD.webp`}
              width="70px"
              height={{
                base: "30px",
                xl: "30px",
              }}
            />
          </Flex>

          <Flex
            as="button"
            w="165px"
            h="62px"
            bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/cancelBTN.webp')`}
            bgPosition="center"
            bgRepeat="no-repeat"
            bgSize="100% 100%"
            cursor="pointer"
            justifyContent="center"
            alignItems="center"
            onClick={handleCancel}
            _hover={{ filter: "brightness(1.1)" }}
          >
            <Image
              src={`${COMMON_WEB_PATH}/FlyDragon/cancelWORD.webp`}
              width="70px"
              height={{
                base: "30px",
                xl: "30px",
              }}
            />
          </Flex>
        </Flex>
      </ChakraBox>
    </Flex>
  );
};

export default ClearCashConfirm;
