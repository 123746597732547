import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import WalletList from "./walletList";
import { COMMON_WEB_PATH, CURRENCY_SYMBOL } from "../../../constant";
import { IoIosAdd } from "react-icons/io";
import MyBalanceV3 from "../../../pages/Profile/components/balance_v3";
import { useNavigate, useLocation } from "react-router";
import {
  AbsoluteCenter,
  Box,
  Divider,
  Flex,
  Icon,
  Image,
  Popover,
  PopoverTrigger,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import useCheckLogin from "../../../hook/useCheckLogin";

const isCredit = process.env.REACT_APP_CREDIT == 1;
const BalanceV4 = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { isMachineUser } = useCheckLogin();

  const linkColor = useColorModeValue("light.100", "#394367");

  const memberInfo = useSelector((state) => state.isMemberInfo);

  const { pathname } = useLocation();

  return (
    <Popover
      variant={"brandPrimary"}
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
    >
      <Flex
        borderRadius={`10px`}
        // _hover={{
        //   bg: baseHoverBg,
        // }}
        // bg={baseBg}
        bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/credit_border.png')`}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="100%100%"
        pl={{
          base: "20px",
          md: "40px",
        }}
        pr={{
          base: "20px",
          md: "40px",
        }}
        pb={{
          base: "10px",
          md: "15px",
        }}
        pt={{
          base: "10px",
          md: "25px",
        }}
        alignItems="center"
        gap="10px"
        minW="180px"
        height={{
          base: "65px",
          sm: "65px",
          md: "65px",
          xl: "70px",
        }}
        cursor="pointer"
        position="relative"
        // mb={{
        //   sm: "10px",
        // }}
        shadow="sm"
        justifyContent={"center"}
      >
        <AbsoluteCenter
          display={{
            base: "none",
            md: "block",
          }}
          w="90px"
          top="10px"
        >
          <Image src={`${COMMON_WEB_PATH}/FlyDragon/credit_word.png`} />
        </AbsoluteCenter>
        <PopoverTrigger w="100%">
          <Flex w="100%" direction={`column`} alignItems={`center`}>
            <Text
              display={{
                base: "block",
                md: "none",
              }}
              fontSize="sm"
            >
              {memberInfo?.memId}
            </Text>
            <Divider
              display={{
                base: "block",
                md: "none",
              }}
              w="100%"
            />
            <Box
              onClick={() => {
                if (pathname.includes("gamebox")) {
                  return;
                } else {
                  if (isCredit) {
                    return;
                  }
                  onToggle();
                }
              }}
              display={`flex`}
              alignItems={`center`}
              gap="5px"
            >
              <MyBalanceV3
                hiddenSymbol
                balanceStyle={{
                  fontSize: {
                    base: "18px",
                    md: "20px",
                    lg: "24px",
                    xl: "24px",
                  },
                  fontWeight: "900",
                }}
              />
            </Box>
          </Flex>
        </PopoverTrigger>
        {/* {!isMachineUser && (
          <Link to="profile/deposit">
            <Box
              w="24px"
              h="24px"
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              bg={linkColor}
              borderRadius={"full"}
            >
              <Flex
                color="light.100"
                borderRadius={"100%"}
                // bg="v3-second-brand.500"
                alignItems={"center"}
                justifyContent={"center"}
                w="24px"
                h="24px"
              >
                <Image
                  src="https://d1h3qe8voog6w4.cloudfront.net/v4-layout/add.svg"
                  w="24px"
                  h="24px"
                />
               </Flex>
            </Box>
          </Link>
        )} */}
      </Flex>
      {/* <WalletList onClose={onClose} /> */}
    </Popover>
  );
};

export default BalanceV4;
