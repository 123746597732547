import React, { useState, cache, useEffect } from "react";
import CommonConfirmModal from "../../../components/Modal/commonConfirmModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeCache } from "../../../redux/action/member/memberAction";
import { notice } from "../../../redux/action/action";
import { Flex, Image, Text, useToast } from "@chakra-ui/react";
import { waitTime } from "../../../utils/waitTime";
import { COMMON_WEB_PATH } from "../../../constant";

const CachePopV4 = ({ openModal, setModalOpen }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const dispatch = useDispatch();
  const cachePop = useSelector((state) => state.isCachePop);

  const toast = useToast();

  const [buttonLoading, setButtonLoading] = useState(false);
  const clearCache = async () => {
    setButtonLoading(true);
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        action: "refreshCache",
      });
    }

    await caches
      .keys()
      .then((keyList) => {
        return Promise.all(
          keyList.map((key) => {
            return caches.delete(key);
          })
        );
      })
      .then(() => {
        dispatch(closeCache());
      });

    toast({
      title: i18n("clearCacheSuccess"),
      description: i18n("willRefreshPageAfterThreeSeconds"),
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setButtonLoading(false);
    await waitTime(3000);
    window.location.reload();
  };
  return (
    <CommonConfirmModal
      modalProps={{
        title: i18n("clearcache"),
        okButtonText: i18n("confirm"),
        buttonLoading: buttonLoading,
        content: (
          <>
            <Flex
              direction={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              gap="15px"
            >
              <Image
                w="150px"
                src={`${COMMON_WEB_PATH}/v4-layout/clear_cache.png`}
              />
              <Text>{i18n("confirm-clearCache")}</Text>
            </Flex>
          </>
        ),
        cancelButtonText: i18n("cancel"),
        footerStyle: {
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        },
        buttonStyle: {
          width: "100%",
          mr: "0",
          borderRadius: "10px",
          height: "40px",
        },
        confirmButtonStyle: {
          width: "100%",
          bgGradient: "linear(to-b, #4D77FB, #2755FE)",
          color: "white",
          _hover: {
            bgGradient: "linear(to-b, #2755FE, #4D77FB)",
            opacity: 0.9,
          },
        },
      }}
      onConfirm={() => {
        clearCache();
      }}
      modalOpen={cachePop}
      size="xs"
      onClose={() => {
        dispatch(closeCache());
      }}
    />
  );
};

export default CachePopV4;
