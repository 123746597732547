import React, { useMemo } from "react";
import ThemeTab from "./themeTab";
import ThemeTabV4 from "./themeTab_v4";
import { WEB_LAYOUT } from "../../../constant";

const ThemeTabSelector = (props) => {
  const themeTab = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v4":
        return <ThemeTabV4 {...props} />;
      default:
        return <ThemeTab {...props} />;
    }
  }, [props]);
  return themeTab;
};

export default ThemeTabSelector;
