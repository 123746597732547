import React, { useEffect, useMemo, useState } from "react";
import CommonTab from "../../../components/TabComponent/CommonTab";
import LevelDetail from "./levelDetail";
import TransferDetail from "./transferDetail";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useTransferMode from "../../../hook/useTransferMode";
import { Box, useColorModeValue } from "@chakra-ui/react";
import RewardDetail from "./rewardDetail";
import CreditPage from "../../../utils/credit/credit_page";
import VipOpenPage from "../../../utils/credit/vip_open_page";

const TAB = [
  { label: "Level", value: "level" },
  // { label: "Reward", value: "reward" },
  // { label: "My points", value: "myPoints", showWhenTransferMode: true },
];

const VipBar = () => {
  const { t } = useTranslation();
  const i18n = (key, props) => t(`vip.${key}`, { ...props });

  const boxBg = useColorModeValue("light.200", "dark.200");

  const [tab, setTab] = useState(TAB[0]?.value);

  const isTriggerTransferTab = useSelector(
    (state) => state.isTriggerTransferTab
  );
  const isTransferMode = useTransferMode();

  useEffect(() => {
    setTab(TAB[1]?.value);

    if (!isTransferMode) {
      setTab(TAB[0]?.value);
      return;
    }
  }, [isTriggerTransferTab, isTransferMode]);

  const components = useMemo(() => {
    switch (tab) {
      case "level":
        return <LevelDetail />;
      case "reward":
        return <RewardDetail />;
      default:
        return <></>;
    }
  }, [tab]);
  return (
    <Box
      p="8px"
      bg={{
        base: boxBg,
        md: "transparent",
      }}
    >
      {" "}
      <CreditPage>
        <Box as="section" display={`flex`} alignItems={`center`} mb="10px">
          <CommonTab setTab={setTab} tab={tab} tabList={TAB} />
        </Box>
      </CreditPage>
      <Box as="section">{components}</Box>
    </Box>
  );
};

export default VipBar;
