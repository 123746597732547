import React, { useMemo } from "react";
import AsideV2 from "./aside_v2";
import Aside from "./aside";
import AsideV3 from "./aside_v3";
import { WEB_LAYOUT } from "../../constant";
import AsideV4 from "./aside_v4";

const AsideVersionSelector = () => {
  const aside = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <AsideV2 />;
      case "v3":
        return <AsideV3 />;
      case "v4":
        return <AsideV4 />;
      default:
        return <Aside />;
    }
  }, [WEB_LAYOUT]);
  return aside;
};

export default AsideVersionSelector;
