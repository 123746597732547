import { Icon, useClipboard, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoIosCopy } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { notice } from "../../redux/action/action";
import { FaCopy } from "react-icons/fa6";
import { PiCopy } from "react-icons/pi";

const CopyIconV4 = ({ copyLink, fontSize } = {}) => {
  const { t } = useTranslation();
  const i18n_common = (key) => t(`common.${key}`);
  const dispatch = useDispatch();

  const { onCopy, value, setValue, hasCopied } = useClipboard("");

  const handleCopy = async () => {
    await onCopy();

    dispatch(
      notice({
        title: i18n_common("Copy Success"),
        content: "",
        type: "success",
      })
    );
  };

  useEffect(() => {
    setValue(copyLink);
  }, [copyLink]);

  return hasCopied ? (
    <Icon
      as={TiTick}
      fontSize={fontSize || "lg"}
      color="green.300"
      cursor="pointer"
    />
  ) : (
    <Icon
      as={PiCopy}
      fontSize={fontSize || "lg"}
      color="#b0b0b5"
      cursor="pointer"
      onClick={handleCopy}
    />
  );
};

export default CopyIconV4;
