import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  chakra,
  shouldForwardProp,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NotFound from "../../ProgressBlock/NotFound";
import useDeviceType from "../../../hook/useDeviceType";
import { languageTransfer } from "../../../utils/languageTransfer";
import i18next from "i18next";
import { routeMapping } from "../GameTypeSelector/utils/routeMapping";
import { useTranslation } from "react-i18next";
import { menuIcon } from "../../Menu/helper/menuIcon";
import { isValidMotionProp, motion } from "framer-motion";
import { gameListFromBottom } from "../../../animateConstant";
import LIVEGAMEITEMV4 from "../../GAMEITEM/LIVEGAMEITEM_v4";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const LiveGameSelector = ({ gameMainTypeFromProps }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const scrollRef = useRef();

  const { gameMainType, gamePlatform, subType } = useParams();

  const isMobile = useDeviceType() === "Mobile";

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const { gameList } = isHandleAllGame || {};
  const platformGamesList = useMemo(() => {
    const mainType = gameMainTypeFromProps || gameMainType;
    const current_game_type = routeMapping(mainType)?.origin;
    const current_game_type_platform = gameList?.[current_game_type];

    const platformList = Object?.keys(current_game_type_platform || {});
    const platformFindFirstGame = platformList?.map((platform) => {
      const findFirstGame = current_game_type_platform?.[platform]?.[0];
      return findFirstGame;
    });
    return platformFindFirstGame;
  }, [gameList, gameMainType, gameMainTypeFromProps]);
  useEffect(() => {
    if (scrollRef.current) {
      if (subType || gamePlatform) {
        const elementPosition = scrollRef.current.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - 100;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [subType, gamePlatform]);

  return (
    <Box ref={scrollRef}>
      {platformGamesList?.length === 0 && <NotFound />}
      <ChakraBox {...gameListFromBottom}>
        <Grid
          templateColumns={{
            base: "repeat(1, minmax(0, 1fr))",
            md: "repeat(2, minmax(0, 1fr))",
            lg: "repeat(2, minmax(0, 1fr))",
            xl: "repeat(2, minmax(0, 1fr))",
            "2xl": "repeat(2, minmax(0, 1fr))",
          }}
          rowGap={"10px"}
          columnGap="12px"
        >
          {platformGamesList?.map((item, index) => {
            const gameName =
              item?.[
                `gameName${languageTransfer({
                  lang: i18next.language,
                })}`
              ];
            const gameNameEn = item?.gameNameEn;
            const platform = item?.game_platform;
            const gameSubType = item?.game_subtype;

            const gameCode = item?.gameCode;
            const gameType = item?.gameType;
            return (
              <GridItem
                // colSpan={index === 0 ? 2 : 1}
                // rowSpan={index === 0 ? 2 : 1}
                bg="brand.900"
                borderRadius={"25px"}
              >
                <LIVEGAMEITEMV4
                  gameProps={{
                    gameName,
                    platform,
                    gameCode,
                    gameType,
                    gameNameEn,
                    gameSubType,
                  }}
                  originProps={item}
                  itemClass={`!h-[155px]`}
                  itemIndex={index}
                  liveLayout={{
                    isLiveLayoutOpen: true,
                    isLobby: item?.game_subtype == "Lobby",
                    showLogoInCenter: true,
                  }}
                />
              </GridItem>
            );
          })}
        </Grid>
      </ChakraBox>
    </Box>
  );
};

export default LiveGameSelector;
