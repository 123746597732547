import React from "react";
import { COMMON_WEB_PATH } from "../../constant";
import { Box, Flex, keyframes } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { machineLogin } from "../../api/getApi";

// 定義動畫
const hitHereKeyframes = keyframes`
30% { transform: scale(1.2); }
40%, 60% { transform: rotate(-5deg) scale(1.2); }
50% { transform: rotate(5deg) scale(1.2); }
70% { transform: rotate(0deg) scale(1.2); }
100% { transform: scale(1); }
`;

// 創建動畫樣式
const hitHereAnimation = `${hitHereKeyframes} 1.5s ease infinite`;

const StartGameButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMachineLogin = async () => {
    // // 創建並播放背景音樂
    // const audio = new Audio(`${COMMON_WEB_PATH}/FlyDragon/back2.MP3`);
    // audio.loop = true;
    // audio.volume = 0.5;
    // audio.play().catch((error) => {
    //   console.log("播放背景音樂失敗:", error);
    // });

    // // 開啟背景音樂的 Redux 狀態
    // dispatch(toggleBGM(true));
    // console.log("開啟背景音樂");
    // window.backgroundAudio = audio;

    // 播放背景音樂
    //await playBackgroundMusic();

    try {
      // 檢查是否為機台域名
      const isMachineDomain = window.location.hostname.startsWith("pc.");
      if (!isMachineDomain) {
        console.error("非機台域名");
        return;
      }

      // 檢查 URL 中的 mac 參數
      const urlParams = new URLSearchParams(window.location.search);
      let macAddress = urlParams.get("mac");

      // 如果URL中沒有mac參數 就從cookie取
      if (!macAddress) {
        macAddress = Cookies.get("mac_address");

        if (!macAddress) {
          console.error("URL 和 cookie 中都沒有找到 mac 地址");
          return;
        }
      } else {
        // 如果從 URL 獲取到了 mac，就更新到 cookie 中
        Cookies.set("mac_address", macAddress);
      }

      // 使用取到的 macAddress 進行登入
      const response = await machineLogin(macAddress);

      if (response && response.token) {
        // 如果已經有 token 先移除
        if (Cookies.get("token")) {
          Cookies.remove("token");
        }
        // 設新的 token 有效期3天
        Cookies.set("token", response.token, { expires: 3 });

        // 初始化 API（更新 Redux store）
        dispatch({
          type: "INITIALIZE_API",
          navigate: navigate,
        });

        navigate("/"); // 導航到首頁
      } else {
        console.error("No MAC address found", response);
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Machine login failed:", error);
    }
  };

  return (
    <Flex
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      bg="rgba(0, 0, 0, 0.8)" // 半透明黑色
      zIndex="9999"
      backdropFilter="blur(2px)" // 添加模糊效果
      justifyContent="center"
      alignItems="center"
    >
      <Box
        onClick={handleMachineLogin}
        position="relative"
        animation={hitHereAnimation} // 添加動畫
        cursor="pointer" // 添加指針樣式
        _hover={{
          filter: "brightness(1.2)", // 可選：懸停效果
        }}
      >
        <Box
          as="section"
          position="absolute"
          left="50%"
          top="50%"
          transform="translate(-50%, -50%)"
          w={{
            base: "200px",
            md: "200px",
          }}
          h={{
            base: "200px",
            md: "200px",
          }}
          bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/game-type-icon/iconBG.webp')`}
          bgPosition="center"
          bgRepeat="no-repeat"
          bgSize="100% 100%"
        />
        <Box
          position="absolute"
          left="50%"
          top="50%"
          transform="translate(-50%, -40%)"
          w={{
            base: "280px",
            md: "280px",
          }}
          h={{
            base: "100px",
            md: "100px",
          }}
          bgImage={`url('${COMMON_WEB_PATH}/FlyDragon/startGame.webp')`}
          bgPosition="center"
          bgRepeat="no-repeat"
          bgSize="100% 100%"
          zIndex="1"
        />
      </Box>
    </Flex>
  );
};

export default StartGameButton;
