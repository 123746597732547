import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../../constant";
import RecordDashboardV4 from "./recordDashboard_v4";
import RecordDashboard from "./recordDashboard";

const RecordDashboardSelector = (props) => {
  const recordDashboard = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v4":
        return <RecordDashboardV4 {...props} />;
      default:
        return <RecordDashboard {...props} />;
    }
  }, [props]);
  return recordDashboard;
};

export default RecordDashboardSelector;
