import { parseSomething } from "../utils/parseSomething";

export const colorConfig =
  process.env.REACT_APP_SECRET_TYPE === "local"
    ? {
        "67-main": {
          leaderBoard: {
            left: "#a4872a",
            right: "#bb9a30",
            border: "#8c7424",
            avatar: "#d3b552",
            "v3-border": "#C9D0E9",
          },
          brand: {
            100: "#f1e7c7",
            200: "#ebddaf",
            300: "#e5d398",
            400: "#dfc981",
            500: "#d3b552",
            600: "#cdab3b",
            700: "#bb9a30",
            800: "#af912d",
            900: "#a4872a",
            950: "#987e27",
            1000: "#8c7424",
          },
          special: {
            1: "#6779B1",
            gold: "#f8e709",
            "jackpot-from": "#FEF87D",
            "jackpot-to": "#F6EB20",
          },
          "game-item": {
            mask: "rgba(211, 181, 82, 0.4)",
            "text-mask-from": "#dfc981",
          },
          "second-brand": {
            500: "#a1110f",
            700: "#c11412",
          },
          "right-promotion-box": {
            to: "#d9bf69",
            from: "#dfc981",
            border: "#cdab3b",
            hover_to: "#d3b552",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#d9bf69",
          },
          "v3-second-brand": {
            100: "#f3afaf",
            200: "#ef9797",
            300: "#ec7f7f",
            400: "#e86767",
            500: "#e13636",
            600: "#da2121",
            700: "#c21d1d",
            800: "#b61b1b",
            900: "#aa1919",
            950: "#9e1818",
            1000: "#921616",
            "500-opacity": "#921616",
            "700-opacity": "#791212",
          },
          "v3-profile": {
            "banner-from": "#FEFEFD",
            "banner-to": "#F2F0DF",
            "banner-border": "#9D6C1D",
            "banner-title": "#F5F3E6",
          },
          light: {
            100: "#f1f2f4",
            200: "#ffffff",
            300: "#DDE0E3",
            350: "rgb(170,170,170)",
            400: "#535353",
            500: "#15191C",
            600: "#5f5f5f",
          },
          dark: {
            100: "#2d250c",
            150: "#171306",
            200: "#231d09",
            250: "#bb9a30",
            300: "#cdab3b",
            400: "#d9bf69",
            500: "#d3b552",
            600: "#dfc981",
            700: "#ebddaf",
          },
        },
        "67-v4": {
          leaderBoard: {
            left: "#1b38a0",
            right: "#1f40b7",
            border: "#17308a",
            avatar: "#3055dc",
            "v3-border": "#C9D0E9",
          },
          brand: {
            100: "#a3b3ef",
            200: "#8ca0ec",
            300: "#758de8",
            400: "#5e7be4",
            500: "#7a1abe",
            600: "#2348ce",
            700: "#1f40b7",
            800: "#1d3cac",
            900: "#1b38a0",
            950: "#193495",
            1000: "#17308a",
          },
          special: {
            1: "#6779B1",
            gold: "#f8e709",
            "jackpot-from": "#FEF87D",
            "jackpot-to": "#F6EB20",
          },
          "game-item": {
            mask: "rgba(48, 85, 220, 0.4)",
            "text-mask-from": "#5e7be4",
          },
          "second-brand": {
            500: "#000000",
            700: "#000000",
          },
          "right-promotion-box": {
            to: "#4768e0",
            from: "#5e7be4",
            border: "#2348ce",
            hover_to: "#3055dc",
            background: {
              platformBg: "#e3e3e3",
              transactionCard: "#fff",
            },
            hover_from: "#4768e0",
          },
          "v3-second-brand": {
            100: "#fac789",
            200: "#f9bc70",
            300: "#f8b057",
            400: "#f7a43f",
            500: "#f58d0d",
            600: "#df7f09",
            700: "#c67108",
            800: "#ba6a08",
            900: "#ad6307",
            950: "#a15c07",
            1000: "#955506",
            "500-opacity": "#955506",
            "700-opacity": "#7c4705",
          },
          "v3-profile": {
            "banner-from": "#bac6f3",
            "banner-to": "#8ca0ec",
            "banner-border": "#1f40b7",
            "banner-title": "#758de8",
          },
          light: {
            100: "#FAFAFA",
            200: "#EFEFF5",
            250: "#ffffff",
            300: "#D9D9D9",
            350: "#D5D5D5",
            400: "#535353",
            450: "#646464",
            500: "#15191C",
            550: "#D9D9D9",
            600: "#5f5f5f",
          },
          dark: {
            100: "#1A2036",
            150: "#394367",
            200: "#1A2036",
            250: "#262D47",
            300: "#6675AE",
            350: "#6574AD",
            400: "#4768e0",
            450: "#4F5C8B",
            500: "#3055dc",
            600: "#5e7be4",
            700: "#8ca0ec",
          },
        },
      }
    : parseSomething(window.web_style_config);
