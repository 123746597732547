import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../../constant";
import BindBankAccount from "./bindBankAccount";
import BindBankAccountV4 from "./bindBankAccount_v4";

const BindBankAccountSelector = () => {
  const bindBankAccount = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <BindBankAccount />;
      case "v3":
        return <BindBankAccount />;
      case "v4":
        return <BindBankAccountV4 />;
      default:
        return <BindBankAccount />;
    }
  }, [WEB_LAYOUT]);
  return bindBankAccount;
};

export default BindBankAccountSelector;
