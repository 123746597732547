export const RULES = [
  {
    title: "Invite Friends",
    details: [
      "Invite friends to register and deposit and earn rewards.",
      "Earn rewards for up to three levels of referrals.",
    ],
    calculation: [
      "Level 1 first deposit, commission reward of $1,000.00.",
      "Level 2 first deposit, commission reward of $200.00.",
      "Level 3 first deposit, commission reward of $100.00.",
    ],
  },
  // {
  //   title: "Friends Betting",
  //   details: [
  //     "Earn cashback when your friends place bets.",
  //     "Receive cashback for up to three levels of referrals.",
  //     "Applicable only to slot games and leisure games.",
  //     "The cashback reward is credited the next day at 00:00 based on the amount wagered by the referred players across three levels.",
  //     "The cashback reward can be withdrawn directly.",
  //   ],
  //   calculation: [
  //     "Level 1 bets, you receive a commission reward of 0.60%.",
  //     "Level 2 bets, you receive a commission reward of 0.30%.",
  //     "Level 3 bets, you receive a commission reward of 0.10%.",
  //   ],
  // },
];

//規則-獎勵計算表格內容
export const COMISSION_RULES = [
  {
    level: 1,
    turnover: "8000000~80000000",
    commission: 0.1,
    from: 8000000,
    to: 80000000,
  },
  {
    level: 2,
    turnover: "80000001~240000000",
    commission: 0.2,
    from: 80000001,
    to: 240000000,
  },
  {
    level: 3,
    turnover: "240000001~560000000",
    commission: 0.3,
    from: 240000001,
    to: 550000000,
  },
  {
    level: 4,
    turnover: "560000001~1200000000",
    commission: 0.4,
    from: 550000001,
    to: 1200000000,
  },
  {
    level: 5,
    turnover: "1200000001~2500000000",
    commission: 0.5,
    from: 1200000001,
    to: 2500000000,
  },
  {
    level: 6,
    turnover: "2500000001~5100000000",
    commission: 0.55,
    from: 2500000001,
    to: 5100000000,
  },
  {
    level: 7,
    turnover: "5100000001~10300000000",
    commission: 0.6,
    from: 5100000001,
    to: 10300000000,
  },
  {
    level: 8,
    turnover: "10300000001~20700000000",
    commission: 0.65,
    from: 10300000001,
    to: 20700000000,
  },
  {
    level: 9,
    turnover: "20700000001~40000000000",
    commission: 0.7,
    from: 20700000001,
    to: 40000000000,
  },
  {
    level: 10,
    turnover: "over 40000000001",
    commission: 0.8,
    from: "over",
    to: 40000000001,
  },
];

export const formatTurnoverForDisplay = (locale, turnover) => {
  const million = 1000000;
  const billion = 1000 * million;
  let formattedNumber = turnover.toString();

  if (turnover === "over") {
    return "over";
  }

  switch (locale) {
    case "tw":
      if (turnover >= billion / 10) {
        formattedNumber = (turnover / billion).toFixed(2) * 10 + "億";
      } else if (turnover >= million) {
        formattedNumber = (turnover / million).toFixed(2) * 100 + "萬";
      }
      break;
    case "en":
      if (turnover >= billion) {
        formattedNumber = (turnover / billion).toFixed(1) + " billion";
      } else if (turnover >= million) {
        formattedNumber = (turnover / million).toFixed(1) + " million";
      }
      break;
    case "zh_chs":
      if (turnover >= billion / 10) {
        formattedNumber = (turnover / billion).toFixed(2) * 10 + "亿";
      } else if (turnover >= million) {
        formattedNumber = (turnover / million).toFixed(2) * 100 + "万";
      }
      break;
    case "viet":
      if (turnover >= billion) {
        formattedNumber = (turnover / billion).toFixed(1) + " tỷ";
      } else if (turnover >= million) {
        formattedNumber = (turnover / million).toFixed(1) + " triệu";
      }
      break;

    default:
      if (turnover >= billion) {
        formattedNumber = (turnover / billion).toFixed(1) + " tỷ";
      } else if (turnover >= million) {
        formattedNumber = (turnover / million).toFixed(1) + " triệu";
      }
      break;
      break;
  }

  return formattedNumber;
};
