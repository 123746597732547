import React, { useState, useEffect } from "react";
import CloseDetailPage from "../../../components/CloseComponent/CloseDetailPage";
import i18next from "i18next";
import { IoCloseOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { closeComissionListPop } from "../../../redux/action/member/memberAction";
import { useTranslation } from "react-i18next";
import { HiChevronLeft } from "react-icons/hi";
import CommonOpacityBackground from "../../../components/Common/commonOpacityBackground";
import { getCommissionList } from "../../../api/getApi";
import NotFound from "../../../components/ProgressBlock/NotFound";
import { formatNumber } from "../../../utils/formatNumber";
import { motion } from "framer-motion";

const ComissionListPage = ({ cagentCommissionList }) => {
  const { t } = useTranslation();
  const i18n = (key) => t(`referral.${key}`);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const memberData = useSelector((state) => state.isMemberInfo);
  const [countdown, setCountdown] = useState(0); // 60

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  return (
    <CommonOpacityBackground
      customFunction={() => {
        dispatch(closeComissionListPop());
      }}
      className="!items-center"
    >
      <motion.div
        onClick={(event) => {
          event.stopPropagation();
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
        className="w-full max-w-[450px] min-h-[600px] bg-transaction-card dark:bg-base-light-dark rounded-[10px]"
      >
        <CloseDetailPage
          title={i18n("comissionList")}
          hasPrev={
            <HiChevronLeft
              onClick={() => {
                dispatch(closeComissionListPop());
              }}
              className="cursor-pointer"
            />
          }
          customClose={<p></p>}
        />

        {/* <section className="flex justify-center">
          <table>
            <thead className="bg-main-color text-white text-center">
              <tr>
                <th className="border px-3 py-2">Total Bet</th>
                <th className="border px-3 py-2">RP Level</th>
                <th className="border px-3 py-2">Commission</th>
                <th className="border px-3 py-2">Member ID</th>
              </tr>
            </thead>
            <tbody>
              {cagentCommissionList.map((item, index) => (
                <tr key={index}>
                  <td>{item.totalbet}1</td>
                  <td>{item.rpLevel}2</td>
                  <td>{item.commission}3</td>
                  <td>{item.b_memId}4</td>
                </tr>
              ))}
              <tr>
                <td className="border border-[#bcbcbc] px-3 py-2 text-center">
                  1
                </td>
                <td className="border border-[#bcbcbc] px-3 py-2 text-center">
                  2
                </td>
                <td className="border border-[#bcbcbc] px-3 py-2 text-center">
                  3
                </td>
                <td className="border border-[#bcbcbc] px-3 py-2 text-center">
                  4
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
              </tr>
            </tbody>
          </table>
        </section> */}
        <section className="p-[10px]">
          <section>
            <div className="grid grid-cols-4 items-center text-center text-sm font-medium border-b pb-[3px] dark:text-base-light-white text-base-light-dark">
              <span>{i18n("Member ID")}</span>
              <span>{i18n("Agent Level")}</span>
              <span>{i18n("Total Bet")}</span>
              <span>{i18n("Commission")}</span>
            </div>
            {cagentCommissionList.length > 0 ? (
              cagentCommissionList.map((item, index) => {
                const {
                  totalbet = 123123132120,
                  rpLevel = 0,
                  commission = 0,
                  b_memId = "",
                } = item;
                return (
                  <div
                    className={`${
                      index % 2 == 0 && "bg-base-deep-gray"
                    } grid grid-cols-4 items-center text-sm font-medium border-b py-[3px]`}
                  >
                    <span>{b_memId}</span>
                    <span>{rpLevel}</span>
                    <span>{formatNumber(totalbet)}</span>
                    <span>{formatNumber(commission)}</span>
                  </div>
                );
              })
            ) : (
              <NotFound />
            )}
          </section>
        </section>
      </motion.div>
    </CommonOpacityBackground>
  );
};

export default ComissionListPage;
