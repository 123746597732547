import React, { useMemo } from "react";
import ResetPassword from "./resetPassword";
import ResetPasswordV4 from "./resetPassword_v4";
import { WEB_LAYOUT } from "../../../constant";

const ResetPasswordSelector = () => {
  const resetPassword = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return <ResetPassword />;
      case "v3":
        return <ResetPassword />;
      case "v4":
        return <ResetPasswordV4 />;
      default:
        return <ResetPassword />;
    }
  }, [WEB_LAYOUT]);
  return resetPassword;
};

export default ResetPasswordSelector;
