import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import useDeviceType from "../hook/useDeviceType";
import SponcorFooter from "./SponcorList/SponsorFooter";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import FloatButton from "./floatButton/floatButton";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import Menu from "./Menu/Menu";
import { useTranslation } from "react-i18next";
import MenuBar from "./Menu/MenuBar";
import RightPromotion from "./Menu/RightPromotion";
import {
  defaultGameStatus,
  playedGameStatus,
  playingGameStatus,
} from "../redux/action/member/memberAction";
import HeaderV2 from "./Header_v2";
import FooterV2 from "./Footer_v2";
import { WEB_LAYOUT } from "../constant";

const rank = [
  {
    label: "platform",
  },
  {
    label: "player",
  },
  {
    label: "bet",
  },
];

const isCredit = process.env.REACT_APP_CREDIT == 1;

const Wrapper = ({ children, scrollToTop }) => {
  const { t } = useTranslation();
  const i18n_ranking = (key) => t(`ranking.${key}`);

  const isStickyLeft = useSelector((state) => state.isStickyLeft);
  const gameBoxStatus = useSelector((state) => state.isGameBoxStatus);
  const dispatch = useDispatch();

  const deviceType = useDeviceType();
  const scrollContainerRef = useRef(null);

  const menuBg = useColorModeValue("light.100", "dark.100");
  const menuBorder = useColorModeValue("gray.300", "dark.100");

  const location = useLocation();

  const navigate = useNavigate();

  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    if (scrollContainerRef.current && scrollToTop) {
      setTimeout(() => {
        scrollContainerRef.current?.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        scrollContainerRef.current?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 100);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 1000) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const isGameBox = useMemo(() => {
    return location.pathname.includes("gamebox");
  }, [location.pathname]);

  const { pathname } = location;
  useEffect(() => {
    if (pathname.includes("gamebox")) {
      dispatch(playingGameStatus());
      return;
    }

    if (gameBoxStatus === "playing") {
      dispatch(playedGameStatus());
      dispatch({ type: "GET_BALANCE" });
      setTimeout(() => {
        dispatch(defaultGameStatus());
      }, 1500);
    }
  }, [pathname, gameBoxStatus]);

  const headerVersion = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v2":
        return {
          header: <HeaderV2 />,
          footer: <FooterV2 />,
          wrapperTopPadding: {
            base: "55px",
            sm: "55px",
          },
        };
      default:
        return {
          header: <Header />,
          footer: <Footer />,
          wrapperTopPadding: {
            // base: isLogin ? "70px" : "50px",
            // sm: isLogin ? "76px" : "56px",
            base: "",
            sm: "",
          },
        };
    }
  }, [WEB_LAYOUT]);

  const { header, footer, wrapperTopPadding } = headerVersion;

  return (
    <Box
      overflow={{
        base: "",
        md: "scroll",
        lg: "hidden",
      }}
      className="md:overflow-x-scroll lg:overflow-hidden"
    >
      {isGameBox && (
        <Box position="relative">
          <Box
            className="gamebox-bg min-w-screen"
            style={{
              backgroundImage: `url(https://cdnimages3.gcdn.co/HRJLWPLB/images/SC/Leonbets/egs/LeonRoulette2@1920x1080.jpg)`,
            }}
          ></Box>
          <div className="gamebox-gradient"></div>
        </Box>
      )}
      <Flex>
        {isGameBox &&
        (deviceType === "PC" || deviceType === "Tablet") ? null : (
          <Box
            maxW={`252px`}
            w={{
              base: "0px",
              md: "252px",
            }}
            overflowX={`hidden`}
            bg={menuBg}
            // borderRight={{
            //   base: "0px solid",
            //   md: `1px solid`,
            // }}
            borderColor={menuBorder}
            className="wrapper-menu-shadow"
            position="relative"
            zIndex={1000}
          >
            {/* <Menu /> */}
            <MenuBar />
          </Box>
        )}
        <Box
          // maxW={`1440px`}
          minW={{
            base: "0px",
            xl: "1024px",
          }}
          w={`100%`}
          h={{
            base: "auto",
            lg: "100vh",
          }}
        >
          {isGameBox && deviceType === "Mobile" ? null : <Header />}
          <Flex
            mx={{
              base: "0px",
              md: `common-x`,
            }}
            justifyContent={`space-between`}
            h={`100%`}
          >
            <Box
              h={`100%`}
              display={`flex`}
              w="100%"
              maxW={
                isGameBox
                  ? {
                      base: "100%",
                      xl: "1188px",
                      "2xl": "1440px",
                    }
                  : {
                      base: "100%",
                      md: "100%",
                      xl: "100%",
                      "2xl": isCredit ? "100%" : "calc(100% - 300px)",
                      // md: "1188px",
                    }
              }
              mx={
                isGameBox
                  ? {
                      base: "auto",
                    }
                  : {
                      base: "auto",
                      xl: "0px",
                    }
              }
            >
              <Box
                ref={scrollContainerRef}
                minW={{
                  base: "0px",
                  lg: "772px",
                  xl: "unset",
                }}
                w="100%"
                // maxH={`calc(100vh)`}
                overflowY={{
                  base: "",
                  sm: "scroll",
                }}
                overflowX={{
                  base: "",
                  lg: "hidden",
                }}
                className={`${isStickyLeft && "!max-w-[1440px]"} no-scrollbar`}
                pt={{
                  base: isGameBox ? "0px" : wrapperTopPadding.base,
                  sm: wrapperTopPadding?.sm,
                }}
              >
                {children}
                {isGameBox && deviceType === "Mobile" ? null : (
                  <SponcorFooter />
                )}
              </Box>
            </Box>
            <Box
              display={{
                base: "none",
                "2xl": isCredit ? "none" : isGameBox ? "none" : "block",
              }}
              // maxW={{
              //   xl: "calc(100% - 1188px)",
              // }}
              w="300px"
              h={`100%`}
              pt={wrapperTopPadding}
              overflowY={`scroll`}
            >
              <RightPromotion />
            </Box>
          </Flex>

          {isGameBox ? null : deviceType === "Mobile" && <Footer />}
          <FloatButton />
        </Box>
      </Flex>
    </Box>
  );
};

export default Wrapper;
