import React, { useMemo } from "react";
import { WEB_LAYOUT } from "../../constant";
import DepositStepOneV4 from "./DepositStepOne_v4";
import DepositStepOne from "./DepositStepOne";

const DepositStepOneSelector = (props) => {
  const depositOne = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v4":
        return <DepositStepOneV4 {...props} />;
      default:
        return <DepositStepOne {...props} />;
    }
  }, [props]);
  return depositOne;
};

export default DepositStepOneSelector;
