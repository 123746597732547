import { useNavigate } from "react-router";

import { useDispatch } from "react-redux";

import { goToDetailPage } from "../../redux/action/action";
import { Base64 } from "js-base64";
import { storeInboxDetail } from "../../redux/action/member/memberAction";
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { useMemo } from "react";

const InboxItemV4 = ({ data, isInbox }) => {
  const {
    message,
    notification_id,
    status,
    notification_content,
    subject,
    send_date,
  } = data || {};

  const boxBg = useColorModeValue("light.100", "dark.250");
  const boxColor = useColorModeValue("dark.100", "light.100");
  const titleColor = useColorModeValue("dark.100", "light.100");
  const dateColor = useColorModeValue("light.400", "light.550");
  const dotColor = useColorModeValue("#32383E", "dark.250");

  const navigate = useNavigate();

  const already_read = useMemo(() => {
    return status === "Unread";
  }, [status]);
  const dispatch = useDispatch();

  return (
    <Box
      bg={boxBg}
      p="16px"
      borderRadius="common"
      gap="10px"
      cursor="pointer"
      onClick={() => {
        navigate(`${notification_id}`);
        dispatch(
          storeInboxDetail({
            subject,
            notification_content,
            send_date,
            notification_id,
            isInbox: isInbox ? "1" : "0",
          })
        );
        dispatch(goToDetailPage());
      }}
    >
      <Flex alignItems="center" justifyContent="left" gap="15px">
        {" "}
        <Box
          w="8px"
          h="8px"
          borderRadius={`full`}
          bg={already_read ? "brand.500" : dotColor}
        ></Box>{" "}
        <Text
          as="p"
          fontWeight={`medium`}
          color={boxColor}
          maxWidth="90%"
          mb="0px"
          truncate //省略文字
          noOfLines={1}
        >
          {subject}
        </Text>
      </Flex>
      <Flex direction={`column`} justifyContent={`space-between`}></Flex>
      <Box
        mt="15px"
        mb="30px"
        ml="25px"
        className="break-words jodit-wysiwyg"
        color={dateColor}
        dangerouslySetInnerHTML={{ __html: notification_content }}
        lineClamp="3"
        noOfLines={3}
        maxWidth="90%"
      ></Box>
      <Text
        as="p"
        pl="25px"
        fontSize="sm"
        fontWeight={`medium`}
        color={dateColor}
        mb="0px"
        className="text-overflow"
      >
        {send_date}
      </Text>
    </Box>
  );
};

export default InboxItemV4;
