import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import useDeviceType from "../../hook/useDeviceType";
import { useNavigate } from "react-router";
import GameBoxHeader from "./components/gameBoxHeader";
import VipBar from "./components/vipBar";
import GameCatalog from "../../components/GameCatalog";
import {
  closeFullScreen,
  openFullScreen,
} from "../../redux/action/game/gameAction";
import { getRandomSlice } from "../../utils/shuffle";
import {
  Box,
  chakra,
  shouldForwardProp,
  useColorModeValue,
} from "@chakra-ui/react";
import { isValidMotionProp, motion } from "framer-motion";
import GameBoxHeaderV3 from "./components/gameBoxHeader_v3";
import useCheckLogin from "../../hook/useCheckLogin";
import GameCatalogV4 from "../../components/GameCatalog_v4";
import { toggleBGM } from "../../redux/action/action";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const GameWrapV3 = ({ children }) => {
  const gameList = useSelector((state) => state.isGameList);
  const gameLink = useSelector((state) => state.isGameLink);
  const isOpenFullScreen = useSelector((state) => state.isOpenFullScreen);
  const isVipBarOpen = useSelector((state) => state.isVipBar);
  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);
  const gameConfig = useSelector((state) => state.isGameConfig);

  const { randomlySelectedGames, topGameList } = isHandleAllGame;

  const vipBarBg = useColorModeValue("light.200", "dark.200");

  const topGameRandom = useMemo(() => {
    return getRandomSlice(topGameList);
  }, [gameConfig]);

  const dispatch = useDispatch();

  const deviceType = useDeviceType();

  const navigate = useNavigate();

  const { isMachineUser } = useCheckLogin();

  //改為 機台使用者 在載入時 預設打開全螢幕  否則預設關閉全螢幕
  useEffect(() => {
    if (isMachineUser) {
      dispatch(openFullScreen());
    } else {
      dispatch(closeFullScreen());
    }
  }, [isMachineUser]);

  useEffect(() => {
    dispatch(
      toggleBGM({
        enabled: false,
        song: "back2",
        musicVolume: 0.2,
      })
    );
  }, []);

  return deviceType === "PC" || deviceType === "Tablet" ? (
    <>
      <Box
        as="section"
        pt={{
          md: isOpenFullScreen ? "0px" : "30px",
        }}
        mb={{
          xl: "0px",
          md: "20px",
        }}
        position={`${isOpenFullScreen ? "fixed" : "relative"}`}
        top="0px"
        left="0px"
        w={isOpenFullScreen ? "100%" : ""}
        h={isOpenFullScreen ? "100%" : ""}
        zIndex={`${isOpenFullScreen ? "1100" : "0"}`}
      >
        <Box
          as="section"
          w={{
            lg: isOpenFullScreen ? "" : "820px",
            xl: isVipBarOpen ? "100%" : isOpenFullScreen ? "" : "1128px",
          }}
          h={isOpenFullScreen || "588px"}
          display={`flex`}
          mx="auto"
          borderBottomRadius={isOpenFullScreen ? "0" : "common"}
          borderTopRadius={isOpenFullScreen ? "0" : "5px"}
          overflow="hidden"
        >
          <Box as="section" w={isVipBarOpen ? "calc(100% - 280px)" : "100%"}>
            <GameBoxHeaderV3 />
            {children}
          </Box>
          {isVipBarOpen && (
            <ChakraBox
              as="section"
              w="280px"
              overflowY={{
                md: "scroll",
              }}
              bg={vipBarBg}
              className={`no-scrollbar`}
            >
              <VipBar />
            </ChakraBox>
          )}
        </Box>
        <Box mx="auto" p="10px">
          {/* <UserClickGameCatalog /> */}
          <GameCatalogV4
            wrapperClassName={`!my-0`}
            list={topGameRandom}
            mainTitle={`Top`}
            swiperProps={{
              customPerView: "game-box-layout",
            }}
            hiddenButtonNavigator
          />
        </Box>
      </Box>
    </>
  ) : (
    children
  );
};

export default GameWrapV3;
