import CloseDetailPage from "../../components/CloseComponent/CloseDetailPage";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import CommonOpacityBackground from "../../components/Common/commonOpacityBackground";
import { useMemo, useState } from "react";
import { COMMON_WEB_PATH } from "../../constant";
import { useTranslation } from "react-i18next";
import { isValidMotionProp, motion } from "framer-motion";
import useSubTypeList from "../../hook/useSubTypeList";
import useSpecPath from "../../hook/useSpecPath";
import useCasinoType from "../../hook/useCasinoType";
import { filterType } from "../../hook/useGameListFilter";
import { BsTools } from "react-icons/bs";
import LazyImage from "../../components/Loading/lazyImage";
import {
  Box,
  Flex,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  chakra,
  shouldForwardProp,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { IoLogoGameControllerB } from "react-icons/io";
import { Link } from "react-router-dom";
import { thisImageNeedDark } from "../../utils/thisImageNeedDark";
import useGetFullGamePlatform from "../../hook/useGetFullGamePlatform";
import { popFromBottom } from "../../animateConstant";
import { routeMapping } from "../../components/v2Layout/GameTypeSelector/utils/routeMapping";

const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

const CasinoGroupsV2 = () => {
  const { t } = useTranslation();
  const i18n = (key) => t(`common.${key}`);

  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const { colorMode, toggleColorMode } = useColorMode();

  const isHandleAllGame = useSelector((state) => state.isHandleAllGame);

  const boxBg = useColorModeValue("light.200", "dark.100");
  const textColor = useColorModeValue("dark.100", "light.100");
  const itemBg = useColorModeValue("light.100", "dark.200");

  const casinoType = useCasinoType();

  const {
    allGamePlatform = [],
    sortedByPlatformGames = {},
    sortedByPlatformAndCategoryGames = {},
    platform = [],
    gameList = [],
  } = isHandleAllGame;

  const subTypeList = useSubTypeList({ forceShowAll: true });

  const filterPlatformBySubType = useMemo(() => {
    const filterNullGame = Object.keys(sortedByPlatformAndCategoryGames).filter(
      (key) =>
        Object.keys(sortedByPlatformAndCategoryGames[key]).some((k) =>
          subTypeList.includes(k)
        )
    );
    const list = filterNullGame.map((item) => {
      const listLengthBigThanOne =
        filterType({
          gameList: sortedByPlatformGames[item],
          casinoType: casinoType,
        })?.length > 0;

      if (!listLengthBigThanOne) {
        return;
      } else {
        return item;
      }
      // return item;
    });

    return list;
  }, [subTypeList]);

  const handleInput = (e) => {
    setSearch(e.target.value);
  };

  const getPlatformPath = useGetFullGamePlatform();

  // const casinoGroup = useMemo(() => {
  //   if (casinoType === "promotion") {
  //     return platform;
  //   }
  //   if (search) {
  //     return filterPlatformBySubType.filter((item) => {
  //       return item?.toLowerCase()?.includes(search);
  //     });
  //   } else {
  //     return filterPlatformBySubType;
  //   }
  // }, [search, filterPlatformBySubType, casinoType]);

  const casinoGroup = useMemo(() => {
    return gameList;
  }, [gameList]);

  return (
    <CommonOpacityBackground
      className={"lg:backdrop-blur-none backdrop-blur-md pt-[200px]"}
      path={-1}
    >
      <ChakraBox
        onClick={(e) => {
          e.stopPropagation();
        }}
        {...popFromBottom}
        minH={{
          md: "248px",
        }}
        maxH={{
          md: "calc(100vh-92px)",
        }}
        w={{
          base: "100%",
          md: "720px",
        }}
        h="100%"
        overflowY="scroll"
        bg={boxBg}
        borderTopRadius={{
          base: `24px`,
        }}
        borderBottomRadius={{
          md: `24px`,
        }}
        gap="30px"
        px="15px"
        display="flex"
        flexDirection={`column`}
        className="no-scrollbar"
      >
        <Box pt="20px" display="flex" justifyContent={`space-between`}>
          <Text fontSize="20px" fontWeight={`medium`}>{`${i18n("Search")}${i18n(
            "Providers"
          )}`}</Text>
          <Link to={-1}>
            <Icon cursor={`pointer`} fontSize="30px" as={IoCloseOutline} />
          </Link>
        </Box>
        {/* <Box as="section">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <IoLogoGameControllerB className="text-gray-300" />
            </InputLeftElement>
            <Input
              variant={`brandPrimary`}
              w="100%"
              icon
              placeholder={i18n("Search")}
            />
          </InputGroup>
        </Box> */}
        {Object.keys(casinoGroup || {}).map((gameType) => {
          return (
            <Box>
              <Flex gap="5px">
                <Text fontSize="16px" fontWeight={`700`} mb="10px">
                  {i18n(gameType)}
                </Text>
                <Image
                  src={`${COMMON_WEB_PATH}/v4-layout/game-type-icon/${gameType?.toUpperCase()}.svg`}
                  w="27px"
                  h="27px"
                />
              </Flex>
              <Box
                display="grid"
                gap="10px"
                className="grid-cols-3 md:grid-cols-4"
              >
                {Object.keys(casinoGroup[gameType] || {})?.map(
                  (plat, index) => {
                    const platConfig = allGamePlatform;
                    const isMaintain = platConfig?.isUnderMaintenance === true;
                    const getGameType = routeMapping(gameType);
                    return (
                      plat && (
                        <Box
                          onClick={() => {
                            if (isMaintain) {
                              return;
                            }
                            navigate(
                              `${getGameType?.link}/category/${plat}/AllGames`
                            );
                          }}
                          key={`${plat}-${index}`}
                          bg={itemBg}
                          cursor={`pointer`}
                          h="110px"
                          borderRadius={`common`}
                          px="7px"
                          display="flex"
                          flexDirection={`column`}
                          alignItems={`center`}
                          justifyContent={`center`}
                          _hover={{
                            border: "1px solid",
                            borderColor: "brand.500",
                          }}
                          position="relative"
                        >
                          {isMaintain && (
                            <Box
                              zIndex="2"
                              position={`absolute`}
                              top="0"
                              left="0"
                              w="full"
                              h="full"
                              bg="rgba(0,0,0,0.7)"
                              display="flex"
                              alignItems={`center`}
                              justifyContent={`center`}
                              flexDirection={`column`}
                            >
                              {" "}
                              <BsTools className="text-2xl" />
                              <Text
                                as="span"
                                fontSize="sm"
                                letterSpacing={`tighter`}
                              >
                                {platConfig?.maintenanceStartTime || "-"} ~
                              </Text>
                              <Text
                                as="span"
                                fontSize="sm"
                                letterSpacing={`tighter`}
                              >
                                {platConfig?.maintenanceEndTime || "-"}
                              </Text>
                            </Box>
                          )}

                          <LazyImage
                            className="mx-auto w-[100px] h-[55px] object-contain"
                            src={`${COMMON_WEB_PATH}/platformIcon/${thisImageNeedDark(
                              plat,
                              colorMode === "dark"
                            )}.png`}
                            alt={plat}
                          />
                          <Text>{plat}</Text>
                        </Box>
                      )
                    );
                  }
                )}
              </Box>
            </Box>
          );
        })}
      </ChakraBox>
    </CommonOpacityBackground>
  );
};

export default CasinoGroupsV2;
