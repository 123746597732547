import React, { useMemo } from "react";
import InboxItemV4 from "./InboxItem_v4";
import InboxItem from "./InboxItem";
import { WEB_LAYOUT } from "../../constant";

const InboxItemSelector = (props) => {
  const inboxItem = useMemo(() => {
    switch (WEB_LAYOUT) {
      case "v4":
        return <InboxItemV4 {...props} />;
      default:
        return <InboxItem {...props} />;
    }
  }, [props]);
  return inboxItem;
};

export default InboxItemSelector;
