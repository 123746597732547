import { Spinner, Flex, Icon } from "@chakra-ui/react";
import React from "react";
import { ImSpinner6 } from "react-icons/im";

const LoadingBlock = ({ commonLoading, children, size }) => {
  return commonLoading ? (
    <Flex align="center" justify="center" h="350px">
      {/* <Spinner
        color="yellow"
        colorScheme="yellow"
        emptyColor="gray.500"
        size={"xl"}
      >
       </Spinner> */}
      <Icon
        as={ImSpinner6}
        fontSize={size || "xl"}
        className="animate-spin"
        color="yellow.500"
      />
    </Flex>
  ) : (
    children
  );
};

export default LoadingBlock;
