import { Flex, Image } from "@chakra-ui/react";
import React from "react";
import { COMMON_WEB_PATH } from "../constant";

// 定義不同遊戲的圖片配置
const GAME_CONFIGS = {
  BINGO: {
    icon1: `${COMMON_WEB_PATH}/FlyDragon/split.webp`,
    icon2: `${COMMON_WEB_PATH}/FlyDragon/game-type-icon/BINGO.png`,
  },
  TOP10: {
    icon1: `${COMMON_WEB_PATH}/FlyDragon/split.webp`,
    icon2: `${COMMON_WEB_PATH}/FlyDragon/TOP10.webp`,
  },
  POPULAR: {
    icon1: `${COMMON_WEB_PATH}/FlyDragon/split.webp`,
    icon2: `${COMMON_WEB_PATH}/FlyDragon/POPULAR.webp`,
  },
  ORIGIN: {
    icon1: `${COMMON_WEB_PATH}/FlyDragon/split.webp`,
    icon2: `${COMMON_WEB_PATH}/FlyDragon/ORIGIN.webp`,
  },
  NEW: {
    icon1: `${COMMON_WEB_PATH}/FlyDragon/split.webp`,
    icon2: `${COMMON_WEB_PATH}/FlyDragon/NEW.webp`,
  },
  EXCLUSIVE: {
    icon1: `${COMMON_WEB_PATH}/FlyDragon/split.webp`,
    icon2: `${COMMON_WEB_PATH}/FlyDragon/EXCLUSIVE.webp`,
  },
};

const HomeTitle = ({ gameType = "TOP10" }) => {
  // 獲取對應遊戲類型的配置
  const config = GAME_CONFIGS[gameType] || GAME_CONFIGS.TOP10;

  return (
    <Flex alignItems={`center`} gap="10px">
      <Image
        src={config.icon1}
        alt={gameType}
        objectFit={"cover"}
        w={{
          base: "30px",
          md: "50px",
        }}
      />
      <Image
        src={config.icon2}
        alt={gameType}
        h={{
          base: "30px",
          md: "50px",
        }}
      />
    </Flex>
  );
};
export default HomeTitle;
